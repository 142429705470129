import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../../api';
import * as Category from '../../../Categories';

const useDeleteCategory = () => {
  return useMutation({
    mutationFn: async (categoryId: string | number) => {
      const response = await api.delete(
        Category.Constants.API_URL + '/' + categoryId,
        {}
      );
      return response.data;
    },
  });
};

export { useDeleteCategory };
