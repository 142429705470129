import { useMutation } from '@tanstack/react-query';
import { passwordReset } from './PasswordReset.req';
import * as C from './PasswordReset.constants';

const usePasswordReset = () => {
  return useMutation({
    mutationKey: [C.MUTATION_KEY],
    mutationFn: passwordReset,
  });
};

export { usePasswordReset };
