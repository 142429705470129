import * as React from 'react';
import * as T from './DeleteCategory.types';
import { Button, Box, Divider } from '../../../Common';
import { cx } from '../../../../utils';
import { FormEvent } from 'react';

const DeleteCategoryForm = React.forwardRef<
  T.DeleteCategoryFormElement,
  T.DeleteCategoryFormProps
>(({ onSubmit, onCancel, isLoading = false, ...props }, forwardedRef) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit?.();
  };

  return (
    <Box as="form" {...props} ref={forwardedRef} onSubmit={handleSubmit}>
      <Box className={cx(['text-lg', 'font-semibold', 'text-gray-600'])}>
        Confirm deletion
      </Box>
      <Divider />
      <Box>
        <p>Are you sure you want to delete this category?</p>
      </Box>
      <Divider />
      <Box className={cx(['flex', 'justify-end', 'space-x-2'])}>
        <Button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            onCancel?.();
          }}
        >
          Cancel
        </Button>
        <Button intent="primary" type="submit" isLoading={isLoading}>
          Confirm
        </Button>
      </Box>
    </Box>
  );
});

export { DeleteCategoryForm };
