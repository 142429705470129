const CC_GROUP_NAME = 'Credit Cards & Loans';

const API_URL = 'budgets';
const BUDGET_QUERY_KEY = 'budget';
const BUDGET_POST_MUTATION_KEY = 'BUDGET_POST_MUTATION_KEY';

const BUDGETING_STYLES = {
  GLOBAL_LTB: 'GLOBAL_LTB',
  WALLED_OFF_MONTHS: 'WALLED_OFF_MONTHS',
};

export {
  BUDGET_QUERY_KEY,
  BUDGETING_STYLES,
  API_URL,
  CC_GROUP_NAME,
  BUDGET_POST_MUTATION_KEY,
};
