import * as T from './Settings.types';
import { api } from '../../api';

const getSettings = async (budgetId: string): Promise<T.SettingsRead[]> => {
  const response = await api.get('/settings/', {
    params: {
      budget_id: budgetId,
    },
  });

  return response.data;
};

const putSettings = async (
  settings: T.SettingsWrite
): Promise<T.SettingsRead> => {
  const response = await api.put(`/settings/${settings.id}/`, settings);

  return response.data;
};

export { getSettings, putSettings };
