import { match } from 'ts-pattern';
import { useState, ChangeEvent, useEffect } from 'react';
import { useFocusWithin } from 'react-aria';
import { ColumnDef } from '@tanstack/react-table';
import { Transaction } from '../../Transactions.types';
import { Input, Box } from '../../../Common';
import { cx } from '../../../../utils';

const notesColumn = (
  field: 'category' | 'memo'
): Partial<ColumnDef<Transaction>> => ({
  cell: ({
    getValue,
    row: { index, original, getIsSelected },
    column: { id },
    table,
  }) => {
    // const isFocused = table.options.meta && table.options.meta.rowFocus[index];
    /* eslint-disable react-hooks/rules-of-hooks */
    // const [isFocused, setFocus] = useState(false);
    const [isFocusWithin, setFocusWithin] = useState(false);

    // const isTransfer = original.internal_type === 'TRANSFER';

    const { focusWithinProps } = useFocusWithin({
      onFocusWithin: (event) => {
        setFocusWithin(true);
      },
      onBlurWithin: (event) => {
        setFocusWithin(false);
      },
    });

    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    const callback = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      if (original[field] !== newValue && newValue !== '') {
        table.options.meta?.onTransactionChange(index, id, {
          id: original.id,
          [field]: newValue,
        });
      }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) =>
      setValue(event.target.value);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Box
        tabIndex={isFocusWithin ? -1 : 0}
        className={cx(['w-[150px]', 'truncate'])}
        {...focusWithinProps}
      >
        {match(isFocusWithin)
          .with(false, () => <>{value}</>)
          .with(true, () => (
            <Input
              label={`Transaction ${field}`}
              hideLabel
              value={String(value)}
              onChange={onChange}
              onBlur={callback}
              autoFocus
            />
          ))
          .otherwise(() => (
            <div />
          ))}
      </Box>
    );
  },
});

export { notesColumn };
