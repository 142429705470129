import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as C from './CategoryGroups.constants';
import { getCategoryGroups, postCategoryGroup } from './CategoryGroups.req';

const useGetCategoryGroups = (budgetId: string) => {
  return useQuery({
    queryKey: [C.QUERY_KEY, budgetId],
    queryFn: () => getCategoryGroups(budgetId),
  });
};

const usePostCategoryGroup = (budgetId: string) => {
  const queryClient = useQueryClient();
  const queryKey = [C.QUERY_KEY, budgetId];
  return useMutation({
    mutationKey: [C.POST_MUTATION_KEY, budgetId],
    mutationFn: postCategoryGroup,
    onSettled: async () => await queryClient.invalidateQueries({ queryKey }),
  });
};

export { useGetCategoryGroups, usePostCategoryGroup };
