import React, { PropsWithChildren, ReactElement } from 'react';
import * as _ from 'lodash-es';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useMatch,
} from 'react-router-dom';
import { cx } from '../../../utils';
import { IconType } from 'react-icons';
import invariant from 'tiny-invariant';

type SidebarPageLinkComponent = (
  props: PropsWithChildren<{
    current?: boolean;
    compact?: boolean;
    href: string;
    icon: IconType;
    label: string;
  }>
) => ReactElement | null;

const SidebarPageLink: SidebarPageLinkComponent = ({
  current = false,
  compact = false,
  href,
  icon: Icon,
  label,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { budgetId } = useParams();
  invariant(budgetId, 'No budget ID found in URL.');

  const lastPartOfHref = _.last(pathname.split('/'));
  current = !!lastPartOfHref?.includes(href);

  const destination = href.includes(budgetId)
    ? '/b' + href
    : `/b/${budgetId}/${href}`;

  const ref = useHotkeys<HTMLLIElement>(
    ['space', 'enter'],
    (keyboardEvent, hotkeysEvent) => {
      navigate(destination);
    }
  );

  return (
    <li
      tabIndex={0}
      ref={ref}
      className={cx([
        'rounded-md',
        'group',
        'focus:outline-none',
        'focus-visible:ring-2',
        'focus-visible:ring-ew-primary',
      ])}
    >
      <Link
        tabIndex={0}
        to={destination}
        className={cx(
          [
            'text-gray-300',
            'hover:text-white',
            'hover:bg-ew-bg-dark-800',
            'group',
            'flex',
            'items-center',
            'gap-x-3',
            'rounded-md',
            'pl-3',
            'p-2',
            'leading-6',
            'focus:outline-none',
            'focus-visible:ring-2',
            'focus-visible:ring-ew-primary',
          ],
          {
            'bg-ew-bg-dark-800 dark:text-white': current,
            'p-1 pl-2': compact,
          }
        )}
      >
        <Icon className={cx(['mr-1', 'w-5', 'h-5'])} />
        {label}
      </Link>
    </li>
  );
};

export { SidebarPageLink };
