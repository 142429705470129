import React, { useEffect, useMemo, useState } from 'react';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';
import { Key } from 'react-aria-components';
import { match } from 'ts-pattern';
import { useFilter, useFocusWithin } from 'react-aria';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import _ from 'lodash-es';
import invariant from 'tiny-invariant';
import { ColumnDef } from '@tanstack/react-table';
import { TableTransaction, TransactionUpdate } from '../../Transactions.types';
import { PayeeRead } from '../../../Payees';
import { ComboboxOption, Badge, Box } from '../../../Common';
import { ThreeDots } from '../../../Common/Loading/ThreeDots';
import * as Combobox from '../../../Common/Combobox';
import { cx } from '../../../../utils';
import {
  PAYEES_QUERY_KEY,
  PAYEES_MUTATION_KEY,
} from '../../../Payees/Payees.constants';
import * as C from '../../Transactions.constants';

// This component will behave differently than the other columns because we are
// affecting the Payees directly and not the Transactions only
const payeeColumn: Partial<ColumnDef<TableTransaction>> = {
  cell: ({
    getValue,
    row: { index, original, getIsSelected },
    column: { id },
    table,
  }) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    const initialValue = getValue<string>();
    const [value, setValue] = useState(initialValue ?? '');

    let [isCreating, setIsCreating] = useState(false);

    const [isFocusWithin, setFocusWithin] = useState(false);
    const { focusWithinProps } = useFocusWithin({
      onFocusWithin: (event) => {
        setFocusWithin(true);
      },
      onBlurWithin: (event) => {
        setFocusWithin(false);
      },
    });

    const isTransfer = original.internal_type === 'TRANSFER';

    const budgetId = table.options.meta?.budgetId;
    const PAYEES_KEY = [PAYEES_QUERY_KEY, budgetId];
    const isFetching = useIsFetching({
      queryKey: PAYEES_KEY,
    });

    // let isMutating = useIsMutating({
    //   mutationKey: [PAYEES_MUTATION_KEY, budgetId],
    // });

    // Get the list of payees
    const payees = table.options.meta?.payees ?? [];

    // Format the list of payees as combobox options
    const payeesFormatted: ComboboxOption[] = payees?.map(
      (payee: PayeeRead) => {
        invariant(!_.isNil(payee.id), 'payee.id must be have a value');

        return {
          value: payee.id,
          label: payee.name,
        };
      }
    );

    // let selected = payeesFormatted.find((item) => item.value === value);

    /**
     * Controlled Combobox state
     */

    let [fieldState, setFieldState] = React.useState<{
      selectedKey: string | Key | null;
      inputValue: string;
    }>({
      selectedKey: null,
      inputValue: value,
    });

    let onSelectionChange = (id: Key | null) => {
      let selected = payeesFormatted.find((item) => item.value === id)?.label;
      setFieldState({
        inputValue: String(selected),
        selectedKey: String(id),
      });

      onSelect(id);
    };

    let onInputChange = (value: string) => {
      setFieldState((prevState) => ({
        inputValue: value,
        selectedKey: value === '' ? null : prevState.selectedKey,
      }));
    };

    /**
     * Custom filtering
     */

    let { contains } = useFilter({ sensitivity: 'base' });
    let filteredItems = React.useMemo(
      () =>
        payeesFormatted.filter((item) => {
          return contains(item.label, fieldState.inputValue);
        }),
      [payees, fieldState.inputValue]
    );

    const onSelect = (option: Key | null) => {
      // When we select an item manually, the Combobox grabs the id, but when
      // we don't select anything and blur out, the Combobox grabs the last
      // selected element's label. So we need to look for both the id and the
      // label.
      let selected = payeesFormatted?.find((payee) => {
        return payee.value === option || payee.label === option;
      });

      // Only update when the selected value is different from the initial one
      if (selected?.label !== value) {
        // Optimistic update the payee name with the local state
        setValue(String(selected?.label));

        table.options.meta?.onTransactionChange(index, id, {
          id: String(original.id),
          payee_id: String(option),
        });
      }
    };

    let onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      let payeeName = event.currentTarget.value;
      let hasMatch = !_.isEmpty(filteredItems);

      // If we have a match, then we don't need to do anything other than returning
      // to the previous state.
      if (hasMatch) {
        // Return to the previous state
        setFieldState((prevState) => ({
          inputValue: value,
          selectedKey: value === '' ? null : prevState.selectedKey,
        }));
        return;
      }

      let selected = payeesFormatted?.find((payee) => {
        return payee.label === payeeName;
      });

      if (selected?.label === value) {
        return;
      }

      if (!_.isEmpty(payeeName)) {
        setIsCreating(true);
        setValue(event.currentTarget.value);
        table.options.meta?.onPayeeCreate(payeeName, original.id, () =>
          setIsCreating(false)
        );
      }
    };

    // If value is mutated externally, sync it with the state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (isCreating) {
      return <ThreeDots intent="secondary" size="sm" />;
    }

    if (original.source === C.SOURCES.INITIAL_BALANCE) {
      return (
        <div className={cx(['max-w-full'])}>
          <Badge intent="secondary">No Payee</Badge>
        </div>
      );
    }

    if (isTransfer) {
      return (
        <Box className={cx(['w-40'])}>
          <Badge intent="secondary">
            <FaArrowRightArrowLeft className={cx(['h-4', 'w-4', 'mr-1'])} />
            Transfer
          </Badge>
        </Box>
      );
    }

    return (
      <Box
        tabIndex={isFocusWithin ? -1 : 0}
        className={cx(['w-40'])}
        {...focusWithinProps}
      >
        {match(isFocusWithin)
          .with(
            false,
            () => value ?? <Badge intent="secondary">No Payee</Badge>
          )
          .with(true, () => (
            <Combobox.$
              selectedKey={fieldState.selectedKey}
              inputValue={fieldState.inputValue}
              onSelectionChange={onSelectionChange}
              onInputChange={onInputChange}
              allowsCustomValue
              hideLabel
              label="Select Payee"
              isLoading={!!isFetching}
              placeholder="Select Payee"
              // defaultInputValue={value}
              defaultItems={filteredItems}
              onBlur={onBlur}
              isRequired
              validationBehavior="aria"
              autoFocus
              onFocus={(event) => {
                event.currentTarget.select();
              }}
            >
              {(data: Combobox.T.ComboboxOption) => (
                <Combobox.Item id={String(data.value)}>
                  {data.label}
                </Combobox.Item>
              )}
            </Combobox.$>
          ))
          .otherwise(() => (
            <div />
          ))}
      </Box>
    );
  },
};
export { payeeColumn };
