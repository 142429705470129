import * as React from 'react';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Input, Button, Divider } from '../Common';
import { cx } from '../../utils';
import * as T from './PasswordUpdate.types';

const PasswordUpdateForm = React.forwardRef<
  T.PasswordUpdateElement,
  T.PasswordUpdateProps
>(({ onSubmit, isLoading, ...props }, forwardedRef) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<T.PasswordUpdateInputs>();
  const onSubmit_: SubmitHandler<T.PasswordUpdateInputs> = (data) => {
    // console.log(data);
    onSubmit?.(data);
  };

  const minLenValidation = {
    value: 8,
    message: 'Password must be at least 8 characters long',
  };

  return (
    <Box
      as="form"
      {...props}
      onSubmit={handleSubmit(onSubmit_)}
      ref={forwardedRef}
    >
      <Box>
        <Input
          label={'New password'}
          type={'password'}
          hasErrors={'new_password1' in errors}
          helpText={errors.new_password1?.message}
          {...register('new_password1', {
            required: 'Password required',
            minLength: minLenValidation,
          })}
        />
      </Box>
      <Divider />
      <Box>
        <Input
          label={'Confirm new password'}
          type={'password'}
          hasErrors={'new_password2' in errors}
          helpText={errors.new_password2?.message}
          fullWidth
          {...register('new_password2', {
            required: 'Both password fields required',
            validate: (value) =>
              value === watch('new_password1') || 'The passwords do not match',
          })}
        />
      </Box>
      <Divider />
      <Box>
        <Button fullWidth intent="primary" isLoading={isLoading}>
          Reset your password
        </Button>
      </Box>
      <Divider />
      <Box className={cx(['flex', 'items-center', 'justify-center'])}>
        <Box className={cx(['text-sm'])}>
          <Link to="/sign-in" className={cx(['font-medium', 'text-gray-600'])}>
            Back to login
          </Link>
        </Box>
      </Box>
    </Box>
  );
});

export { PasswordUpdateForm };
