import moment from 'moment';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Box, Button, Input, Divider } from '../Common';
import * as Combobox from '../Common/Combobox';
import { cx } from '../../utils';
import { TransferFormInputs, TransferFormComponent } from './Transfers.types';

const TransferForm: TransferFormComponent = ({
  initialValue,
  accounts,
  formId,
  onSubmit,
  isLoading = false,
  noButtons = false,
  settings,
}) => {
  const [isPressed, setPressed] = useState(false);
  const { budget: budgetId, id: accountId } = useParams<{
    budget: string;
    id: string;
  }>();

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setFocus,
  } = useForm<TransferFormInputs>({
    defaultValues: {
      ...initialValue,
      date: moment(new Date()).format('YYYY-MM-DD').toString(),
    },
  });

  useEffect(() => {
    setFocus('date');
  }, [setFocus]);

  const onSubmit_: SubmitHandler<TransferFormInputs> = (data, options) => {
    console.log('transfer data', data);
    onSubmit(data, {
      onSuccess() {
        reset();

        if (formId) {
          const form = document.getElementById(formId) as HTMLFormElement;
          form.reset();
        }

        setValue('date', moment(new Date()).format('YYYY-MM-DD').toString());
        setValue('amount', '');

        setFocus('amount');
      },
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit_)} id={formId}>
      <Box className={cx(['flex', 'justify-between'])}>
        <Box className={cx(['flex-auto'])}>
          <Input
            fullWidth
            type="date"
            label="Date"
            hasErrors={'date' in errors}
            helpText={errors.date?.message}
            {...register('date', { required: true })}
          />
        </Box>
        <Divider orientation="vertical" />
        <Box className={cx(['flex-auto'])}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Input
                  autoFocus
                  fullWidth
                  forMoney
                  label="Amount"
                  hasErrors={!!fieldState.error}
                  helpText={fieldState.error?.message}
                  {...field}
                  symbol={settings?.symbol}
                  numberOptions={{
                    thousandSeparator: settings?.thousands,
                    decimalSeparator: settings?.decimal,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                />
              );
            }}
            name="amount"
          />
        </Box>
      </Box>
      <Divider />
      <Box className={cx(['flex'])}>
        <Controller
          control={control}
          rules={{ required: 'Please select an account' }}
          render={({ field, fieldState }) => {
            return (
              <Combobox.$
                className={cx(['w-full'])}
                placeholder="Select Account"
                label="Account"
                defaultItems={accounts ?? []}
                defaultInputValue={field.value}
                {...field}
                // Validation
                validationBehavior="aria"
                isInvalid={!!fieldState.error}
                errorMessage={fieldState.error?.message}
                onSelectionChange={field.onChange}
              >
                {(data: Combobox.T.ComboboxOption) => (
                  <Combobox.Item id={String(data.value)}>
                    {data.label}
                  </Combobox.Item>
                )}
              </Combobox.$>
            );
          }}
          name="account"
        />
      </Box>
      <Divider />
      <Box className={cx(['flex-auto'])}>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                fullWidth
                label="Notes"
                // hasErrors={!!fieldState.error}
                // helpText={fieldState.error?.message}
                {...field}
              />
            );
          }}
          name="memo"
        />
      </Box>

      {!noButtons && (
        <>
          <Divider />
          <Box>
            <Button isLoading={isLoading} type="submit">
              Submit
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export { TransferForm };
