import { AccountPage } from './Account.page';
import * as H from './Account.hooks';
import * as T from './Account.types';
import * as C from './Account.constants';
import * as Forms from './forms';
import * as S from './Account.store';
import * as U from './Account.utils';
import * as req from './Account.req';

const Page = AccountPage;

export { Page, T, C, H, Forms, S, U, req };
