import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Input, Button, Divider } from '../Common';
import { SignInComponent, SignInInput } from './SignIn.types';

const SignInForm: SignInComponent = ({ onSubmit, isLoading = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInput>();

  const onSubmit_: SubmitHandler<SignInInput> = (data) => {
    onSubmit(data, {});
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit_)}>
      <Box>
        <Input
          label={'Email address'}
          type="email"
          hasErrors={'email' in errors}
          fullWidth
          helpText={errors.email?.message}
          {...register('email', { required: 'Email address required' })}
        />
      </Box>
      <Divider />
      <Box>
        <Input
          label={'Password'}
          type="password"
          hasErrors={'password' in errors}
          fullWidth
          helpText={errors.password?.message}
          {...register('password', { required: 'Password required' })}
        />
      </Box>
      <Divider size="lg" />
      <Box>
        <Button intent="primary" fullWidth isLoading={isLoading}>
          Sign in
        </Button>
      </Box>
    </Box>
  );
};

export { SignInForm };
