import { cva } from 'class-variance-authority';
import * as Input from '../Input';
import { disabled, errors, forMoney } from '../Input/Input.styles';
// git

let base = ['relative'];

const input = [Input.styles.base, 'w-full'];

const button = [
  'absolute',
  'right-2',
  'left-auto',
  'top-1/2',
  'transform',
  '-translate-y-1/2',
  'bg-gray-300',
  'hover:bg-gray-400',
  'py-1',
  'px-0.5',
  'rounded',
];

let clearButton = [
  'absolute',
  'right-9',
  'left-auto',
  'top-1/2',
  'transform',
  '-translate-y-1/2',
  'text-gray-700',
  // 'hover:bg-gray-400',
  // 'py-1',
  // 'px-0.5',
  // 'rounded',
];

let isLoading = [
  'absolute',
  'right-9',
  'left-auto',
  'top-1/4',
  '-translate-y-2/4',
  'text-gray-700',
];

const list = ['max-h-[300px]', 'overflow-y-auto', 'overflow-x-hidden'];

let listBox = [
  'w-full',
  'bg-gray-100',
  'outline-0',
  'p-1',
  'max-h-[inherit]',
  'overflow-auto',
  '[clip-path:inset(0_0_0_0_round_.75rem)]',
  // Modals have a pointer events: none so we need to explicitly set this to auto
  'pointer-events-auto',
];

let listBoxItem = cva(
  [
    'group',
    'flex',
    'items-center',
    'gap-4',
    'cursor-default',
    'select-none',
    'py-2',
    'pl-3',
    'pr-1',
    'rounded-lg',
    'outline',
    'outline-0',
    'text-sm',
    'forced-color-adjust-none',
    'bg-gray-100',
  ],
  {
    variants: {
      isDisabled: {
        false: 'text-gray-900 dark:text-zinc-100',
        true: 'text-gray-300 dark:text-zinc-600 forced-colors:text-[GrayText]',
      },
      isFocused: {
        true: 'bg-gray-600 text-white forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]',
      },
      isOpen: {
        true: [''],
      },
    },
    compoundVariants: [
      {
        isFocused: false,
        isOpen: true,
        className: 'bg-gray-100 dark:bg-zinc-700/60',
      },
    ],
  }
);

let description = [
  'inline-block',
  'mt-2',
  'text-sm',
  'text-gray-500',
  'dark:text-gray-300',
];

let error = [...description, 'text-red-700'];

let section = [
  'first:-mt-[5px]',
  "after:content-['']",
  'after:block',
  'after:h-[5px]',
];

let sectionHeader = [
  'text-sm',
  'font-semibold',
  'text-gray-700',
  'px-4',
  'py-1',
  'truncate',
  'sticky',
  '-top-[5px]',
  '-mt-px',
  '-mx-1',
  'z-10',
  'bg-gray-300',
  'supports-[-moz-appearance:none]:bg-gray-300',
  'border-y',
  '[&+*]:mt-1',
];

const group = [
  'overflow-hidden',
  'text-gray-600',
  'dark:text-ew-text-dark',
  '[&_[cmdk-group-heading]]:px-2',
  '[&_[cmdk-group-heading]]:py-1',
  '[&_[cmdk-group-heading]]:text-xs',
  '[&_[cmdk-group-heading]]:text-gray-800',
  '[&_[cmdk-group-heading]]:bg-gray-100',
  '[&_[cmdk-group-heading]]:border-y',
  '[&_[cmdk-group-heading]]:uppercase',
  '[&_[cmdk-group-heading]]:font-bold',
  '[&_[cmdk-group-heading]]:tracking-wider',
];

const item = [
  'w-full',
  'relative',
  'flex',
  'text-gray-700',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'px-2',
  'py-1',
  'text-sm',
  'outline-none',
  'dark:text-ew-text-dark',
  'aria-selected:bg-ew-primary',
  'dark:aria-selected:bg-ew-primary-dark',
  'aria-selected:text-white',
  'dark:aria-selected:text-black',
  'data-[disabled]:pointer-events-none',
  'data-[disabled]:opacity-50',
];

const fullWidth = ['w-full'];

const combobox = cva(base, {
  variants: {
    intent: {},
    disabled: {
      true: disabled,
    },
    size: {},
    fullWidth: {
      true: fullWidth,
    },
    hasErrors: {
      true: errors,
    },
    forMoney: {
      true: forMoney,
    },
  },
  defaultVariants: {
    // size: "md",
  },
});

const command = cva(undefined, {
  variants: {
    fullWidth: {
      true: fullWidth,
    },
  },
  defaultVariants: {
    fullWidth: false,
  },
});

export {
  combobox,
  command,
  input,
  list,
  group,
  item,
  section,
  sectionHeader,
  button,
  isLoading,
  clearButton,
  listBox,
  listBoxItem,
  description,
  error,
};
