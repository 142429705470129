import * as React from 'react';
import * as $ from '@radix-ui/react-switch';
import * as T from './Switch.types';
import { cx } from '../../../utils';

const Switch = React.forwardRef<T.SwitchElement, T.SwitchProps>(
  ({ label, ...props }, forwardedRef) => {
    return (
      <$.Root
        {...props}
        className={cx([
          'relative',
          'flex',
          'w-full',
          'border-2',
          'border-gray-400',
          'rounded-md',
          'py-2',
        ])}
        ref={forwardedRef}
      >
        <$.Thumb
          className={cx([
            'block',
            'max-w-1/2',
            'border-2',
            'border-blue-800',
            'w-[120px]',
            'h-[20px]',
            'data-[state=checked]:bg-red-400',
            'data-[state=checked]:translate-x-1/3',
          ])}
        />
      </$.Root>
    );
  }
);

export { Switch };
