import { useMutation } from '@tanstack/react-query';
import { importBudget } from './ImportBudget.req';
import * as T from '../../Budget.types';

const useImportBudget = (budgetId: string, periodId: string) => {
  return useMutation({
    mutationFn: (data: T.BudgetExport[]) =>
      importBudget(data, budgetId, periodId),
  });
};

export { useImportBudget };
