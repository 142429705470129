import * as React from 'react';
import moment from 'moment';
import invariant from 'tiny-invariant';
import { Await, useLoaderData, useParams } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { byPrefixAndName, far } from '@awesome.me/kit-7767038e99/icons';
import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { Box, Money, Layout, Divider } from '../Common';
import { cx } from '../../utils';
import {
  transactionDetailQuery,
  getTransactionDetail,
} from './Transactions.loaders';

const TransactionsDetail = () => {
  const transactionQuery = useLoaderData() as UseSuspenseQueryResult<
    Awaited<ReturnType<typeof getTransactionDetail>>
  >;

  return (
    <Layout title="Transaction">
      <Box className={cx(['p-4'])}>
        <Box className={cx(['max-w-2xl', 'mx-auto', 'flex', 'flex-grow'])}>
          <React.Suspense fallback={<div>Loading from suspense...</div>}>
            <Await
              resolve={transactionQuery.data}
              errorElement={<div>Error!</div>}
            >
              <View />
            </Await>
          </React.Suspense>
        </Box>
      </Box>
    </Layout>
  );
};

let headerStyles = cx(['font-normal', 'text-gray-500', 'text-sm']);

function View() {
  const params = useParams<{ transactionId: string }>();
  invariant(params.transactionId, 'transactionId is required');

  const { data: txn } = useSuspenseQuery(
    transactionDetailQuery(params.transactionId)
  );

  let amount = txn.entries[0].amount;

  return (
    <Box className={cx(['w-full'])}>
      <Box
        className={cx([
          'border',
          'border-2',
          'border-gray-300',
          'p-5',
          'rounded-lg',
          'bg-gray-50',
        ])}
      >
        <Box className={cx(['flex', 'flex-row', 'justify-between'])}>
          <Box
            className={cx([
              'text-2xl',
              'font-normal',
              'text-gray-700',
              'space-x-2',
              'flex',
              'items-center',
            ])}
          >
            <Money value={amount} />
          </Box>
          <Box className={cx(['flex', 'space-x-1'])}>
            <p
              className={cx([
                'uppercase',
                'text-green-700',
                'font-semibold',
                'text-sm',
                'tracking-wider',
              ])}
            >
              Cleared
            </p>
            {/*<FontAwesomeIcon*/}
            {/*  aria-label={'Cleared'}*/}
            {/*  className={cx(['text-green-700', 'text-xl'])}*/}
            {/*  icon={byPrefixAndName.fad['circle-check']}*/}
            {/*  fixedWidth*/}
            {/*/>*/}
          </Box>
        </Box>
        <Divider size="xl" />
        <Box className={cx(['flex', 'items-center'])}>
          <Box className={cx(['w-8', 'flex', 'items-center'])}>
            {/*<FontAwesomeIcon*/}
            {/*  className={cx(['text-gray-400'])}*/}
            {/*  icon={far.faStore}*/}
            {/*  fixedWidth*/}
            {/*/>*/}
          </Box>
          <Box className={cx(['text-gray-700', 'font-semibold'])}>
            <p>{txn.payee.name}</p>
          </Box>
        </Box>
        <Divider />
        <Box className={cx(['flex', 'flex-row', 'items-center'])}>
          <Box className={cx(['w-8'])}>
            {/*<FontAwesomeIcon*/}
            {/*  className={cx(['text-gray-400'])}*/}
            {/*  icon={far.faCalendar}*/}
            {/*  fixedWidth*/}
            {/*/>*/}
          </Box>
          <Box className={cx(['text-gray-600'])}>
            <p>{moment(txn.date).format('DD MMM YYYY')}</p>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box className={cx(['flex', 'items-center'])}>
            <Box className={cx(['w-8'])}>
              {/*<FontAwesomeIcon*/}
              {/*  className={cx(['text-gray-400'])}*/}
              {/*  icon={far.faLandmark}*/}
              {/*  fixedWidth*/}
              {/*/>*/}
            </Box>
            <Box className={cx(['text-gray-600'])}>
              <p>{txn.category.name}</p>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box className={cx(['flex', 'items-center'])}>
            <Box className={cx(['w-8'])}>
              {/*<FontAwesomeIcon*/}
              {/*  className={cx(['text-gray-400'])}*/}
              {/*  icon={far.faMemo}*/}
              {/*  fixedWidth*/}
              {/*/>*/}
            </Box>
            <Box className={cx(['text-gray-600'])}>
              <p>{txn.memo}</p>
            </Box>
          </Box>
        </Box>
        <Divider size="xl" />

        {txn.entries.map((entry, index) => (
          <Box key={index}>
            <Box className={cx(['flex', 'items-center'])}>
              <Box className={cx(['w-8'])}>
                {/*<FontAwesomeIcon*/}
                {/*  className={cx(['text-gray-400'])}*/}
                {/*  icon={far.faWallet}*/}
                {/*  fixedWidth*/}
                {/*/>*/}
              </Box>
              <Box className={cx(['text-gray-600'])}>
                <p>{entry.account.name}</p>
              </Box>
            </Box>
            <Box className={cx(['flex', 'items-center'])}>
              <Box className={cx(['w-8'])}>
                {/*<FontAwesomeIcon*/}
                {/*  className={cx(['text-gray-400'])}*/}
                {/*  icon={far.faInfo}*/}
                {/*  fixedWidth*/}
                {/*/>*/}
              </Box>
              <Box className={cx(['text-gray-600'])}>
                <p>{entry.direction}</p>
              </Box>
            </Box>
            <Box className={cx(['flex', 'items-center'])}>
              <Box className={cx(['w-8'])}>
                {/*<FontAwesomeIcon*/}
                {/*  className={cx(['text-gray-400'])}*/}
                {/*  icon={far.faCommentDollar}*/}
                {/*  fixedWidth*/}
                {/*/>*/}
              </Box>
              <Box className={cx(['text-gray-600'])}>
                <Money value={entry.amount} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export { TransactionsDetail };
