import React, { useEffect, useState } from 'react';
import { useTransition, useSpring, animated } from '@react-spring/web';
import { FaAngleLeft, FaCog, FaDiscord } from 'react-icons/fa';
import * as Store from './Sidebar.store';
import { SidebarComponent } from './Sidebar.types';
import * as Settings from '../../Settings';
import { Box } from '../Box';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { IconButton } from '../IconButton';
import { cx } from '../../../utils';

// On Desktop, we want to make the Sidebar open by default and let the user close
// it with a button. Opening on Desktop will push the body of the content to the
// right.
// On Mobile we want to hide the Sidebar and open it as Flyout.
const Sidebar: SidebarComponent = ({
  header,
  compact = false,
  onChange,
  children,
  ...rest
}) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  // https://tailwindcss.com/docs/responsive-design
  const mediaQuery = window.matchMedia('(min-width: 1024px)');
  const isLarge = () => mediaQuery.matches;
  const [sidebarOpen, setSidebarOpen] = useState(isLarge);
  const sidebarStore = Store.useSidebarStore((state) => state);

  const sidebarSize = '19rem';

  const [springs, api] = useSpring(
    () => ({
      // from: { marginLeft: open ? '0%' : '-100%' },
      from: { marginLeft: sidebarOpen ? '0' : `${sidebarSize}` },
    }),
    []
  );

  const transition = useTransition(sidebarOpen ? [1] : [], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const openConfig = {
    from: { marginLeft: '0' },
    to: { marginLeft: `-${sidebarSize}` },
  };

  const closedConfig = {
    from: { marginLeft: `-${sidebarSize}` },
    to: { marginLeft: '0' },
  };

  useEffect(() => {
    const handler = (event: MediaQueryListEvent) => {
      if (event.matches) {
        openSidebar(true);
      } else {
        openSidebar(false);
      }
    };

    'addEventListener' in mediaQuery
      ? mediaQuery.addEventListener('change', handler)
      : // Add event listener for old safari browsers
        // @ts-ignore
        mediaQuery.addListener(handler);

    return () => {
      'addEventListener' in mediaQuery
        ? mediaQuery.removeEventListener('change', handler)
        : // Add event listener for old safari browsers
          // @ts-ignore
          mediaQuery.removeListener(handler);
    };
  }, []);

  const openSidebar = (open: boolean) => {
    setSidebarOpen(open);
    sidebarStore.setSidebarOpen(true);
    api.start(open ? closedConfig : openConfig);
    onChange?.(open);
  };

  const handleOpen = () => {
    setSidebarOpen(!sidebarOpen);
    sidebarStore.setSidebarOpen(sidebarOpen);
    api.start(sidebarOpen ? openConfig : closedConfig);
    onChange?.(!sidebarOpen);
  };

  return (
    <>
      {!mediaQuery.matches
        ? transition((style, item) => (
            // Show Overlay only on mobile
            <animated.div
              style={style}
              onClick={() => {
                if (sidebarOpen) {
                  openSidebar(false);
                }
              }}
              className={cx(
                [
                  'fixed',
                  'inset-0',
                  'z-40',
                  'bg-gray-800/10',
                  'backdrop-blur-sm',
                ],
                item
              )}
              aria-hidden="true"
            />
          ))
        : null}
      <animated.div
        data-theme="dark"
        data-open={sidebarOpen}
        style={{
          ...springs,
        }}
        className={cx(
          [
            'z-50',
            'flex',
            'flex-col',
            'gap-y-5',
            'overflow-y-auto',
            'overflow-visible',
            'border-r',
            'border-r-ew-border',
            'border-ew-text-400',
            'shadow-sm',
            'bg-ew-bg-dark-600',
            'py-2',
            'px-4',
            'h-full',
          ],
          rest.className,
          {
            'gap-y-3': compact,
            'fixed inset-y-0': !mediaQuery.matches,
          }
        )}
      >
        <Box className={cx(['flex', 'items-center'])}>
          {header}
          <Modal
            open={settingsOpen}
            setOpen={setSettingsOpen}
            className={cx(['w-1/2'])}
            trigger={
              <IconButton
                intent="ghost"
                className={cx(['rounded-md', 'ml-auto', 'group'])}
                iconClasses={cx([
                  'transition-transform',
                  'duration-300',
                  'hover:rotate-180',
                  'text-gray-300',
                  'dark:group-hover:text-white',
                ])}
                label={'Settings'}
                icon={FaCog}
              />
            }
          >
            <Settings.Form
              onCancel={() => setSettingsOpen(false)}
              onSuccess={() => setSettingsOpen(false)}
            />
          </Modal>
          <IconButton
            className={cx([
              'rounded-md',
              'ml-2',
              'transition-transform',
              'delay-300',
              'duration-500',
              'group',
              {
                'translate-x-[4rem] -translate-y-2 rotate-180': !sidebarOpen,
              },
            ])}
            iconClasses={cx(['text-gray-300', 'dark:group-hover:text-white'])}
            icon={FaAngleLeft}
            label={'Open Sidebar'}
            onClick={handleOpen}
          />
        </Box>
        {children}

        <Box className={cx(['py-4'])}>
          <Button
            as="a"
            href="https://discord.gg/QvyvNuDJCk"
            fullWidth
            className={cx(['flex', 'items-center', 'justify-center'])}
          >
            <FaDiscord className={cx('text-2xl', 'mr-2')} />
            <span className={cx(['text-base'])}>Join our Discord</span>
          </Button>
        </Box>
      </animated.div>
    </>
  );
};

export { Sidebar };
