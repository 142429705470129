import { api } from '../../api';
import { AxiosResponse } from 'axios';
import * as T from './Payees.types';
import * as C from './Payees.constants';

const getPayees = async (budgetId: string): Promise<T.PayeeRead[]> => {
  const response: AxiosResponse<T.PayeeRead[]> = await api.get(
    `/${C.API_URL}/`,
    {
      params: {
        budget_id: budgetId,
      },
    }
  );

  return response.data;
};

const postPayee = async (newPayee: T.PayeeWrite): Promise<T.PayeeRead> => {
  const response: AxiosResponse<T.PayeeRead> = await api.post(
    `/${C.API_URL}/`,
    newPayee
  );

  return response.data;
};

export { getPayees, postPayee };
