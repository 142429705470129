import * as React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Button, Divider, Input } from '../Common';
import type * as T from './CategoryGroups.types';
import { cx } from '../../utils';

const CategoryGroupForm = React.forwardRef<
  T.CategoryGroupFormElement,
  T.CategoryGroupFormProps
>(({ isLoading = false, onSubmit, onCancel, ...props }, forwardedRef) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<T.CategoryGroupFormInputs>({
    defaultValues: {
      name: '',
    },
  });

  const submitHandler: SubmitHandler<T.CategoryGroupFormInputs> = (data) => {
    onSubmit?.(data);
  };

  return (
    <Box
      as="form"
      {...props}
      onSubmit={handleSubmit(submitHandler)}
      ref={forwardedRef}
    >
      <Box>Create a new Category Group</Box>
      <Divider />
      <Box>
        <Input
          label="Name"
          fullWidth
          hasErrors={!!errors.name}
          helpText={errors?.name?.message}
          {...register('name', { required: true })}
        />
      </Box>
      <Divider size="xl" />
      <Box className={cx(['flex', 'justify-end', 'space-x-3'])}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>
        <Button type="submit" intent="primary" isLoading={isLoading}>
          Save
        </Button>
      </Box>
    </Box>
  );
});

export { CategoryGroupForm };
