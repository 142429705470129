import React, { forwardRef, ElementType, ReactNode } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { cx } from '../../../utils';
import { ButtonComponent, ButtonProps } from './Button.types';
import { buttonStyles } from './Button.styles';
import { PolymorphicRef } from '../Common.types';

const ButtonIconWrapper = ({ children }: { children: ReactNode }) => (
  <span
    className={cx([
      'absolute',
      'top-[50%]',
      'left-[50%]',
      '-translate-x-2/4',
      '-translate-y-2/4',
    ])}
  >
    {children}
  </span>
);

/**
 *
 * The `Button` component.
 */
// @ts-ignore
const Button: ButtonComponent = forwardRef(
  <C extends ElementType = 'button'>(
    {
      as,
      isLoading,
      isDisabled,
      intent,
      size,
      fullWidth,
      children,
      icon: Icon,
      hasErrors,
      ...rest
    }: ButtonProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Component = as || 'button';

    const classes = cx(
      buttonStyles({ intent, size, fullWidth, isLoading, hasErrors }),
      rest.className,
      {}
    );

    return (
      <Component {...rest} className={classes} ref={ref} disabled={isDisabled}>
        {isLoading ? (
          <>
            <ButtonIconWrapper>
              <CgSpinner className="fa-spin" />
            </ButtonIconWrapper>
            <div className={cx([{ 'opacity-0': isLoading }])}>{children}</div>
          </>
        ) : Icon ? (
          <div className={cx(['flex', 'items-center', 'justify-center'])}>
            <Icon className={cx(['mr-1'])} />
            {children}
          </div>
        ) : (
          <>{children}</>
        )}
      </Component>
    );
  }
);

export { Button };
