import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Budgets from '../Budget';
import { ACCOUNT_TOTALS_KEY } from './Account.constants';
import * as T from './Account.types';
import * as C from './Account.constants';
import {
  getAccount,
  deleteAccount,
  postAccount,
  patchAccount,
  getAccounts,
  getInternalAccounts,
  getAccountTotals,
} from './Account.req';

const useDeleteAccount = (budgetId: string) => {
  const queryClient = useQueryClient();
  const ACCOUNTS_KEY = [C.ACCOUNTS_QUERY_KEY, budgetId];
  return useMutation({
    mutationKey: [C.DELETE_ACCOUNT_MUTATION_KEY],
    mutationFn: deleteAccount,
    onMutate: async (accountId) => {
      await queryClient.invalidateQueries({
        queryKey: ACCOUNTS_KEY,
      });

      const previousAccounts: T.AccountRead[] | undefined =
        queryClient.getQueryData(ACCOUNTS_KEY);

      queryClient.setQueryData(
        ACCOUNTS_KEY,
        previousAccounts?.filter((account) => account.id !== accountId)
      );

      return { previousAccounts, accountId };
    },

    onError: (err, account, context) => {
      queryClient.setQueryData(ACCOUNTS_KEY, context?.previousAccounts);
    },
    onSettled: async (accountId) => {
      await queryClient.invalidateQueries({
        queryKey: ACCOUNTS_KEY,
      });
    },
  });
};

const usePostAccount = (budgetId: string) => {
  const queryClient = useQueryClient();
  const ACCOUNTS_KEY = [C.ACCOUNTS_QUERY_KEY, budgetId];

  return useMutation({
    mutationFn: (newAccount: T.AccountPost) =>
      postAccount(newAccount, budgetId),
    onMutate: async (account) => {
      // const ACCOUNT_KEY = ['accountInfo', account.id, budgetId]

      await queryClient.invalidateQueries({
        queryKey: ACCOUNTS_KEY,
      });

      const previousAccounts: T.AccountRead[] | undefined =
        queryClient.getQueryData(ACCOUNTS_KEY);

      queryClient.setQueryData(ACCOUNTS_KEY, [
        // We are pleasing TS here. Should never be undefined and if it is there's a bug.
        ...(previousAccounts ?? []),
        account,
      ]);

      return { previousAccounts, account };
    },
    onError: (err, account, context) => {
      queryClient.setQueryData(ACCOUNTS_KEY, context?.previousAccounts);
    },
    onSettled: async (account) => {
      await queryClient.invalidateQueries({
        queryKey: ACCOUNTS_KEY,
      });
    },
  });
};

const usePatchAccount = (budgetId: string) => {
  const queryClient = useQueryClient();
  const ACCOUNTS_KEY = [C.ACCOUNTS_QUERY_KEY, budgetId];

  return useMutation({
    mutationFn: patchAccount,
    onMutate: async (account: T.AccountPost | T.AccountPatch) => {
      // If account has id then it's an optimistic update
      const isOptimisticUpdate = 'id' in account;

      const ACCOUNT_KEY = isOptimisticUpdate
        ? [C.ACCOUNT_INFO_QUERY_KEY, account.id, budgetId]
        : [];

      await queryClient.invalidateQueries({
        queryKey: ACCOUNTS_KEY,
      });
      await queryClient.invalidateQueries({
        queryKey: ACCOUNT_KEY,
      });

      const previousAccount: T.AccountRead | undefined =
        queryClient.getQueryData(ACCOUNT_KEY);
      const previousAccounts: T.AccountRead[] | undefined =
        queryClient.getQueryData(ACCOUNTS_KEY);

      queryClient.setQueryData(ACCOUNT_KEY, account);
      queryClient.setQueryData(
        ACCOUNTS_KEY,
        previousAccounts?.map((previousAccount) => {
          if (isOptimisticUpdate && previousAccount.id === account.id) {
            return account;
          }

          return previousAccount;
        })
      );

      return { previousAccount, previousAccounts, account };
    },

    onError: (err, account, context) => {
      queryClient.setQueryData(ACCOUNTS_KEY, context?.previousAccounts);
      if ('id' in account) {
        queryClient.setQueryData(
          [C.ACCOUNT_INFO_QUERY_KEY, account.id, budgetId],
          context?.previousAccount
        );
      }
    },
    onSettled: async (account) => {
      await queryClient.invalidateQueries({
        queryKey: ACCOUNTS_KEY,
      });

      await queryClient.invalidateQueries({
        queryKey: [C.ACCOUNT_INFO_QUERY_KEY, account?.id, budgetId],
      });
    },
  });
};

const useGetAccounts = (budgetId: string) => {
  return useQuery({
    queryKey: [C.ACCOUNTS_QUERY_KEY, budgetId],
    queryFn: () => getAccounts(budgetId),
  });
};

// Used for loaders
let getAccountsQuery = (budgetId: string) => {
  return {
    queryKey: [C.ACCOUNTS_QUERY_KEY, budgetId],
    queryFn: () => getAccounts(budgetId),
  };
};

const useGetInternalAccounts = (budgetId: string) => {
  return useQuery({
    queryKey: [C.INTERNAL_ACCOUNTS_QUERY_KEY, budgetId],
    queryFn: () => getInternalAccounts(budgetId),
  });
};

const useGetAccount = (accountId: string, budgetId: string) => {
  return useQuery<T.AccountRead, AxiosError>({
    queryKey: [C.ACCOUNT_INFO_QUERY_KEY, accountId, budgetId],
    queryFn: () => getAccount(accountId),
  });
};

const useGetAccountTotals = (
  accountId: string,
  budgetId: string,
  periodId?: string,
  budgetingStyle: string = Budgets.C.BUDGETING_STYLES.GLOBAL_LTB,
  params = {}
) => {
  const queryKey = [
    ACCOUNT_TOTALS_KEY,
    accountId,
    budgetId,
    periodId,
    budgetingStyle,
    params,
  ];
  return useQuery<T.AccountTotals, AxiosError>({
    queryKey,
    queryFn: () =>
      getAccountTotals(accountId, budgetId, periodId, budgetingStyle, params),
    enabled: !!accountId,
  });
};

export {
  useGetAccountTotals,
  useGetAccount,
  useGetAccounts,
  useGetInternalAccounts,
  usePatchAccount,
  usePostAccount,
  useDeleteAccount,
  getAccountsQuery,
};
