import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useState } from 'react';
import * as _ from 'lodash-es';
import * as Category from '../../Categories';
import * as Transactions from '../../Transactions';
import { Box, Button, Money, Modal, ActivityTable } from '../../Common';
import { cx } from '../../../utils';

const categoryActivity: Partial<ColumnDef<Category.T.Category>> = {
  cell: ({
    getValue,
    row: { index, original, getCanExpand },
    // column: { id: columnId },
    table,
  }) => {
    const initialValue = getValue();
    const isCC = table.options.meta?.isCC ?? false;
    const settings = table.options.meta?.settings;
    const periodId = table.options.meta?.periodId;
    const monthName = moment(periodId).format('MMMM YYYY');

    /* eslint-disable react-hooks/rules-of-hooks */
    const [value, setValue] = useState(initialValue);

    if (!getCanExpand()) {
      const baseParams = {
        internal_type: 'TRANSACTION',
        // Don't include income transactions.
        exclude_categories: String(table.options.meta?.leftToBudgetId),
        approved: true,
      };

      // const params = isCC
      const params = isCC
        ? {
            ...baseParams,
            internal_type: null,
            internal_types: 'TRANSACTION,TRANSFER',
            account_id: original.id,
            type: 'OUTFLOW',
          }
        : {
            ...baseParams,
            category_id: original.id,
          };

      // We want to get all transactions from all accounts filtered by the current category.
      const activity = Transactions.H.useGetTransactionsByPeriod(
        String(table.options.meta?.budgetId), // Budget id
        String(periodId), // Period
        params
      );

      // if (isCC && original.name === 'Venture (9421)') {
      //   console.group('activity');
      //   console.log('activity', activity.data, original, params);
      //   console.groupEnd();
      // }

      // console.log('activity', original.name, activity.data?.results, original);
      const sum = _.sum(
        activity.data?.results?.map((t) => {
          if (t.internal_type === 'TRANSFER') {
            return -Math.abs(Number(t.entries[0].amount));
          }

          if (t.type === 'OUTFLOW') {
            return Number(t.entries[0].amount);
          } else {
            return -Math.abs(Number(t.entries[0].amount));
          }
        })
      );

      // console.log('sum', sum);

      if (sum <= 0) {
        return (
          <Box className={cx(['px-2', 'py-0.5'])}>
            <Money value={sum} />
          </Box>
        );
      }

      return (
        <Modal
          trigger={
            <Button
              intent="ghost"
              className={cx([
                'text-base',
                '-m-l-4',
                'px-2',
                'py-0.5',
                'underline',
                'hover:no-underline',
              ])}
            >
              <Money value={sum} />
            </Button>
          }
          className={cx(['w-1/2'])}
        >
          <ActivityTable
            isCC={isCC}
            // @ts-ignore
            account={original}
            month={monthName}
            data={activity?.data?.results ?? []}
            total={sum}
            settings={settings}
          />
        </Modal>
      );
    }

    return value;
  },
};

export { categoryActivity };
