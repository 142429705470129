import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type SidebarState = {
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
};

const useSidebarStore = create<SidebarState>()(
  devtools((set) => ({
    sidebarOpen: false,
    setSidebarOpen: (sidebarOpen) => set({ sidebarOpen }),
  }))
);

export { useSidebarStore };
