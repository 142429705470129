import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import * as Accounts from '../../Account';
import { Money, Badge, ThreeDots } from '../../Common';
import * as Category from '../../Categories';
import { cx } from '../../../utils';

const categoryRemaining: Partial<ColumnDef<Category.T.Category>> = {
  cell: ({
    getValue,
    row: { index, original, getIsSelected, getCanExpand },
    // column: { id: columnId },
    table,
  }) => {
    const initialValue = getValue();

    const isCC = table.options.meta?.isCC ?? false;

    /* eslint-disable react-hooks/rules-of-hooks */
    const [value, setValue] = useState(initialValue);

    if (!getCanExpand()) {
      const accountTotals = Accounts.H.useGetAccountTotals(
        original.id,
        String(table.options.meta?.budgetId),
        String(table.options.meta?.periodId),
        undefined,
        {
          balance_type: 'REMAINING',
        }
      );

      const moneyValue = String(accountTotals.data?.approved);

      if (accountTotals.isLoading) {
        return <ThreeDots size="sm" />;
      }

      const intent = Number(moneyValue) <= 0 || isCC ? 'secondary' : 'success';

      return (
        <Badge
          intent={intent}
          className={cx(['text-sm', 'py-0.5', 'px-2', 'rounded-lg'])}
        >
          <Money value={moneyValue} />
        </Badge>
      );
    }

    return value;
  },
};

export { categoryRemaining };
