import * as React from 'react';
import { IconButton } from '../IconButton';
import { match, P } from 'ts-pattern';
import * as $ from '@radix-ui/react-dialog';
import * as T from './Modal.types';
import * as styles from './Modal.styles';
import { Button } from '../Button';
import { Box } from '../Box';
import { cx } from '../../../utils';
import { FaTimes } from 'react-icons/fa';

const Modal = React.forwardRef<T.ModalElement, T.ModalProps>(
  ({ trigger, open, setOpen, children, ...props }, forwardedRef) => {
    return (
      <$.Root open={open} onOpenChange={setOpen}>
        <$.Trigger asChild>
          {match(trigger)
            .with(P.not(P.nullish), (t) => t)
            .otherwise(() => null)}
        </$.Trigger>
        <$.Portal>
          <$.Overlay className={cx([styles.overlay])} />
          <$.Content
            {...props}
            className={cx([styles.content, props.className])}
            ref={forwardedRef}
            onEscapeKeyDown={() => setOpen?.(false)}
          >
            <Box>{children}</Box>
            <$.Close asChild aria-label="Close">
              <IconButton
                className={cx([styles.close])}
                label="Close"
                icon={FaTimes}
              />
            </$.Close>
          </$.Content>
        </$.Portal>
      </$.Root>
    );
  }
);

export { Modal };
