import { FilterFn, SortingFn, sortingFns } from '@tanstack/react-table';
import { compareItems, rankItem } from '@tanstack/match-sorter-utils';
import * as Account from '../Account';
import * as Entries from '../Entries';
import * as Const from './Transactions.constants';
import * as T from './Transactions.types';
import invariant from 'tiny-invariant';
import * as Transactions from './index';

const fuzzyPayeeFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  return true;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store itemRank info
  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

// What type of transaction we get?
const generateEntryFromOptimisticUpdate = (
  transaction: T.TransactionOptimistic
): Entries.Types.EntryOptimistic => {
  invariant(
    transaction.type !== Const.TYPES.INFLOW ||
      transaction.type !== Const.TYPES.OUTFLOW,
    'Transaction type must be INFLOW or OUTFLOW'
  );

  invariant(transaction.account_id, 'Transaction must have an account_id');

  return {
    amount: transaction.amount,
    account: {
      id: transaction.account_id,
    },
    direction:
      transaction.type === Const.TYPES.INFLOW
        ? Entries.Constants.DIRECTIONS.DEBIT
        : Entries.Constants.DIRECTIONS.CREDIT,
    // data that will get synced with the server
    id: '',
    account_id: '',
    budget: '',
    user: '',
  };
};

/**
 * The API returns transactions with an array of entries attached. We need to format
 * the transaction so that it can be displayed in the table.
 *
 * Note: This function is used for ASSETs only.
 * Note: Also, we might receive transactions with empty `entries` array. This happens
 * on optimistic updates.
 */
const formatTransaction = (
  transaction: T.TransactionRead | T.TransactionOptimistic
): T.TableTransaction => {
  let originAccount = {} as Account.T.AccountRead;
  let destinationAccount = {} as Account.T.AccountRead;
  let type = '' as T.TransactionType;
  let entryToDisplay = {} as Entries.Types.EntryRead;

  transaction.entries.forEach((entry) => {
    if (entry.account.id === transaction.account.id) {
      // @ts-ignore
      originAccount = entry.account;
      entryToDisplay = entry;
    } else {
      // @ts-ignore
      destinationAccount = entry.account;
    }
  });

  const account = originAccount;
  const category = destinationAccount;

  // Asset, e.g., Bank account
  if (account.internal_type === Account.C.INTERNAL_TYPES.ASSET) {
    if (entryToDisplay.direction === Entries.Constants.DIRECTIONS.DEBIT) {
      type = Const.TYPES.INFLOW;
    }

    if (entryToDisplay.direction === Entries.Constants.DIRECTIONS.CREDIT) {
      type = Const.TYPES.OUTFLOW;
    }
  }

  // Liability, e.g., Credit card
  if (account.internal_type === Account.C.INTERNAL_TYPES.LIABILITY) {
    if (entryToDisplay.direction === Entries.Constants.DIRECTIONS.CREDIT) {
      type = Const.TYPES.OUTFLOW;
    }

    if (entryToDisplay.direction === Entries.Constants.DIRECTIONS.DEBIT) {
      type = Const.TYPES.INFLOW;
    }
  }

  // Make it easier to display the amount in the table
  const inflow = type === Const.TYPES.INFLOW ? entryToDisplay.amount : '0';
  const outflow = type === Const.TYPES.OUTFLOW ? entryToDisplay.amount : '0';

  // console.group('formatTransaction');
  // console.log()
  // console.groupEnd()

  // TODO:FIX
  // @ts-ignore
  return {
    ...transaction,
    amount: entryToDisplay.amount,
    type,
    category,
    inflow,
    outflow,
  };
};

/**
 * Get the entry affecting the current account.
 */
const getCurrentAccountEntry = (transaction: T.TableTransaction) => {
  invariant(transaction.account, 'Transaction must have an account');

  const result = transaction.entries?.find(
    (entry) => entry.account.id === transaction.account?.id
  );

  return result;
};

const getOppositeAccountEntry = (transaction: T.TableTransaction) => {
  invariant(transaction.account, 'Transaction must have an account');

  const result = transaction.entries?.find(
    (entry) => entry.account.id !== transaction.account?.id
  );

  return result;
};

/**
 * Get the entries affecting the current and opposite accounts.
 */
const getEntries = (transaction: T.TableTransaction) => {
  invariant(transaction.account, 'Transaction must have an account');
  let origin = {} as Entries.Types.EntryRead;
  let destination = {} as Entries.Types.EntryRead;

  transaction.entries?.forEach((entry) => {
    invariant(transaction.account, 'Transaction must have an account');
    invariant('id' in transaction.account, 'Transaction must have an account');
    if (entry.account.id === transaction.account.id) {
      origin = entry;
    } else {
      destination = entry;
    }
  });

  return { origin, destination };
};

const findTransactionByCategory = (
  transactions: Transactions.T.TransactionRead[],
  categoryId: string
): Transactions.T.TransactionRead | undefined =>
  transactions.find((txn) => txn.category_id === categoryId);

export {
  fuzzySort,
  fuzzyPayeeFilter,
  fuzzyFilter,
  formatTransaction,
  getCurrentAccountEntry,
  getOppositeAccountEntry,
  getEntries,
  // generateEntries,
  generateEntryFromOptimisticUpdate,
  findTransactionByCategory,
};
