import React from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import { type VariantProps } from 'class-variance-authority';

import { cx } from '../../../utils';
import { toggleStyles } from './Toggle.styles';

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleStyles>
>(({ className, intent, size, ...props }, ref) => {
  const classes = cx(toggleStyles({ intent, size, className }));
  return <TogglePrimitive.Root ref={ref} className={classes} {...props} />;
});

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle };
