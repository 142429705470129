import * as React from 'react';
import * as CheckboxPrim from '@radix-ui/react-checkbox';
import { FaRegSquare, FaCheckSquare } from 'react-icons/fa';

import { cx } from '../../../utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrim.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrim.Root>
>(({ className, ...props }, ref) => {
  return (
    <CheckboxPrim.Root
      ref={ref}
      className={cx(
        [
          'relative',
          'ring-offset-background',
          'disabled:cursor-not-allowed',
          'disabled:opacity-50',
          'text-gray-500',
        ],
        className
      )}
      {...props}
    >
      <FaRegSquare className={cx(['text-lg'])} />
      <CheckboxPrim.Indicator
        className={cx(['absolute', 'top-0', 'left-0', 'z-10', 'text-lg'])}
      >
        <FaCheckSquare />
      </CheckboxPrim.Indicator>
    </CheckboxPrim.Root>
  );
});
Checkbox.displayName = CheckboxPrim.Root.displayName;

export { Checkbox };
