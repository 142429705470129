import invariant from 'tiny-invariant';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import { ENTRIES_QUERY_KEY } from './Entries.constants';
import { EntryRead } from './Entries.types';
import * as req from './Entries.req';
import * as C from './Entries.constants';

let useGetEntriesByPeriod = (
  budgetId: string,
  periodId: string,
  params = {}
) => {
  return useQuery({
    queryKey: [C.ENTRIES_QUERY_KEY, budgetId, periodId, params],
    queryFn: () => req.getEntriesByPeriod(budgetId, 0, periodId, params),
  });
};

const patchEntry = async (entry: Partial<EntryRead>): Promise<EntryRead> => {
  const { id, ...rest } = entry;
  invariant(id, 'id is required.');

  const response: AxiosResponse<EntryRead, AxiosError> = await api.patch(
    `/entries/${id}/`,
    rest
  );

  return response.data;
};

const usePatchEntry = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchEntry,
    onMutate: async (newEntry) => {
      const key = [ENTRIES_QUERY_KEY, newEntry.id];
      await queryClient.cancelQueries({ queryKey: key });

      const previousEntry = queryClient.getQueryData(key);

      queryClient.setQueryData(key, newEntry);

      return { previousEntry, newEntry };
    },
    onError: (err, newEntry, context) => {
      const key = [ENTRIES_QUERY_KEY, context?.newEntry.id];
      queryClient.setQueryData(key, context?.previousEntry);
    },
    onSettled: async (newEntry) => {
      const key = [ENTRIES_QUERY_KEY, newEntry?.id];
      await queryClient.invalidateQueries({ queryKey: key });
    },
  });
};

export { usePatchEntry };
