import Papa from 'papaparse';
import * as Budgets from '../../Budget';
import * as Categories from '../../Categories';
import * as CategoryGroups from '../../CategoryGroups';

// Formats the category groups into a format that can be exported to CSV
const groupsToBudgetExport = (
  groups: CategoryGroups.T.CategoryGroupGet<Categories.T.CategoryTable>[]
): Budgets.T.BudgetExport[] => {
  let budgetExports: Budgets.T.BudgetExport[] = [];

  groups.forEach((group) => {
    const groupName = group.name;
    group?.accounts.forEach((account) => {
      budgetExports.push({
        categoryGroup: groupName,
        category: account.name,
        dueDate: account.due_date,
        budgeted: Number(account?.amount ?? '0.00'),
      });
    });
  });

  return budgetExports;
};

const downloadCsv = (data: Budgets.T.BudgetExport[], periodId: string) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const csvUrl = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvUrl;
  tempLink.setAttribute('download', `budget-export-${periodId}.csv`);
  tempLink.click();
};

export { downloadCsv, groupsToBudgetExport };
