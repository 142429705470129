import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import * as _ from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  FilterFn,
  flexRender,
  getSortedRowModel,
  GroupingState,
  ExpandedState,
  SortingState,
} from '@tanstack/react-table';
import { FaTrash, FaAngleDown, FaAngleLeft } from 'react-icons/fa6';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import * as Category from '../Categories';
import * as DeleteCategory from './components/DeleteCategory';
import { fuzzyFilter } from '../Transactions/Transactions.utils';
import { Box, IconButton, Modal, Divider } from '../Common';
import {
  categoryName,
  categoryRemaining,
  categoryDueDate,
  categoryActivity,
  categoryBudgeted,
} from './column-fields';
import { BudgetTableComponent } from './Budget.types';
import { cx } from '../../utils';
import * as Accounts from '../Account';
import * as Transactions from '../Transactions';
import * as CategoryGroups from '../CategoryGroups';
import * as C from '../CategoryGroups/CategoryGroups.constants';
// Category Fields
// - name
// - due date
// - budgeted
// - activity
// - remaining/amount due

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const BudgetTable: BudgetTableComponent = ({
  isCC = false,
  latestCategory = '',
  data = [],
  budgetId,
  periodId,
  leftToBudgetId,
  onBudgetedAmountUpdate,
  onCategoryUpdate,
  settings,
}) => {
  const queryClient = useQueryClient();
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);
  const [expanded, setExpanded] = useState<ExpandedState>(true);
  const [grouping, setGrouping] = useState<GroupingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  // const [deleteCategoryModalIsOpen, setDeleteCategoryModalIsOpen] =
  //   useState(false);

  const columns = useMemo<ColumnDef<Category.T.Category>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        ...categoryName,
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        ...categoryDueDate,
      },
      {
        accessorKey: 'amount',
        header: 'Budgeted',
        ...categoryBudgeted,
      },
      {
        accessorKey: 'activity',
        header: 'Activity',
        ...categoryActivity,
      },
      {
        accessorKey: 'remaining',
        header: isCC ? 'Amount Due' : 'Remaining',
        ...categoryRemaining,
      },
      {
        accessorKey: 'actions',
        header: ({ table }) => (
          <>
            <IconButton
              intent={'ghost'}
              label={'Open'}
              icon={table.getIsAllRowsExpanded() ? FaAngleDown : FaAngleLeft}
              onClick={table.getToggleAllRowsExpandedHandler()}
            />
          </>
        ),
        cell: ({ row, column, table }) => {
          const deleteCategory = DeleteCategory.H.useDeleteCategory();
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [modalOpen, setModalOpen] = useState(false);

          const categoryGroupQueryKey = [CategoryGroups.C.QUERY_KEY, budgetId];

          const periodIdForLeftToBudget =
            settings.budgeting_style !== 'GLOBAL_LTB' ? periodId : '';

          const leftToBudgetQueryKey = [
            Accounts.C.ACCOUNT_TOTALS_KEY,
            leftToBudgetId,
            budgetId,
            periodIdForLeftToBudget,
            settings.budgeting_style,
          ];

          const budgetedForTheMonthQueryKey = [
            Transactions.C.TRANSACTIONS_QUERY_KEY,
            budgetId,
            periodId,
            {
              account_id: String(leftToBudgetId),
              internal_type: 'BUDGET',
            },
          ];

          // If it's a category group and doesn't have any categories then prevent
          // deletion
          const isGroupWithCategories =
            row.getCanExpand() && !_.isEmpty(row.subRows);
          // if (isGroupWithCategories) {
          //   return null;
          // }

          const handleDelete = async () => {
            const toastId = toast.loading('Deleting category...');
            deleteCategory.mutate(row.original.id, {
              onSuccess: async () => {
                toast.success('Category deleted', { id: toastId });
                setModalOpen(false);

                // ~TODO~:
                // - [x] Clear LTB
                await queryClient.invalidateQueries({
                  queryKey: leftToBudgetQueryKey,
                });

                // - [x] Clear Budgeted
                await queryClient.invalidateQueries({
                  queryKey: budgetedForTheMonthQueryKey,
                });

                // - [x] Clear category from the table
                await queryClient.invalidateQueries({
                  queryKey: categoryGroupQueryKey,
                });
              },
              onError: () => {
                // console.error('Error deleting category', error);
                toast.error('Error deleting category', { id: toastId });
              },
            });
          };

          return (
            <Box
              className={cx(
                [
                  'flex',
                  // 'opacity-0',
                  'items-center',
                  'justify-end',
                  // 'group-hover:opacity-100',
                ]
                // {
                //   'group-hover:opacity-50': row.subRows.length > 0,
                // }
              )}
            >
              {row.getCanExpand() ? (
                <>
                  <IconButton
                    label={'Open'}
                    icon={row.getIsExpanded() ? FaAngleDown : FaAngleLeft}
                    onClick={row.getToggleExpandedHandler()}
                  />
                  <Divider />
                </>
              ) : null}

              <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                trigger={
                  <IconButton
                    disabled={isGroupWithCategories}
                    className={cx([
                      'text-sm',
                      'text-gray-600',
                      'bg-transparent',
                    ])}
                    label={'Delete'}
                    icon={FaTrash}
                    size="sm"
                  />
                }
              >
                <DeleteCategory.Form
                  onCancel={() => setModalOpen(false)}
                  onSubmit={handleDelete}
                  isLoading={deleteCategory.isPending}
                />
              </Modal>
            </Box>
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      grouping,
      globalFilter,
      expanded,
      sorting,
    },
    enableExpanding: true,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.accounts,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    // Sorting
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // @ts-ignore
    meta: {
      isCC,
      latestCategory,
      settings,
      budgetId,
      periodId,
      leftToBudgetId,
      onCategoryUpdate(rowIndex, columnId, value) {
        onCategoryUpdate?.(value);
      },
      onBudgetedAmountUpdate(rowIndex, columnId, value) {
        onBudgetedAmountUpdate?.(value);
      },
    },
  });

  return (
    <Box
      className={cx([
        'min-w-full',
        'rounded-xl',
        'overflow-visible',
        'align-middle',
        // 'shadow',
        'sm:rounded-lg',
        'border',
        'border-zinc-300',
        'dark:border-gray-700',
      ])}
    >
      <table
        className={cx([
          'rounded-xl',
          'min-w-full',
          'divide-y',
          'divide-gray-200',
          'dark:divide-gray-700',
        ])}
      >
        <thead>
          {table?.getHeaderGroups().map((headerGroup) => {
            return (
              <tr
                className={cx([
                  'bg-zinc-200',
                  'whitespace-nowrap',
                  'px-2',
                  'py-3.5',
                  'text-left',
                  'font-semibold',
                  'text-gray-900',
                ])}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className={cx([
                        // 'bg-gray-50',
                        'dark:bg-ew-bg-dark',
                        'px-3',
                        'py-2',
                        'text-left',
                        'text-xs',
                        'font-normal',
                        'text-zinc-600',
                        'dark:text-ew-text-dark',
                        'uppercase',
                        'font-semibold',
                      ])}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody
          className={cx([
            'divide-y',
            'divide-gray-200',
            'bg-white',
            'dark:bg-ew-bg-dark',
            'dark:divide-gray-700',
          ])}
        >
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                className={cx([
                  'group',
                  'dark:bg-ew-bg-dark-600',
                  'dark:text-ew-text-dark',
                  'dark:focus-within:ring-ew-primary-dark',
                ])}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      className={cx(
                        [
                          'whitespace-nowrap',
                          'px-3',
                          'py-1',
                          'text-gray-900',
                          'dark:text-ew-text-dark',
                        ],
                        {
                          // 'py-1.5 bg-gray-100 border-b-2 border-t-2 border-t-gray-200 border-b-ew-primary':
                          'py-1.5 bg-zinc-100 border-y border-y-gray-300':
                            row.getCanExpand(),
                          hidden:
                            !settings?.show_due_date &&
                            cell.column.id === 'due_date',
                        }
                      )}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export { BudgetTable };
