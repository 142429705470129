import { cva } from "class-variance-authority";

const cardStyles = cva(
  [
    "overflow-hidden",
    "bg-white",
    "border",
    "border-ew-border",
    "dark:border-ew-border-dark",
    "shadow-sm",
    "rounded-lg",
    "dark:bg-ew-bg-dark",
  ],
  {
    variants: {},
    defaultVariants: {},
  }
);

const cardTitleStyles = cva(
  [
    "border-b",
    "border-ew-border",
    "dark:border-ew-border-dark",
    "bg-white",
    "dark:bg-ew-bg-dark",
    "text-ew-text",
    "dark:text-ew-text-dark",
    "px-4",
    "py-5",
    "sm:px-6",
  ],
  {
    variants: {},
    defaultVariants: {},
  }
);

const cardActionsStyles = cva(["card-actions"], {
  variants: {},
  defaultVariants: {},
});

const cardBodyStyles = cva(
  [
    "max-w-xl",
    "text-sm",
    "text-ew-text",
    "dark:text-ew-text-50",
    "px-4",
    "py-5",
    "sm:px-6",
  ],
  {
    variants: {},
    defaultVariants: {},
  }
);

const cardFooterStyles = cva(
  [
    "px-4",
    "py-5",
    "sm:px-6",
    "border-t",
    "border-ew-border",
    "dark:border-ew-border-dark",
  ],
  {
    variants: {},
    defaultVariants: {},
  }
);

export {
  cardStyles,
  cardTitleStyles,
  cardBodyStyles,
  cardActionsStyles,
  cardFooterStyles,
};
