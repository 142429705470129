import React, { forwardRef, ElementType } from 'react';
import { IconButtonComponent, IconButtonProps } from './IconButton.types';
import { iconButtonStyles } from './IconButton.styles';
import { cx } from '../../../utils';
import { PolymorphicRef } from '../Common.types';

// @ts-ignore
const IconButton: IconButtonComponent = forwardRef(
  <C extends ElementType = 'button'>(
    {
      as,
      intent,
      size,
      icon: Icon,
      label,
      iconClasses,
      ...rest
    }: IconButtonProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Component = as || 'button';

    const classes = cx(iconButtonStyles({ intent, size }), rest.className, {});
    return (
      <Component {...rest} className={classes} ref={ref}>
        <span className={cx(['sr-only'])}>{label}</span>
        <Icon className={iconClasses} />
      </Component>
    );
  }
);

export { IconButton };
