const SETTINGS_QUERY_KEY = 'SETTINGS';
const SETTINGS_MUTATION_KEY = 'UPDATE_SETTINGS';

const BUDGETING_STYLE = [
  { value: 'GLOBAL_LTB', label: 'Global LTB' },
  { value: 'WALLED_OFF_MONTHS', label: 'Walled-Off Months' },
];

const MONTH_VIEW = [
  { value: 'SINGLE', label: 'Single' },
  { value: 'MULTIPLE', label: 'Multiple' },
];

const THOUSANDS = [
  { value: ',', label: '1,234' },
  { value: '.', label: '1.234' },
  { value: "'", label: `1'234` },
  { value: ' ', label: '1 234' },
];

const DECIMAL = [
  { value: '.', label: '456.78' },
  { value: ',', label: '456,78' },
  { value: '-', label: `456-78` },
];

const SYMBOL = [
  { value: '$', label: '$' },
  { value: '£', label: '£' },
  { value: '€', label: '€' },
  { value: 'Fr', label: 'Fr' },
  { value: 'SEK', label: 'SEK' },
  { value: 'kr', label: 'kr' },
  { value: '₽', label: '₽' },
  { value: '¥', label: '¥' },
  { value: '₩', label: '₩' },
  { value: 'R', label: 'R' },
  { value: '₱', label: '₱' },
  { value: '₹', label: '₹' },
];

const SHOW_SYMBOL = [
  { value: 'SHOW', label: 'Show' },
  { value: 'HIDE', label: 'Hide' },
];

const DATE_FORMAT = [
  { value: 'MM/DD/YY', label: 'MM/DD/YY' },
  { value: 'DD/MM/YY', label: 'DD/MM/YY' },
  { value: 'MM-DD-YY', label: 'MM-DD-YY' },
  { value: 'DD-MM-YY', label: 'DD-MM-YY' },
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  { value: 'MM-DD-YYYY', label: 'MM-DD-YYYY' },
  { value: 'DD-MM-YYYY', label: 'DD-MM-YYYY' },
  { value: 'YYYY/MM/DD', label: 'YYYY/MM/DD' },
  { value: 'YYYY/DD/MM', label: 'YYYY/DD/MM' },
  { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
  { value: 'YYYY-DD-MM', label: 'YYYY-DD-MM' },
];

const SHOW_DUE_DATE = [
  { value: 'SHOW', label: 'Show' },
  { value: 'HIDE', label: 'Hide' },
];

const WEEK_START = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
];

const NUMBER_OF_MONTHS = [{ value: 1, label: '1' }];

const GENERAL_SETTINGS = {
  BUDGETING_STYLE,
  MONTH_VIEW,
  THOUSANDS,
  DECIMAL,
  SYMBOL,
  SHOW_SYMBOL,
  DATE_FORMAT,
  SHOW_DUE_DATE,
  WEEK_START,
  NUMBER_OF_MONTHS,
};

const DEFAULT_SETTINGS = {
  BUDGETING_STYLE: 'GLOBAL_LTB',
  MONTH_VIEW: 'SINGLE',
  THOUSANDS: ',',
  DECIMAL: '.',
  SYMBOL: '$',
  SHOW_SYMBOL: 'SHOW',
  DATE_FORMAT: 'MM/DD/YY',
  SHOW_DUE_DATE: 'HIDE',
  WEEK_START: 0,
  NUMBER_OF_MONTHS: 1,
};

export {
  SETTINGS_QUERY_KEY,
  SETTINGS_MUTATION_KEY,
  GENERAL_SETTINGS,
  DEFAULT_SETTINGS,
};
