import * as React from 'react';
import * as T from '../Transactions.types';
import { useFocusWithin } from 'react-aria';
// import { cx } from '../../../utils';

const TableRow = React.forwardRef<T.TableRowElement, T.TableRowProps>(
  ({ row, rowFocus, setRowFocus, children }) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    // const isSelected = row.getIsSelected();

    const { focusWithinProps } = useFocusWithin({
      onFocusWithin: (event) => {
        // if (
        //   event.relatedTarget?.getAttribute('data-component') ===
        //   'toggle'
        // ) {
        //   event.stopPropagation();
        //   event.preventDefault();
        // }
      },
      onBlurWithin: (event) => {
        // if (
        //   event.relatedTarget?.getAttribute('data-component') ===
        //   'toggle'
        // ) {
        //   event.stopPropagation();
        //   event.preventDefault();
        // }
      },
      onFocusWithinChange: (isFocusWithin) => {
        setRowFocus({ [row.index]: isFocusWithin });
      },
    });

    // The Combobox we use for Category and Payee use Radix-ui's Popover. This Popover renders the list of
    // options outside the boundaries of the table and because of that when we select an option the table
    // row loses focus, making our component read-only before it updates the state of the selected option.
    // The behavior looks like this:
    //   - User focus (by keyboard or mouse) a row
    //   - The row becomes editable, then showing the Combobox and Inputs
    //   - The user clicks on a Combobox, then list of options display.
    //   - Radix renders this as a Popover but outside in the document.body element
    //   - The user clicks an option in the Combobox, the element triggers a blur event, then the row loses focus
    //   - The component loses focus before the selected element changes the state of the Combobox
    // To prevent this issue we are preventing propagation on the blur event to let user select an option then
    // we return the focus to the table row.
    // We check if the element we are clicking on is a radix element and if so we prevent the blur propagation.
    const onBlur = (event: React.FocusEvent) => {
      if (event.relatedTarget?.id?.includes('radix-')) {
        event.stopPropagation();
        event.preventDefault();
      } else {
        // @ts-ignore
        focusWithinProps?.onBlur?.(event);
      }
    };

    return (
      <tr tabIndex={0} {...focusWithinProps} onBlur={onBlur}>
        {children}
      </tr>
    );
  }
);

export { TableRow };
