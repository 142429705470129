import * as React from 'react';
import * as _ from 'lodash-es';
import Papa from 'papaparse';
import { FaFileCsv, FaInfo, FaExclamation } from 'react-icons/fa6';
import { useForm, SubmitHandler } from 'react-hook-form';
import { match, P } from 'ts-pattern';
import * as Budgets from '../../../Budget';
import { Box, Alert, Divider, Button } from '../../../Common';
import { cx } from '../../../../utils';
import * as BudgetTypes from '../../Budget.types';
import * as T from './ImportBudget.types';
import * as styles from './ImportBudget.styles';
import { ImportBudgetTable as Table } from './ImportBudget.table';

const ImportBudgetForm: T.BudgetImportFormElement = ({
  isLoading = false,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      file: null,
    },
  });
  const fileInputRef = React.useRef(null);
  const [csvSample, setCsvSample] = React.useState<Budgets.T.BudgetExport[]>(
    []
  );
  const [csvData, setCsvData] = React.useState<Budgets.T.BudgetExport[]>([]);
  // const [csvHeaders, setCsvHeaders] = React.useState<string[]>([]);
  const [isWrongFormat, setIsWrongFormat] = React.useState(false);
  const headers = ['categoryGroup', 'category', 'dueDate', 'budgeted'];

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target.files?.[0];

    if (!_.isUndefined(file)) {
      Papa.parse<Budgets.T.BudgetExport>(file, {
        skipEmptyLines: true,
        preview: 0,
        header: true,
        complete(results) {
          setCsvSample([...results.data.slice(0, 3)]);
          setCsvData(results.data);

          if (!_.isEqual(results.meta.fields, headers)) {
            setIsWrongFormat(true);
          } else {
            setIsWrongFormat(false);
          }
        },
      });
    }
  };

  const onSubmitHandler = () => {
    console.log('DATA', csvData);
    onSubmit?.(csvData);
  };

  console.log('errors', errors);

  return (
    <Box className={cx(['w-full', 'p-2'])}>
      <Box className={cx(['text-lg'])}>Import a Budget from a CSV file</Box>
      <Divider size="lg" />
      <form className={cx([])} onSubmit={handleSubmit(onSubmitHandler)}>
        <Box
          className={cx([
            'flex',
            'items-center',
            'space-x-3',
            'p-2',
            'bg-gray-100',
            'w-full',
            'rounded-md',
          ])}
        >
          <Box
            className={cx([
              'bg-gray-100',
              'rounded-3xl',
              'p-2',
              'border-2',
              'border-gray-800',
            ])}
          >
            <FaFileCsv className={cx(['text-2xl', 'text-gray-800'])} />
          </Box>
          <Box>
            <label className={cx(['block'])}>
              <span className={cx(['sr-only'])}>Select CSV file</span>
              <input
                {...register('file', {
                  required: true,
                })}
                onChange={handleFile}
                className={cx(styles.base, styles.primary, [
                  'block',
                  'w-full',
                  'text-sm',
                  'text-slate-800',
                  'font-semibold',
                ])}
                type="file"
                // ref={fileInputRef}
              />
            </label>
          </Box>
        </Box>
        <Box>
          {isWrongFormat ? (
            <>
              <Divider />
              <Alert
                icon={FaExclamation}
                intent="error"
                description={'Wrong format.'}
              />
            </>
          ) : (
            <>
              {match(csvSample)
                .with([], () => null)
                .with(P.not(P.nullish), (data) => {
                  console.log('DATA', data);
                  return (
                    <>
                      <Divider />
                      <Alert
                        icon={FaInfo}
                        intent="info"
                        description={
                          'This sample shows the first 3 rows of your file.'
                        }
                      />
                      <Divider />
                      <Box className={cx(['border', 'rounded-md'])}>
                        <Table data={data} />
                      </Box>
                    </>
                  );
                })
                .otherwise(() => null)}
            </>
          )}
        </Box>
        <Divider size="xl" />
        <Box className={cx(['flex', 'justify-end', 'space-x-3'])}>
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              onCancel?.();
            }}
          >
            Cancel
          </Button>
          <Button isLoading={isLoading} intent="primary">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export { ImportBudgetForm };
