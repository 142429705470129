import * as React from 'react';
import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { ErrorNotFound } from './components/Errors';
import * as PasswordReset from './components/PasswordReset';
import * as PasswordUpdate from './components/PasswordUpdate';
import * as SignIn from './components/SignIn';
import * as Dashboard from './components/Dashboard';
import * as Budget from './components/Budget';
import * as Account from './components/Account';
import * as Transactions from './components/Transactions';
import * as FileImports from './components/FileImports';
import { IsAuthorized } from './components';
import { getPeriodId } from './utils';

const router = (queryClient: QueryClient) =>
  createBrowserRouter([
    {
      id: 'root',
      path: '/',
      loader: () => redirect('/sign-in'),
      errorElement: <ErrorNotFound />,
    },
    {
      id: 'sign-in',
      path: '/sign-in',
      element: <SignIn.Page />,
    },
    {
      id: 'password-reset',
      path: 'password-reset',
      element: <PasswordReset.Page />,
    },
    {
      id: 'reset-password',
      path: 'reset/:uid/:token',
      element: <PasswordUpdate.Page />,
    },
    {
      id: 'app',
      path: '/b/:budgetId',
      element: (
        <IsAuthorized>
          <Dashboard.Layout />
        </IsAuthorized>
      ),
      children: [
        {
          index: true,
          loader: () => redirect(`${getPeriodId().current}`),
        },
        {
          path: ':periodId',
          element: <Budget.Page />,
        },
        {
          path: 'a/:accountId',
          element: <Account.Page />,
        },
        {
          path: 't/:transactionId',
          // loader: Transactions.detailLoader(queryClient),
          loader: ({ params }) =>
            Transactions.detailLoader(queryClient, params),
          element: <Transactions.Detail />,
          errorElement: <div>Error!</div>,
        },
        {
          path: 'import',
          loader: ({ params }) => FileImports.loader(queryClient, params),
          action: ({ params, request }) =>
            FileImports.action(queryClient, params, request),
          element: <FileImports.Page />,
          errorElement: <FileImports.ErrorPage />,
        },
      ],
    },
  ]);

export { router };
