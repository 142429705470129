import { cva } from 'class-variance-authority';

const allDotsBase = ['relative', 'rounded-full'];

const firstDot = ['delay-0'];

const secondDot = ['delay-150'];

const thirdDot = ['delay-300'];

const primary = [
  'bg-ew-primary',
  'color-ew-primary',

  'animate-[dot-flashing-primary_0.4s_infinite_alternate]',
];

const secondary = [
  'bg-gray-800',
  'color-gray-800',
  'animate-[dot-flashing-secondary.4s_infinite_alternate]',
];

const sm = ['w-[5px]', 'h-[5px]'];

const md = ['w-[10px]', 'h-[10px]'];

const lg = ['w-[15px]', 'h-[15px]'];

const firstDotStyles = cva([allDotsBase, firstDot], {
  variants: {
    inverted: {
      true: ['bg-white', 'color-white'],
    },
    intent: {
      primary,
      secondary,
    },
    size: {
      sm,
      md,
      lg,
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
});

const secondDotStyles = cva([allDotsBase, secondDot], {
  variants: {
    inverted: {
      true: ['bg-white', 'color-white'],
    },
    intent: {
      primary,
      secondary,
    },
    size: {
      sm,
      md,
      lg,
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
});

const thirdDotStyles = cva([allDotsBase, thirdDot], {
  variants: {
    inverted: {
      true: ['bg-white', 'color-white'],
    },
    intent: {
      primary,
      secondary,
    },
    size: {
      sm,
      md,
      lg,
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
});

export { firstDotStyles, secondDotStyles, thirdDotStyles };
