// @ts-ignore
import * as _ from 'lodash-es';
import invariant from 'tiny-invariant';
import moment from 'moment';

// Handle Inflow, Outflow, and Amount columns
// If Inflow and Outflow are empty, calculate the amount

let formatCSVData = (
  data: Array<Record<string, unknown>>,
  selectedMap: Record<string, string>
) => {
  return data?.map((entry) => {
    let newItem: Record<string, unknown> = {};

    Object.entries(entry).forEach(([key, value]) => {
      let newKey: string = selectedMap[key];

      if (!_.isUndefined(newKey)) {
        newItem[newKey.toLowerCase()] = value;
      }
    });

    return newItem;
  });
};

let formatDate = (date: string) => {
  return moment(date).format('YYYY-MM-DD');
};

let figureOutTypeFromAmount = (amount: number) => {
  return amount > 0 ? 'INFLOW' : 'OUTFLOW';
};

let figureOutType = (
  accountType: 'ASSET' | 'LIABILITY',
  transactionType: 'CREDIT' | 'DEBIT'
  // @ts-ignore
): 'INFLOW' | 'OUTFLOW' => {
  if (accountType.toUpperCase() === 'ASSET') {
    return transactionType.toUpperCase() === 'CREDIT' ? 'INFLOW' : 'OUTFLOW';
  }

  if (accountType.toUpperCase() === 'LIABILITY') {
    return transactionType.toUpperCase() === 'CREDIT' ? 'INFLOW' : 'OUTFLOW';
  }
};

type Amount = {
  amount?: number;
  debit?: number;
  credit?: number;
  type?: 'CREDIT' | 'DEBIT';
  accountType: 'ASSET' | 'LIABILITY';
};

type AmountSerialized = {
  amount: number;
  type: 'INFLOW' | 'OUTFLOW';
};

let serializeAmount = (amount: Amount): AmountSerialized => {
  let hasAmount = !_.isUndefined(amount.amount);
  let hasType = !_.isUndefined(amount.type);
  let hasDebit = !_.isUndefined(amount.debit);
  let hasCredit = !_.isUndefined(amount.credit);

  console.group('Serialize Amount');
  console.info('Amount', amount);
  console.info('hasAmount', hasAmount);
  console.info('hasType', hasType);
  console.info('hasDebit', hasDebit);
  console.info('hasCredit', hasCredit);
  console.groupEnd();

  // - Amount always positive with a Type column that's either Debit or Credit.
  if (hasAmount && hasType) {
    invariant(!_.isUndefined(amount.amount), 'Amount is required (A).');
    invariant(amount.amount >= 0, 'Amount must be positive (A).');
    invariant(amount.type, 'Type is required.');

    return {
      amount: amount.amount,
      type: figureOutType(amount.accountType, amount.type),
    };
  }

  // - Amount with either negative or positive with a single Amount column.
  if (hasAmount && !hasType && !hasDebit && !hasCredit) {
    invariant(!_.isUndefined(amount.amount), 'Amount is required (B).');

    return {
      amount: Math.abs(amount.amount),
      type: figureOutTypeFromAmount(amount.amount),
    };
  }

  // - No amount column, only Debit and Credit with the amount always positive.
  if (!hasAmount && !hasType && hasDebit && hasCredit) {
    if (amount.debit && amount.debit < 0) {
      throw new Error('Debit must be positive.');
    }

    if (amount.credit && amount.credit < 0) {
      throw new Error('Credit must be positive.');
    }

    let internalType = amount.debit ? 'CREDIT' : 'DEBIT';
    // @ts-ignore
    let type = figureOutType(amount.accountType, internalType);

    return {
      amount: (amount.debit || amount.credit) as number,
      type,
    };
  }

  console.warn('Amount', amount);
  throw new Error(`Does not match any of the conditions.`);
};

export type { Amount, AmountSerialized };
export { formatCSVData, formatDate, figureOutTypeFromAmount, serializeAmount };
