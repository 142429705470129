import * as React from 'react';
import * as _ from 'lodash-es';
import {
  Button as RAButton,
  ComboBox,
  Input,
  Label as ComboBoxLabel,
  ListBox,
  ListBoxItem,
  ListBoxItemProps,
  Popover,
  Section,
  Header,
  Collection,
  composeRenderProps,
  ComboBoxStateContext,
} from 'react-aria-components';
import { useFilter } from 'react-aria';
import { FaCircleXmark, FaCaretDown, FaCheck } from 'react-icons/fa6';
import { FieldError, Text } from 'react-aria-components';
import { Box } from '../Box';
import { cx } from '../../../utils';
import * as styles from './Combobox.styles2';
import * as T from './Combobox.types';
import * as Label from '../Label';
import { match, P } from 'ts-pattern';
import { ComboboxOption } from './Combobox.types';
import { CgSpinner } from 'react-icons/cg';

function Combobox<T extends object>({
  label,
  placeholder,
  description,
  errorMessage,
  children,
  hideLabel,
  showClearButton = false,
  showCreateButton = false,
  onCreate,
  onClear,
  isLoading = false,
  ...props
}: T.ComboboxProps<T>) {
  let classes = cx([props.className, styles.combobox]);
  let clearButtonRef = React.useRef<HTMLButtonElement | null>(null);

  let { contains } = useFilter({ sensitivity: 'base' });
  let [searchQuery, setSearchQuery] = React.useState('');

  // TODO:
  // - [ ] toggle has match if there's a selected item
  let hasMatch = React.useMemo(
    () =>
      // @ts-ignore
      props.items?.filter((item) => contains(item.label, searchQuery.trim()))
        .length > 0,
    [contains, props.items, searchQuery]
  );

  return (
    <ComboBox {...props} className={classes}>
      {hideLabel ? null : (
        <ComboBoxLabel className={cx([Label.styles.labelStyles])}>
          {label}
        </ComboBoxLabel>
      )}
      <Box
        className={cx([
          'relative',
          'flex',
          {
            'mt-2': !hideLabel,
          },
        ])}
      >
        <Input placeholder={placeholder} className={cx([styles.input])} />
        {match([isLoading, showClearButton])
          .with([false, true], () => (
            <ComboboxClearButton
              className={cx([styles.clearButton])}
              onPress={() => {
                onClear?.();
              }}
              ref={clearButtonRef}
            />
          ))
          .with([true, P._], () => (
            <CgSpinner className={cx([styles.isLoading, 'fa-spin'])} />
          ))
          .otherwise(() => null)}
        <RAButton className={cx([styles.button])}>
          <FaCaretDown aria-hidden className={cx(['w-4', 'h-4'])} />
        </RAButton>
      </Box>
      <ComboboxFeedback
        description={description}
        errorMessage={errorMessage}
        hasMatch={hasMatch}
      />
      <Popover className={cx(['w-[--trigger-width]'])}>
        <ListBox className={cx(styles.listBox)}>{children}</ListBox>
      </Popover>
    </ComboBox>
  );
}

// Logic for feedback.
function ComboboxFeedback({
  description,
  errorMessage,
  hasMatch,
}: {
  description?: string;
  errorMessage?: T.ComboboxError;
  hasMatch?: boolean;
}) {
  let state = React.useContext(ComboBoxStateContext);
  let isEmpty = _.isEmpty(state?.inputValue);
  let hasSelection = !_.isEmpty(state?.selectedItem);

  // Show "Press Enter" when:
  // - There's no match
  // - Input is not empty
  // - There's no selection

  return (
    match([description, errorMessage, hasMatch, isEmpty, hasSelection])
      // .with([P._, P._, false, false, false], () => {
      //   return (
      //     <Text slot="description" className={cx([styles.description])}>
      //       Press <span className={cx(['font-bold', 'text-sm'])}>ENTER</span> to
      //       create {state?.inputValue}
      //     </Text>
      //   );
      // })
      .with(
        [P.not(P.nullish), P.nullish, P._, P._, P._],
        ([description, ...rest]) => {
          return (
            <Text slot="description" className={cx([styles.description])}>
              {description}
            </Text>
          );
        }
      )
      .with(
        [P._, P.not(P.nullish), P._, P._, P._],
        ([description, errorMessage, ...rest]) => {
          return (
            <FieldError className={cx([styles.error])}>
              {errorMessage}
            </FieldError>
          );
        }
      )

      .otherwise(() => null)
  );
}

function ComboboxItem(props: ListBoxItemProps) {
  let textValue =
    props.textValue ||
    (_.isString(props.children) ? props.children : undefined);

  return (
    <ListBoxItem
      {...props}
      textValue={textValue}
      className={styles.listBoxItem}
    >
      {composeRenderProps(props.children, (children, { isSelected }) => (
        <>
          <span
            className={cx([
              'flex',
              'items-center',
              'flex-1',
              'gap-2',
              'font-normal',
              'truncate',
              'group-selected:font-semibold',
            ])}
          >
            {children}
          </span>
          <span className={cx(['flex', 'items-center', 'w-5'])}>
            {isSelected ? <FaCheck className={cx(['w-4', 'h-4'])} /> : ''}
          </span>
        </>
      ))}
    </ListBoxItem>
  );
}

function ComboboxSection<T extends object>(props: T.ComboboxSectionProps<T>) {
  return (
    <Section className={cx([styles.section])}>
      <Header className={cx([styles.sectionHeader])}>{props.title}</Header>
      <Collection items={props.items}>{props.children}</Collection>
    </Section>
  );
}

const ComboboxClearButton = React.forwardRef<
  T.ComboboxClearButtonElement,
  T.ComboboxClearButtonProps
>((props, forwardedRef) => {
  let state = React.useContext(ComboBoxStateContext);
  return (
    <RAButton
      {...props}
      // Don't inherit default Button behavior from ComboBox.
      slot={null}
      aria-label="Clear"
      onPress={(event) => {
        // Clear the input value.
        state?.setSelectedKey(null);
        props.onPress?.(event);
      }}
      ref={forwardedRef}
    >
      <FaCircleXmark />
    </RAButton>
  );
});

function ComboboxContent(props: { data: ComboboxOption }) {
  return match(props.data)
    .with({ value: P.array(P.not(P.nullish)) }, ({ value }) => {
      return (
        <ComboboxSection
          title={props.data.label}
          id={props.data.label}
          items={value}
          key={props.data.label}
        >
          {/*{(item: ComboboxOption) => (*/}
          {/*  <ComboboxItem id={item.label}>{item.label}</ComboboxItem>*/}
          {/*)}*/}
          <p>Test</p>
        </ComboboxSection>
      );
    })
    .with({ value: P.string }, () => (
      <ComboboxItem id={String(props.data.value)}>
        {props.data.label}
      </ComboboxItem>
    ))
    .otherwise(() => null);
}

export {
  Combobox,
  ComboboxItem,
  ComboboxSection,
  ComboboxContent,
  ComboboxClearButton,
};
