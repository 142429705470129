import * as T from './CategoryGroups.types';
import { FaMoneyBillTrendUp } from 'react-icons/fa6';

const categoryGroupsMocks: T.CategoryGroupGet[] = [
  {
    id: '2wBnzBjg',
    name: 'House',
    accounts: [
      {
        id: 'LRnKb7qa',
        name: 'Cleaning',
        type: 'INTERNAL',
        internal_type: 'LIABILITY',
        _as: 'CATEGORY',
        notes: null,
        user: 'juan@jolvera.dev',
        budget: {
          hashid: 'gq9jv9MA',
          name: 'My First Budget',
          user: 'juan@jolvera.dev',
          accounts: [
            'E4nb2nZz',
            'EL7p5nBd',
            'QbMVpn21',
            'ePwO17o9',
            'LRnKb7qa',
          ],
        },
        budget_id: 'gq9jv9MA',
        opening_balance: '0.00',
        due_date: 5,
      },
      {
        id: 'LRnKb7qa',
        name: 'Drinks',
        type: 'INTERNAL',
        internal_type: 'LIABILITY',
        _as: 'CATEGORY',
        notes: null,
        user: 'juan@jolvera.dev',
        budget: {
          hashid: 'gq9jv9MA',
          name: 'My First Budget',
          user: 'juan@jolvera.dev',
          accounts: [
            'E4nb2nZz',
            'EL7p5nBd',
            'QbMVpn21',
            'ePwO17o9',
            'LRnKb7qa',
          ],
        },
        budget_id: 'gq9jv9MA',
        opening_balance: '0.00',
        due_date: 12,
      },
      {
        id: 'LRnKb7qa',
        name: 'Groceries',
        type: 'INTERNAL',
        internal_type: 'LIABILITY',
        _as: 'CATEGORY',
        notes: null,
        user: 'juan@jolvera.dev',
        budget: {
          hashid: 'gq9jv9MA',
          name: 'My First Budget',
          user: 'juan@jolvera.dev',
          accounts: [
            'E4nb2nZz',
            'EL7p5nBd',
            'QbMVpn21',
            'ePwO17o9',
            'LRnKb7qa',
          ],
        },
        budget_id: 'gq9jv9MA',
        opening_balance: '0.00',
        due_date: 1,
      },
    ],
    budget: 3,
  },
  {
    id: '2wBnzBjg',
    name: 'Biz',
    accounts: [
      {
        id: 'LRnKbw3r',
        name: 'Internet',
        type: 'INTERNAL',
        internal_type: 'LIABILITY',
        _as: 'CATEGORY',
        notes: null,
        user: 'juan@jolvera.dev',
        budget: {
          hashid: 'gq9jv9MA',
          name: 'My First Budget',
          user: 'juan@jolvera.dev',
          accounts: [
            'E4nb2nZz',
            'EL7p5nBd',
            'QbMVpn21',
            'ePwO17o9',
            'LRnKb7qa',
          ],
        },
        budget_id: 'gq9jv9MA',
        opening_balance: '0.00',
        due_date: 15,
      },
      {
        id: 'LRnKblsk',
        name: 'Supplies',
        type: 'INTERNAL',
        internal_type: 'LIABILITY',
        _as: 'CATEGORY',
        notes: null,
        user: 'juan@jolvera.dev',
        budget: {
          hashid: 'gq9jv9MA',
          name: 'My First Budget',
          user: 'juan@jolvera.dev',
          accounts: [
            'E4nb2nZz',
            'EL7p5nBd',
            'QbMVpn21',
            'ePwO17o9',
            'LRnKb7qa',
          ],
        },
        budget_id: 'gq9jv9MA',
        opening_balance: '0.00',
        due_date: 20,
      },
      {
        id: 'LRnKbxcs',
        name: 'Electricity',
        type: 'INTERNAL',
        internal_type: 'LIABILITY',
        _as: 'CATEGORY',
        notes: null,
        user: 'juan@jolvera.dev',
        budget: {
          hashid: 'gq9jv9MA',
          name: 'My First Budget',
          user: 'juan@jolvera.dev',
          accounts: [
            'E4nb2nZz',
            'EL7p5nBd',
            'QbMVpn21',
            'ePwO17o9',
            'LRnKb7qa',
          ],
        },
        budget_id: 'gq9jv9MA',
        opening_balance: '0.00',
        due_date: 1,
      },
    ],
    budget: 3,
  },
];

const categoryGroupsWithAmounts: T.CategoryGroupBase[] =
  categoryGroupsMocks.map((group) => {
    return {
      ...group,
      amount: 23,
    };
  });

// For Combobox
const formattedCategoryGroups = [
  {
    label: 'Income',
    // value: 'E4nb2nZz',
    value: [
      {
        label: 'Paycheck (Cha-ching!)',
        value: 'E4nb2nZz',
        icon: FaMoneyBillTrendUp,
      },
    ],
  },
  {
    label: 'Loans',
    value: [
      {
        label: 'Jefferson Cap',
        value: '9WMdlAMa',
      },
      {
        label: 'One Main',
        value: '4ewL85w6',
      },
      {
        label: 'Wells Fargo (STI)',
        value: 'XOwoYg7j',
      },
      {
        label: 'Pixel 8',
        value: 'DXMWAQno',
      },
      {
        label: 'Conns Appliances',
        value: 'mowx5BMx',
      },
      {
        label: 'Wells Fargo (Tahoe)',
        value: 'G5MjQrwa',
      },
      {
        label: 'Affirm (eero)',
        value: 'rjn9gpMo',
      },
    ],
  },
  {
    label: 'Streaming',
    value: [
      {
        label: 'Disney+',
        value: '5Pw5B3M3',
      },
      {
        label: 'Spotify',
        value: 'Pgw8q1Mo',
      },
      {
        label: 'Hulu',
        value: 'aL7YLEMD',
      },
      {
        label: 'Netflix',
        value: 'xowB4g7j',
      },
      {
        label: 'Crunchyroll',
        value: 'vy7kLBnE',
      },
      {
        label: "Pablo's Spotify",
        value: 'rYwPA0My',
      },
      {
        label: 'Peacock (Apple)',
        value: 'jL706awZ',
      },
      {
        label: 'Max (Apple)',
        value: 'Or7GErwd',
      },
      {
        label: 'Apple TV (Apple)',
        value: 'mdMm3gn4',
      },
      {
        label: 'TO DELETE',
        value: 'bjwaj9MO',
      },
    ],
  },
  {
    label: 'Bills',
    value: [
      {
        label: 'Woodlands Water',
        value: 'E4nb42MZ',
      },
      {
        label: 'Rent',
        value: 'EL7p55nB',
      },
      {
        label: 'T-Mobile',
        value: 'QbMVrpM2',
      },
      {
        label: 'Landscaping',
        value: 'LRnK9bMq',
      },
      {
        label: 'Groceries',
        value: 'ePwOk1wo',
      },
      {
        label: 'Gasoline',
        value: 'zEnyONMD',
      },
      {
        label: 'Tachus',
        value: 'WpMN9pwN',
      },
      {
        label: "Dogs' food",
        value: '4d7rLmwE',
      },
      {
        label: 'Gas',
        value: 'Prwg5a7J',
      },
      {
        label: 'Entergy Texas',
        value: '2rnQaYne',
      },
    ],
  },
  {
    label: 'Insurance',
    value: [
      {
        label: 'Renters',
        value: 'QbMVrlM2',
      },
      {
        label: 'Pets Best Insurance',
        value: 'YKM2G57y',
      },
      {
        label: 'Farmers (Auto)',
        value: 'rYwPA4My',
      },
      {
        label: 'Protective life',
        value: 'EL7p5enB',
      },
    ],
  },
  {
    label: 'Misc',
    value: [
      {
        label: 'Fun',
        value: 'LRnK9vMq',
      },
      {
        label: "Kid's allowance",
        value: 'ePwOkKwo',
      },
      {
        label: "Yuri's allowance",
        value: 'zEnyOzMD',
      },
      {
        label: 'Vacations',
        value: 'LenXrPMQ',
      },
    ],
  },
  {
    label: 'Health & Fitness',
    value: [
      {
        label: 'Fitness Project',
        value: 'WpMN9rwN',
      },
    ],
  },
  {
    label: 'Debt',
    value: [
      {
        label: 'IRS',
        value: '4d7rLkwE',
      },
      {
        label: 'Credit Cards',
        value: 'y07ZzG7p',
      },
    ],
  },
  {
    label: 'Subscriptions',
    value: [
      {
        label: "Mariana's app (Apple)",
        value: 'Prwg537J',
      },
      {
        label: 'Ipsy',
        value: '2rnQa9ne',
      },
      {
        label: 'iCloud+ (Yuri)',
        value: 'ZvnJZVnA',
      },
      {
        label: 'Quickbooks (Apple)',
        value: 'XJMlkywO',
      },
      {
        label: 'iCloud+ (Juan)',
        value: 'ZJw6rDw2',
      },
    ],
  },
  {
    label: 'Biz',
    value: [
      {
        label: 'X',
        value: 'YKM2Gk7y',
      },
      {
        label: 'Biz expenses',
        value: '2Q7zrb7g',
      },
    ],
  },
  {
    label: 'Credit Cards & Loans',
    value: [
      {
        label: 'AMEX 8888',
        value: 'xowBOJMj',
      },
    ],
  },
  {
    label: 'Accounts (Transfer)',
    value: [
      {
        label: 'Checking',
        value: '4d7rLZwE',
      },
      {
        label: 'Savings',
        value: 'y07ZzQ7p',
      },
    ],
  },
];

export {
  categoryGroupsMocks,
  categoryGroupsWithAmounts,
  formattedCategoryGroups,
};
