import * as _ from 'lodash-es';
import { match, P } from 'ts-pattern';
import moment from 'moment';
import { useIsFetching } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import { Box, Input, Divider, Button, Toggle } from '../Common';
import * as Combobox from '../Common/Combobox';
import { cx } from '../../utils';
import { PAYEES_QUERY_KEY } from '../Payees/Payees.constants';
import { TYPES } from './Transactions.constants';
import {
  FormInputsTransaction,
  TransactionFormComponent,
} from './Transactions.types';
import { Key } from 'react-aria-components';
import { useFilter } from 'react-aria';

const TransactionForm: TransactionFormComponent = ({
  initialValue,
  categoryGroups,
  payees,
  onPayeeCreate,
  formId,
  noButtons,
  onSubmit,
  isLoading = false,
  settings,
  createMore = false,
}) => {
  const [isPressed, setPressed] = useState(false);
  const [type, setType] = useState<typeof TYPES.INFLOW | typeof TYPES.OUTFLOW>(
    TYPES.OUTFLOW
  );

  const { budgetId } = useParams();
  const PAYEES_KEY = [PAYEES_QUERY_KEY, budgetId];

  const isFetching = useIsFetching({
    queryKey: PAYEES_KEY,
  });

  const today = new Date(); // local

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setFocus,
  } = useForm<FormInputsTransaction>({
    defaultValues: {
      ...initialValue,
      date: moment(today).format('YYYY-MM-DD').toString(),
      // @ts-ignore
      type,
    },
  });

  const focusTypeToggle = () =>
    document.getElementById('form-type-toggle')?.focus();

  useEffect(() => {
    focusTypeToggle();
  }, [setFocus]);

  const onSubmit_: SubmitHandler<FormInputsTransaction> = (data, options) => {
    const newDate = new Date();

    onSubmit(data, {
      onSuccess() {
        reset();

        if (formId) {
          const form = document.getElementById(formId) as HTMLFormElement;
          form.reset();
        }

        // If the user wants to keep adding transactions, we use the last used category and payee.
        if (createMore) {
          setValue('category', data.category);
          setValue('payee', data.payee);
        }

        focusTypeToggle();

        setValue('date', moment(newDate).format('YYYY-MM-DD').toString());
        setValue('amount', '');
      },
    });
  };

  const typeIconClasses = cx([
    'w-8',
    'h-8',
    'text-gray-500',
    'dark:text-ew-text-dark',
  ]);
  const typeInflowClasses = cx([
    'text-ew-primary',
    'dark:text-ew-primary-dark',
  ]);

  const changeType = (pressed: boolean) => {
    setPressed(pressed);
    const newType = pressed ? TYPES.INFLOW : TYPES.OUTFLOW;
    setType(newType);
    setValue('type', newType);
  };

  // We could have memoized this at the Account.Page level, but I prefer to have the structure as the API returned it
  // through Account.Page > Transactions.Page > Transactions.Table in case we need to read it.
  // We format from CategoryGroupsRead[] to ComboboxOption[]
  const categoryGroupsFormatted = useMemo(
    () =>
      categoryGroups.map(({ name, id, accounts }) => {
        if (Array.isArray(accounts) && !_.isEmpty(accounts)) {
          // format nested options
          const nestedOptions = accounts.map((account) => ({
            label: account.name,
            value: account.id,
          }));
          return {
            label: name,
            value: nestedOptions,
          };
        }
        return {
          label: name,
          value: id,
        };
      }),
    [categoryGroups, budgetId]
  );

  /**
   * Controlled Combobox state
   */

  let [fieldState, setFieldState] = React.useState<{
    selectedKey: string | Key | null;
    inputValue: string;
  }>({
    selectedKey: null,
    inputValue: '',
  });

  let onSelectionChange = (id: Key | null) => {
    let selected = payees.find((item) => item.value === id)?.label;
    setFieldState({
      inputValue: String(selected),
      selectedKey: String(id),
    });
  };

  let onInputChange = (value: string) => {
    setFieldState((prevState) => ({
      inputValue: value,
      selectedKey: value === '' ? null : prevState.selectedKey,
    }));
  };

  /**
   * Custom filtering
   */

  let { contains } = useFilter({ sensitivity: 'base' });
  let filteredPayees = React.useMemo(
    () => payees.filter((item) => contains(item.label, fieldState.inputValue)),
    [payees, fieldState.inputValue]
  );

  let onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    let hasMatch = !_.isEmpty(filteredPayees);
    let hasValue = !_.isEmpty(value);

    if (!hasMatch && hasValue) {
      onPayeeCreate(value, undefined, (payee) => {
        setValue('payee', payee?.id);
      });
      return;
    }

    setFieldState((prevState) => ({
      inputValue: prevState.inputValue,
      selectedKey: prevState.selectedKey,
    }));
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit_)} id={formId}>
      <Box className={cx(['text-gray-600'])}>
        <span
          className={cx([
            'block',
            'text-sm',
            'font-medium',
            'leading-6',
            'dark:text-white',
          ])}
        >
          <span className="label-text">Type</span>
        </span>

        <Box className={cx(['mt-2', 'flex', 'justify-start', 'items-center'])}>
          <Toggle
            size="sm"
            className={cx([
              'p-0',
              'focus:ring-2',
              'focus:ring-ew-primary',
              'focus-visible:ring-2',
              'focus-visible:ring-ew-primary',
              'dark:focus-visible:ring-ew-primary-dark',
              'focus-visible:ring-offset-2',
              'dark:focus-visible:ring-offset-ew-bg-dark',
            ])}
            aria-label="Toggle approved"
            data-component="toggle"
            // onChange={changeType}
            defaultPressed={isPressed}
            onPressedChange={changeType}
            id="form-type-toggle"
            autoFocus
          >
            {isPressed ? (
              <FaToggleOn
                className={cx([typeIconClasses, typeInflowClasses])}
              />
            ) : (
              <FaToggleOff className={typeIconClasses} />
            )}{' '}
            <span className={cx(['px-3', 'text-sm', 'dark:text-ew-text-dark'])}>
              {type}
            </span>
          </Toggle>
        </Box>
      </Box>
      <Divider />
      <Box className={cx(['flex', 'justify-between'])}>
        <Box className={cx(['flex-auto'])}>
          <Input
            fullWidth
            type="date"
            label="Date"
            hasErrors={'date' in errors}
            helpText={errors.date?.message}
            {...register('date', { required: true })}
            autoFocus
          />
        </Box>
        <Divider orientation="vertical" />
        <Box className={cx(['flex-auto'])}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Input
                  autoFocus
                  fullWidth
                  forMoney
                  label="Amount"
                  hasErrors={!!fieldState.error}
                  helpText={fieldState.error?.message}
                  {...field}
                  symbol={settings?.symbol}
                  numberOptions={{
                    thousandSeparator: settings?.thousands,
                    decimalSeparator: settings?.decimal,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                />
              );
            }}
            name="amount"
          />
        </Box>
      </Box>
      <Divider />
      <Box className={cx(['flex', 'w-full'])}>
        <Controller
          name="payee"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => {
            return (
              <Combobox.$
                allowsCustomValue
                className={cx(['w-full'])}
                isRequired
                label="Payee"
                placeholder={'Select Payee'}
                isInvalid={invalid}
                errorMessage={error?.message}
                // showClearButton={!_.isEmpty(field.value)}
                validationBehavior="aria"
                isLoading={!!isFetching}
                {...field}
                onBlur={onBlur}
                // Controlled state
                defaultItems={filteredPayees}
                selectedKey={fieldState.selectedKey}
                inputValue={fieldState.inputValue}
                onSelectionChange={(key) => {
                  onSelectionChange(key);
                  field.onChange(key);
                }}
                onInputChange={onInputChange}
              >
                {(data: Combobox.T.ComboboxOption) => (
                  <Combobox.Item id={String(data.value)}>
                    {data.label}
                  </Combobox.Item>
                )}
              </Combobox.$>
            );
          }}
        />
      </Box>
      <Divider />
      <Box className={cx(['flex', 'w-full'])}>
        <Controller
          name="category"
          control={control}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => {
            return (
              <Combobox.$
                className={cx(['w-full'])}
                defaultItems={categoryGroupsFormatted}
                isRequired
                label="Category"
                placeholder={'Select Category'}
                isInvalid={invalid}
                errorMessage={error?.message}
                showClearButton={!_.isEmpty(field.value)}
                validationBehavior="aria"
                {...field}
                onSelectionChange={field.onChange}
              >
                {(data: Combobox.T.ComboboxOption) => {
                  return match(data)
                    .with({ value: P.array(P.not(P.nullish)) }, ({ value }) => {
                      return (
                        <Combobox.Section
                          title={data.label}
                          id={String(value)}
                          items={Array.isArray(value) ? value : []}
                        >
                          {(item: Combobox.T.ComboboxOption) => (
                            <Combobox.Item id={String(item.value)}>
                              {item.label}
                            </Combobox.Item>
                          )}
                        </Combobox.Section>
                      );
                    })
                    .with({ value: P.string }, () => (
                      <Combobox.Item id={String(data.value)}>
                        {data.label}
                      </Combobox.Item>
                    ))
                    .otherwise(() => <div />);
                }}
              </Combobox.$>
            );
          }}
        />
      </Box>
      <Divider />
      <Box className={cx(['flex-auto'])}>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                fullWidth
                label="Notes"
                // hasErrors={!!fieldState.error}
                // helpText={fieldState.error?.message}
                {...field}
              />
            );
          }}
          name="memo"
        />
      </Box>
      {!noButtons && (
        <>
          <Divider />
          <Box>
            <Button isLoading={isLoading} type="submit">
              Submit
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export { TransactionForm };
