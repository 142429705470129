// import moment from 'moment';
import { useHotkeys, HotkeysProvider } from 'react-hotkeys-hook';
import React, { useState, useMemo } from 'react';
import _ from 'lodash-es';
import { useIsMutating } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { FaFolderOpen, FaPlus } from 'react-icons/fa';
import { match } from 'ts-pattern';
import { FaBalanceScale, FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';
import {
  cx,
  // getCurrentMonthName
} from '../../utils';
import { formatTransaction } from './Transactions.utils';
import {
  Empty,
  SlideOver,
  Box,
  Divider,
  Layout,
  OnSubmit,
  Button,
} from '../Common';
import * as Accounts from '../Account';
import * as T from './Transactions.types';
import * as S from './Transactions.store';
import * as C from './Transactions.constants';

import { TRANSACTIONS_CREATE_KEY } from './Transactions.constants';
import { TransactionForm } from './Transactions.form';
import { TransactionsTable } from './Transactions.table';
import {
  FormInputsTransaction,
  TransactionsPageComponent,
} from './Transactions.types';

import { TransferForm } from '../Transfers';
import { TransferFormInputs } from '../Transfers/Transfers.types';
import { OverviewBox } from './components';

import { getAccountType } from '../../utilities/accountTypes';
// import * as Accounts from '../Account';
import invariant from 'tiny-invariant';

const TransactionsPage: TransactionsPageComponent = ({
  account,
  accounts,
  internalAccounts,
  transactions,
  payees,
  categoryGroups,
  onTransactionChange,
  onTransactionDelete,
  onEntryChange,
  onPayeeCreate,
  onTransactionCreate,
  onTransferCreate,
  accountTotals,
  accountId,
  budgetId,
  settings,
  sidebarOpen,
  updateAccountFormOpen = false,
  setUpdateAccountFormOpen = () => {},
  accountInfo = { accountName: '', accountNotes: '' },
  ...rest
}) => {
  // const todayDate = new Date();
  // const currentMonth = getCurrentMonthName();
  // const nextMonth = moment(todayDate).add(1, 'month').format('MMMM');
  const transactionIsMutating = useIsMutating({
    mutationKey: [TRANSACTIONS_CREATE_KEY, accountId, budgetId],
  });

  const [createMore, setCreateMore] = useState(false);
  // const [transactionFormOpen, setTransactionFormOpen] = useState(false);
  const { transactionFormOpen, setTransactionFormOpen } = S.transactionsStore(
    (state) => state
  );
  const [transferFormOpen, setTransferFormOpen] = useState(false);

  useHotkeys('t', () => setTransactionFormOpen(true), {
    scopes: ['transactions'],
  });

  useHotkeys('shift+t', () => setTransferFormOpen(true), {
    scopes: ['transfers'],
  });

  // Format payees for the Combobox component
  const formattedPayees = payees.map((payee) => {
    invariant(!_.isNil(payee.id), 'payee.id must be have a value');
    return {
      label: payee.name,
      value: payee.id,
    };
  });

  const formattedTransactions = useMemo(() => {
    let results = transactions?.results?.map(
      (transaction: T.TransactionRead | T.TransactionOptimistic) =>
        formatTransaction(transaction)
    );

    // If account is CC then filter out BUDGET transactions
    // NOTE: We will fix this in BYT-428
    if (account.type === Accounts.C.CREDIT_CARD) {
      results = results.filter(
        (t) => t.internal_type !== C.INTERNAL_TYPES.BUDGET
      );
    }

    return results;
  }, [transactions.results]);

  const incomeCategory = internalAccounts?.find(
    (category) => category._as === 'LEFT_TO_BUDGET'
  );
  invariant(incomeCategory, 'Left to Budget account is missing.');

  // Add income category to the category groups.
  const incomeCategoryGroup = {
    id: incomeCategory.id,
    name: 'Income',
  };

  const formattedCategories = categoryGroups
    .slice()
    .filter((category) => category?.accounts?.length >= 1);

  if (account.internal_type === Accounts.C.INTERNAL_TYPES.ASSET) {
    formattedCategories
      // @ts-ignore
      .unshift(incomeCategoryGroup);
  }

  const formattedAccounts = accounts
    // Remove current account because we can't transfer to itself.
    .filter((account_) => account_.name !== account.name)
    .map((account) => ({
      label: account.name,
      value: account.id,
    }));

  const accountType = getAccountType(account.type);

  const handleTransferCreate: OnSubmit<TransferFormInputs> = (
    transfer,
    options
  ) => {
    onTransferCreate(transfer, {
      onSuccess(data, variables, context) {
        toast.success('Your transfer has been created!');

        if (!createMore) {
          setTransferFormOpen(false);
        }

        options?.onSuccess?.(data, variables, context);
      },
      onError(data, variables, context) {
        toast.error(
          'An error has occurred. We could not create your transaction. Not your fault! The error has been reported.'
        );

        options?.onError?.(data, variables, context);
      },
    });
  };

  const handleTransactionCreate: OnSubmit<FormInputsTransaction> = (
    transaction,
    options
  ) => {
    onTransactionCreate(transaction, {
      onSuccess(data, variables, context) {
        if (!createMore) {
          setTransactionFormOpen(false);
        }
        toast.success('Your transaction has been created!');

        options?.onSuccess?.(data, variables, context);
      },
      onError(data, variables, context) {
        toast.error(
          'An error has occurred. We could not create your transaction. Not your fault! The error has been reported.'
        );

        options?.onError?.(data, variables, context);
      },
    });
  };

  // let isLiabilityAccount =
  //   account.internal_type === Accounts.C.INTERNAL_TYPES.LIABILITY;

  return (
    <Box {...rest} className={cx(['flex', 'flex-grow'], rest.className)}>
      <HotkeysProvider initiallyActiveScopes={['transactions']}>
        <SlideOver
          formId="new-transaction"
          title="New transaction"
          open={transactionFormOpen}
          setOpen={setTransactionFormOpen}
          isLoading={!!transactionIsMutating}
          toggleLabel="Create more"
          toggleState={createMore}
          updateToggle={(toggled) => setCreateMore(toggled)}
        >
          <TransactionForm
            formId="new-transaction"
            categoryGroups={formattedCategories}
            payees={formattedPayees}
            onPayeeCreate={onPayeeCreate}
            noButtons
            onSubmit={handleTransactionCreate}
            isLoading={!!transactionIsMutating}
            settings={settings}
            createMore={createMore}
          />
        </SlideOver>

        <SlideOver
          formId="new-transfer"
          title={'New transfer'}
          open={transferFormOpen}
          setOpen={setTransferFormOpen}
          toggleLabel="Create more"
          toggleState={createMore}
          updateToggle={(toggled) => setCreateMore(toggled)}
        >
          <TransferForm
            formId="new-transfer"
            onSubmit={handleTransferCreate}
            accounts={formattedAccounts}
            noButtons
            settings={settings}
          />
        </SlideOver>
        <Layout
          // title={account?.name}
          title={'Account'}
          // subtitle={accountType}
          actionsEnd={
            <>
              <Button
                onClick={() => setTransferFormOpen(true)}
                icon={FaPlus}
                aria-label="Create new transfer"
              >
                Transfer
              </Button>
              <Button
                intent="primary"
                onClick={() => setTransactionFormOpen(true)}
                icon={FaPlus}
                aria-label="Create new transaction"
              >
                Transaction
              </Button>
            </>
          }
        >
          <Box className={cx(['p-4'])}>
            <Box>
              <Box className={cx(['flex', 'w-full'])}>
                <Box className={cx(['w-2/5'])}>
                  <h2
                    className={cx([
                      'text-xl',
                      'text-gray-700',
                      'font-semibold',
                    ])}
                  >
                    {account.name}
                  </h2>
                  <Divider size="sm" />
                  <p className={cx(['text-gray-600'])}>{accountType}</p>
                </Box>
                <Box className={cx(['flex', 'w-3/5', 'grid', 'grid-cols-3'])}>
                  <OverviewBox
                    icon={FaRegCheckCircle}
                    label="Approved"
                    isLoading={accountTotals.isLoading}
                    amount={accountTotals?.data?.approved}
                    // amount={'0.00'}
                    type="approved"
                    settings={settings}
                  />

                  <OverviewBox
                    icon={FaRegCircle}
                    label="Unapproved"
                    isLoading={accountTotals.isLoading}
                    amount={accountTotals?.data?.unapproved}
                    // amount={'0.00'}
                    type="unapproved"
                    settings={settings}
                  />

                  <OverviewBox
                    icon={FaBalanceScale}
                    label="Balance"
                    isLoading={accountTotals.isLoading}
                    amount={accountTotals?.data?.balance}
                    type="balance"
                    settings={settings}
                    borderless
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box>
              {match(formattedTransactions)
                .with([], () => (
                  <Empty
                    heading="Manage your transactions"
                    content="Create, update, and delete your transactions."
                    icon={FaFolderOpen}
                    actionLabel="New Transaction"
                    action={() => setTransactionFormOpen(true)}
                  />
                ))
                .otherwise(() => (
                  <TransactionsTable
                    accounts={accounts}
                    // TODO:FIX
                    // @ts-ignore
                    transactions={formattedTransactions}
                    transactionsCount={transactions.count}
                    payees={payees}
                    categoryGroups={formattedCategories}
                    accountId={accountId}
                    budgetId={budgetId}
                    onTransactionChange={onTransactionChange}
                    onTransactionDelete={onTransactionDelete}
                    onEntryChange={onEntryChange}
                    onPayeeCreate={onPayeeCreate}
                    settings={settings}
                  />
                ))}
            </Box>
          </Box>
        </Layout>
      </HotkeysProvider>
    </Box>
  );
};

export { TransactionsPage };
