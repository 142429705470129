import create from "zustand";
import { devtools } from "zustand/middleware";

interface AlertState {
    foo: string;
    setFoo: (foo: string) => void;
}

const useAlertStore = create<AlertState>()(
    devtools(
        (set) => ({
            foo: '',
            setFoo: (foo) => set((state) => ({ foo })),
        }),
    )
);

export { useAlertStore };
