import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cx } from '../../../utils';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cx(
      [
        'bg-ew-bg-dark-800',
        'z-50',
        'overflow-hidden',
        'rounded-md',
        // 'border',
        // 'border-ew-bg-dark-900',
        'px-3',
        'py-1.5',
        'text-sm',
        'shadow-md',
        'animate-in',
        'fade-in-0',
        'zoom-in-95',
        'data-[state=closed]:animate-out',
        'data-[state=closed]:fade-out-0',
        'data-[state=closed]:zoom-out-95',
        'data-[side=bottom]:slide-in-from-top-2',
        'data-[side=left]:slide-in-from-right-2',
        'data-[side=right]:slide-in-from-left-2',
        'data-[side=top]:slide-in-from-bottom-2',
      ],
      [
        "after:content-['']",
        'after:absolute',
        'after:left-1/2',
        'after:top-[100%]',
        'after:-translate-x-1/2',
        'after:border-8',
        'after:border-x-transparent',
        'after:border-b-transparent',
        'after:border-t-ew-bg-dark-800',
      ],
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

type TooltipComponent = (
  props: PropsWithChildren<{
    content: ReactNode | string;
  }>
) => ReactElement | null;

const Tooltip: TooltipComponent = ({ children, content }) => (
  <TooltipProvider delayDuration={400}>
    <TooltipRoot>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent sideOffset={15}>{content}</TooltipContent>
    </TooltipRoot>
  </TooltipProvider>
);

export {
  Tooltip,
  // TooltipTrigger, TooltipContent, TooltipProvider
};
