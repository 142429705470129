import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { Box, DayOfTheMonth } from '../../Common';
import { cx } from '../../../utils';
import * as Category from '../../Categories';

const categoryDueDate: Partial<ColumnDef<Category.T.Category>> = {
  cell: ({
    getValue,
    row: { index, original, getIsSelected, getCanExpand },
    column: { id: columnId },
    table,
  }) => {
    const initialValue = getValue();

    // console.log('initial value', initialValue);
    // const settings = table.options.meta?.settings;

    /* eslint-disable react-hooks/rules-of-hooks */
    const [value, setValue] = useState(initialValue);

    if (getCanExpand()) {
      return null;
    }

    const update = (day: number) => {
      table.options.meta?.onCategoryUpdate(index, columnId, {
        id: original.id,
        due_date: day,
      });
    };

    return (
      <Box className={cx(['flex', 'items-center', 'justify-start'])}>
        <DayOfTheMonth
          value={Number(value) ?? 0}
          onChange={(change) => {
            console.log('change', change.day);
            setValue(change.day);
            update(change.day);
          }}
        />
      </Box>
    );
  },
};

export { categoryDueDate };
