const base = [
  'file:mr-2',
  'file:border-0',
  'file:relative',
  'rounded-md',
  'file:rounded-md',
  'file:px-2.5',
  'file:py-1.5',
  'file:font-semibold',
  'file:shadow-sm',
  'file:focus:ring-0',
  'file:focus-visible:ring-0',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-ew-primary',
  'focus:border-0',
  'focus-visible:border-0',
  'focus-visible:outline-none',
  'focus-visible:ring-2',
  'focus-visible:ring-offset-2',
  'focus-visible:ring-ew-primary',
  // 'ring-1',
  // 'ring-inset',
  // 'ring-ew-border',
];

/** Intent */
const primary = [
  'file:text-white',
  'file:bg-ew-primary',
  'file:hover:bg-ew-primary-600',
  'file:dark:bg-ew-primary-dark',
  'file:dark:hover:bg-ew-primary-dark-600',
];

export { base, primary };
