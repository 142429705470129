import * as React from 'react';
import * as accounting from 'accounting';
import { match, P } from 'ts-pattern';
import * as Settings from '../Settings';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { cx } from '../../utils';
import { ThreeDots } from './Loading/ThreeDots';
import { ComponentPropsWithoutRef } from 'react';

type MoneyProps = ComponentPropsWithoutRef<'span'> & {
  value: number | string;
  withColor?: boolean;
  withSign?: boolean;
  bold?: boolean;
};

type MoneyElement = React.ElementRef<'span'>;

const Money = React.forwardRef<MoneyElement, MoneyProps>(
  (
    { value, withColor = false, withSign = false, bold = false, ...props },
    forwardedRef
  ) => {
    const { budgetId } = useParams();
    invariant(budgetId, 'budgetId is required');
    const settings = Settings.Hooks.useGetSettings(budgetId);

    return match(settings)
      .with(
        { isLoading: false, isError: false, data: P.not(P.nullish) },
        ({ data }) => {
          const { decimal, symbol, show_symbol, thousands } = data[0];
          return (
            <span
              ref={forwardedRef}
              className={cx(
                [''],
                {
                  'text-green-700': withColor && Number(value) > 0,
                  'font-semibold': bold && Number(value) > 0,
                },
                props.className
              )}
            >
              {withSign && Number(value) < 0 ? '-' : ''}
              {accounting.formatMoney(
                value,
                show_symbol ? symbol : '',
                2,
                thousands,
                decimal
              )}
            </span>
          );
        }
      )
      .with({ isLoading: true }, () => (
        <span ref={forwardedRef}>
          <ThreeDots />
        </span>
      ))
      .otherwise(() => <span ref={forwardedRef} />);
  }
);

export { Money };
