import { AxiosResponse } from 'axios';
import { api } from '../../api';
import * as C from './PasswordReset.constants';

const passwordReset = async (payload: { email: string }) => {
  const response: AxiosResponse = await api.post(C.API_URL, payload);

  return response.data;
};

export { passwordReset };
