import * as React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Box, Button, Divider, Input, ComboboxOption } from '../Common';
import * as Combobox from '../Common/Combobox';
import type * as T from './Categories.types';
import { cx } from '../../utils';
import { match, P } from 'ts-pattern';

const CategoryForm = React.forwardRef<
  T.CategoryFormElement,
  T.CategoryFormProps
>(
  (
    {
      isLoading = false,
      onSubmit,
      onCancel,
      categoryGroups,
      onCategoryGroupCreate,
      ...props
    },
    forwardedRef
  ) => {
    const {
      control,
      handleSubmit,
      register,
      formState: { errors },
      setValue,
    } = useForm<T.CategoryFormInputs>({
      defaultValues: {
        name: '',
      },
    });

    const submitHandler: SubmitHandler<T.CategoryFormInputs> = (data) => {
      onSubmit?.(data);
    };

    // const payeeOnSync = (option: ComboboxOption) => {
    //   // onCreatePayee's optimistic update messes up the form state. Combobox updates
    //   // the selected value internally and provides an `onSync` method to sync any
    //   // changes.
    //   setValue('payee', option);
    // };

    const onCategoryGroupSync = (option: ComboboxOption) => {
      setValue('category_group', option);
    };

    const formattedCategoryGroups = categoryGroups?.map((group) => {
      return {
        label: group.name,
        value: group.id,
      };
    });

    return (
      <Box
        as="form"
        {...props}
        onSubmit={handleSubmit(submitHandler)}
        ref={forwardedRef}
      >
        <Box>Create a new Category</Box>
        <Divider />
        <Box>
          <Input
            label="Name"
            fullWidth
            hasErrors={!!errors.name}
            helpText={errors?.name?.message}
            {...register('name', { required: true })}
          />
        </Box>
        <Divider />
        <Box>
          <Controller
            name="category_group"
            control={control}
            rules={{ required: 'Category group required' }}
            render={({ field, fieldState }) => {
              return (
                <Combobox.$
                  className={cx(['w-full'])}
                  defaultItems={formattedCategoryGroups}
                  label="Category group"
                  placeholder="Select as option"
                  isInvalid={fieldState.invalid}
                  errorMessage={fieldState.error?.message}
                  onSelectionChange={field.onChange}
                  // onSync={onCategoryGroupSync}
                  isLoading={isLoading}
                  validationBehavior="aria"
                  allowsCustomValue
                >
                  {(data: Combobox.T.ComboboxOption) => (
                    <Combobox.Item id={String(data.value)}>
                      {data.label}
                    </Combobox.Item>
                  )}
                </Combobox.$>
              );
            }}
          />
        </Box>
        <Divider size="xl" />
        <Box className={cx(['flex', 'justify-end', 'space-x-3'])}>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button type="submit" intent="primary" isLoading={isLoading}>
            Save
          </Button>
        </Box>
      </Box>
    );
  }
);

export { CategoryForm };
