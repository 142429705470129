import { AxiosResponse } from 'axios';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from '../../api';
import * as Account from '../Account';
import * as U from './Transactions.utils';
import * as T from './Transactions.types';
import * as C from './Transactions.constants';
import {
  getTransactions,
  getTransactionsByPeriod,
  patchTransaction,
  upsertTransaction,
} from './Transactions.req';

const useGetTransactionsByPeriod = (
  budgetId: string,
  periodId: string,
  params: {}
) => {
  return useQuery({
    queryKey: [C.TRANSACTIONS_QUERY_KEY, budgetId, periodId, params],

    queryFn: () => getTransactionsByPeriod(budgetId, 0, periodId, params),
  });
};

const useGetTransactionsByDate = (
  accountId: string,
  budgetId: string,
  date: string,
  params = {}
) => {
  return useQuery({
    queryKey: [C.TRANSACTIONS_QUERY_KEY, accountId, budgetId, date, params],
    queryFn: () =>
      getTransactions(accountId, budgetId, 0, {
        date,
        ...params,
      }),
    enabled: !!(accountId && budgetId),
  });
};

const useGetTransactions = (
  accountId: string,
  budgetId: string,
  page = 0,
  params = {}
) => {
  return useQuery({
    queryKey: [C.TRANSACTIONS_QUERY_KEY, accountId, budgetId, page, params],
    queryFn: () => getTransactions(accountId, budgetId, page, params),
    enabled: !!(accountId && budgetId),
    placeholderData: keepPreviousData,
    // staleTime: 60 * 5,
  });
};

// This is a hard one. We need to update the transaction in the cache, but we also need to update the entries.
const useMutateTransaction = (
  accountId: string,
  budgetId: string,
  page = 0
) => {
  const queryKey = [C.TRANSACTIONS_QUERY_KEY, accountId, budgetId, page];
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (transaction: T.TransactionUpdate) =>
      patchTransaction(transaction),
    onMutate: async (transaction) => {
      await queryClient.cancelQueries({ queryKey });
      const previousTransactions = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (currentTransactionsPaginated: T.TransactionsPaginated) => {
          // TODO:FIX
          // @ts-ignore
          const newTransactions: T.TransactionRead[] =
            currentTransactionsPaginated?.results?.map((currentTransaction) => {
              // @ts-ignore mutations are weird with the type of transactions, but id is def available
              if (currentTransaction.id === transaction.id) {
                // console.log(
                //   'updating',
                //   currentTransaction.amount,
                //   'to',
                //   // @ts-ignore
                //   transaction.amount
                // );
                return {
                  ...currentTransaction,
                  // @ts-ignore
                  ...transaction,
                };
              }

              return currentTransaction;
            });
          return {
            ...currentTransactionsPaginated,
            results: newTransactions,
          };
        }
      );

      return { previousTransactions };
    },
    onSuccess: async () => {
      // Clear cache and pull totals
      // queryKey: [ACCOUNT_TOTALS_KEY, accountId, budgetId],
      await queryClient.invalidateQueries({
        queryKey: [Account.C.ACCOUNT_TOTALS_KEY, accountId, budgetId],
      });
    },
    onError: (err, updatedTransaction, context) => {
      queryClient.setQueryData(queryKey, context?.previousTransactions);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
};

const deleteTransactions = async (payload: string[]) => {
  await api.delete(`/${C.API_URL}`, {
    data: payload,
  });
};

const useDeleteTransactions = (accountId: string, budgetId: string) => {
  const queryClient = useQueryClient();

  // the key of this mutation
  const mutationKey = [C.DELETE_QUERY_KEY];

  // the key of the cached query that this mutation will invalidate
  const queryKey = [C.TRANSACTIONS_QUERY_KEY, accountId, budgetId];

  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (payload: string[]) => deleteTransactions(payload),
    onMutate: async (payload) => {
      await queryClient.cancelQueries({ queryKey });

      const previousTransactions = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (transactions: T.TransactionRead[]) => {
          return transactions?.filter(
            (transaction: T.TransactionRead) =>
              !payload.includes(transaction.id)
          );
        }
      );

      return { previousTransactions };
    },
    onError: (err, variables, context) => {
      // console.log('err', err);
      queryClient.setQueryData(queryKey, context?.previousTransactions);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
};

const createTransaction = async (
  transaction: T.TransactionCreate
): Promise<T.TransactionRead> => {
  const response: AxiosResponse<T.TransactionRead> = await api.post(
    '/transactions/',
    transaction
  );

  return response.data;
};

const useCreateTransaction = (accountId: string, budgetId: string) => {
  const queryClient = useQueryClient();
  const TRANSACTIONS_KEY = [C.TRANSACTIONS_QUERY_KEY, accountId, budgetId];
  return useMutation({
    mutationKey: [C.TRANSACTIONS_CREATE_KEY, accountId, budgetId],
    mutationFn: createTransaction,
    onMutate: async (newTransaction) => {
      await queryClient.cancelQueries({
        queryKey: TRANSACTIONS_KEY,
      });

      // What we get here is not really `TransactionRead` because we don't have an id yet.
      // We need to cast as `TransactionOptimistic` to make it work.
      // We can't cast or infer other type, so we are going to ignore for now.
      newTransaction.entries = [
        // @ts-ignore
        U.generateEntryFromOptimisticUpdate(newTransaction),
      ];

      // @ts-ignore
      newTransaction.account = {
        id: newTransaction.account_id,
      };

      const previousTransactions = queryClient.getQueryData(TRANSACTIONS_KEY);

      queryClient.setQueryData(
        TRANSACTIONS_KEY,
        // @ts-ignore
        (transactions: T.Transaction[]) => {
          if (transactions) {
            return [
              ...transactions,
              {
                ...newTransaction,
              },
            ];
          }

          return transactions;
        }
      );

      return {
        previousTransactions,
      };
    },

    onError: (err, newTransaction, context) => {
      queryClient.setQueryData(TRANSACTIONS_KEY, context?.previousTransactions);
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: TRANSACTIONS_KEY,
      });
    },
  });
};

const useUpsertTransaction = (
  accountId: string,
  budgetId: string,
  periodId: string,
  budgetingStyle: string
) => {
  // const queryKey = [C.TRANSACTIONS_UPSERT_KEY, accountId, budgetId];
  const queryClient = useQueryClient();
  const leftToBudgetTotalsQueryKey = [
    Account.C.ACCOUNT_TOTALS_KEY,
    accountId,
    budgetId,
    periodId,
    budgetingStyle,
  ];
  return useMutation({
    mutationFn: async (transaction: T.TransactionUpdate) =>
      upsertTransaction(transaction),
    onMutate: async (transaction) => {},
    onSuccess: async () => {
      // Clear cache and pull totals

      // Clear cache of Left to Budget totals in the Budget page.
      await queryClient.invalidateQueries({
        queryKey: leftToBudgetTotalsQueryKey,
      });
      // queryKey: [ACCOUNT_TOTALS_KEY, accountId, budgetId],
      // await queryClient.invalidateQueries({
      //   queryKey: [Account.C.ACCOUNT_TOTALS_KEY, accountId, budgetId],
      // });
    },
    onError: (err, updatedTransaction, context) => {
      // queryClient.setQueryData(queryKey, context?.previousTransactions);
    },
    onSettled: async () => {
      // await queryClient.invalidateQueries({ queryKey });
    },
  });
};

export {
  useGetTransactions,
  useGetTransactionsByPeriod,
  useGetTransactionsByDate,
  useCreateTransaction,
  useMutateTransaction,
  useUpsertTransaction,
  useDeleteTransactions,
};
