import { Box, Layout } from '../Common';
import { useRouteError } from 'react-router-dom';
import { cx } from '../../utils';

const ErrorNotFound = () => {
  const error = useRouteError();
  return (
    <Layout title="Page not found">
      <Box className={cx(['w-full', 'mx-auto', 'px-4', 'sm:px-6', 'lg:px-8'])}>
        <h1>Oops!</h1>

        <p>Sorry, an unexpected error has occurred.</p>
      </Box>
    </Layout>
  );
};

export { ErrorNotFound };
