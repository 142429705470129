import * as React from 'react';
import { Box } from '../Box';
import { cx } from '../../../utils';

type PublicElement = (
  props: React.PropsWithChildren<{}>
) => React.ReactElement | null;

const Public: PublicElement = ({ children }) => {
  return (
    <Box
      className={cx([
        'flex',
        'flex-col',
        'flex-grow',
        'bg-gray-100',
        'dark:bg-ew-bg-dark-600',
      ])}
    >
      <Box className={cx(['relative', 'z-60'])}>
        <Box className={cx(['px-2', 'py-8', 'mx-auto'])}>{children}</Box>
      </Box>
    </Box>
  );
};

export { Public };
