const API_URL = 'accounts';

const ACCOUNTS_QUERY_KEY = 'ACCOUNTS';

const ACCOUNT_INFO_QUERY_KEY = 'ACCOUNT_INFO';

const ACCOUNT_TOTALS_KEY = 'ACCOUNT_TOTALS';

const INTERNAL_ACCOUNTS_QUERY_KEY = 'INTERNAL_ACCOUNTS';

const DELETE_ACCOUNT_MUTATION_KEY = 'DELETE_ACCOUNT';

const CHECKING = 'CHECKING';
const CASH = 'CASH';
const GIFT_CARD = 'GIFT_CARD';
const SPENDING = 'SPENDING';
const SAVINGS = 'SAVINGS';
const CREDIT_CARD = 'CREDIT_CARD';
const DEBT_OTHER = 'DEBT_OTHER';
const INVESTMENT = 'INVESTMENT';
const OFF_BUDGET_ASSET = 'OFF_BUDGET_ASSET';
const MORTGAGE = 'MORTGAGE';
const OFF_BUDGET_LIABILITY = 'OFF_BUDGET_LIABILITY';

const accountTypesSpending = [CHECKING, CASH, GIFT_CARD, SPENDING, SAVINGS];

const accountTypesDebt = [CREDIT_CARD, DEBT_OTHER];

const accountTypesOffBudgetAssets = [INVESTMENT, OFF_BUDGET_ASSET];

const accountTypesOffBudgetLiabilities = [MORTGAGE, OFF_BUDGET_LIABILITY];

const accountTypesOffBudget = [OFF_BUDGET_ASSET, OFF_BUDGET_LIABILITY];

const ACCOUNT_AS = {
  ACCOUNT: 'ACCOUNT',
  INTERNAL: 'INTERNAL',
};

/**
 * Double-entry specific constants
 */

const INTERNAL_TYPES = {
  ASSET: 'ASSET',
  LIABILITY: 'LIABILITY',
};

export {
  API_URL,
  ACCOUNT_AS,
  ACCOUNT_INFO_QUERY_KEY,
  ACCOUNTS_QUERY_KEY,
  ACCOUNT_TOTALS_KEY,
  DELETE_ACCOUNT_MUTATION_KEY,
  INTERNAL_ACCOUNTS_QUERY_KEY,
  CHECKING,
  CASH,
  GIFT_CARD,
  SPENDING,
  SAVINGS,
  CREDIT_CARD,
  DEBT_OTHER,
  INVESTMENT,
  OFF_BUDGET_LIABILITY,
  OFF_BUDGET_ASSET,
  MORTGAGE,
  accountTypesSpending,
  accountTypesDebt,
  accountTypesOffBudgetAssets,
  accountTypesOffBudgetLiabilities,
  accountTypesOffBudget,
  INTERNAL_TYPES,
};
