import { cva } from 'class-variance-authority';

const base = [
  'relative',
  'rounded-full',
  'text-white',
  'focus-visible:outline',
  'focus-visible:outline-2',
  'focus-visible:outline-offset-2',
];

/** Intent */
const primary = [
  'bg-ew-primary',
  'hover:bg-ew-primary-600',
  'focus-visible:outline-ew-primary',
];

const secondary = [
  'bg-white',
  'text-gray-900',
  'bg-gray-100',
  'hover:bg-gray-200',
  'focus-visible:outline-gray-900',
  'dark:text-white',
  'dark:bg-ew-bg-dark-800',
  'dark:hover:text-white',
  'dark:hover:bg-ew-bg-dark-900',
  'dark:ring-0',
  'dark:focus-visible:outline-gray-50',
];

const ghost = [...secondary, 'bg-transparent', 'dark:bg-transparent'];

/** Size */
const sm = ['p-1'];
const md = ['p-1.5'];
const lg = ['p-2'];

const iconButtonStyles = cva(base, {
  variants: {
    intent: {
      primary,
      secondary,
      ghost,
    },
    size: {
      sm,
      md,
      lg,
    },
  },
  defaultVariants: {
    intent: 'secondary',
    size: 'md',
  },
});

export { iconButtonStyles };
