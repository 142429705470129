import { cva } from 'class-variance-authority';

const base = [
  'inline-flex',
  'items-center',
  'justify-center',
  'rounded-md',
  'text-sm',
  'font-medium',
  'ring-offset-bw-primary',
  'transition-colors',
  // 'hover:bg-gray-300',
  // 'hover:text-muted-foreground',
  // 'focus-visible:outline-none',
  // 'focus-visible:ring-2',
  // 'focus-visible:ring-ring',
  // 'focus-visible:ring-offset-2',
  // 'disabled:pointer-events-none',
  // 'disabled:opacity-50',
  // 'data-[state=on]:bg-ew-primary',
  // 'data-[state=on]:text-bw-primary',
];

const toggleStyles = cva(base, {
  variants: {
    intent: {
      default: [],
      outline:
        'border border-input bg-transparent hover:bg-accent hover:text-accent-foreground',
    },
    size: {
      default: 'h-10 px-3',
      sm: 'h-7 px-2.5',
      lg: 'h-11 px-5',
    },
  },
  defaultVariants: {
    intent: 'default',
    size: 'default',
  },
});

export { toggleStyles };
