import * as React from 'react';
import moment from 'moment';
import invariant from 'tiny-invariant';
import { SubmitHandler } from 'react-hook-form';
import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import {
  Await,
  useNavigation,
  useLoaderData,
  useSubmit,
  useParams,
} from 'react-router-dom';
import { Layout, Box, Divider, Alert, Button } from '../Common';
import { match, P } from 'ts-pattern';
import { cx } from '../../utils';
import { FaInfo } from 'react-icons/fa6';
import { FileImportsForm as Form } from './FileImports.form';
import { FileImportsTable as Table } from './FileImports.table';
import * as Accounts from '../Account';
import * as Transactions from '../Transactions';
import * as T from './FileImports.types';
import * as C from './FileImports.constants';
import * as H from './FileImports.hooks';
import * as req from './FileImports.req';
import { formatCSVData, serializeAmount } from './utils';
import { fad } from '@awesome.me/kit-7767038e99/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let figureOutType = (accountType: string, givenType: string) => {
  if (accountType === Accounts.C.INTERNAL_TYPES.ASSET) {
    return givenType.toLowerCase() === 'debit'
      ? Transactions.C.TYPES.OUTFLOW
      : Transactions.C.TYPES.INFLOW;
  }

  if (accountType === Accounts.C.INTERNAL_TYPES.LIABILITY) {
    return givenType.toLowerCase() === 'debit'
      ? Transactions.C.TYPES.INFLOW
      : Transactions.C.TYPES.OUTFLOW;
  }
};

type LoadersResponse = {
  accounts: Awaited<ReturnType<typeof Accounts.req.getAccounts>>;
  fileImports: Awaited<ReturnType<typeof req.getFileImports>>;
};

const FileImportsPage = () => {
  let { budgetId } = useParams();
  invariant(budgetId, 'budgetId is required.');

  let submit = useSubmit();
  let navigation = useNavigation();

  let queryRes = useLoaderData() as UseSuspenseQueryResult<LoadersResponse>;
  // Awaited<ReturnType<typeof Accounts.req.getAccounts>>

  let [isWrongFormat, setIsWrongFormat] = React.useState(false);
  let [csvSample, setCsvSample] = React.useState<T.FileImport[]>([]);

  let [selectedColumns, setSelectedColumns] = React.useState<string[]>([]);
  let [selectedColumnsMap, setSelectedColumnsMap] = React.useState({});

  let handleSubmit: SubmitHandler<T.FileImportFormFields> = ({
    csvData,
    file,
    account,
  }) => {
    let formattedCSVData = formatCSVData(csvData, selectedColumnsMap);

    let transactions: Transactions.T.TransactionCreate[] = formattedCSVData.map(
      (row) => {
        // let type = figureOutType(account.internal_type, String(row.type));
        let date = moment(String(row.date)).format('YYYY-MM-DD');

        let serializedResult = serializeAmount({
          // @ts-ignore
          accountType: account.internal_type,
          // @ts-ignore
          amount: row.amount,
          // @ts-ignore
          debit: row.inflow,
          // @ts-ignore
          credit: row.outflow,
          // @ts-ignore
          type: row.type,
        });

        delete row['outflow'];
        delete row['inflow'];

        return {
          ...row,
          date,
          internal_type: Transactions.C.INTERNAL_TYPES.TRANSACTION,
          type: serializedResult.type,
          source: Transactions.C.SOURCES.WEB_IMPORT,
          approved: true,
          budget_id: budgetId,
          account_id: account.id,
          amount: serializedResult.amount,
        };
      }
    );

    // TODO: FIX
    let newImport: T.FileImportCreate = {
      file_name: file?.name || '',
      // @ts-ignore
      file_type: C.FILE_TYPES[file?.type] || '',
      account_id: account.id,

      // @ts-ignore TODO: FIX
      transactions,
    };

    submit(newImport, {
      method: 'POST',
      encType: 'application/json',
    });
  };

  // We need to map the headers of the CSV file to the transactions fields.
  let onSelectColumn = (option: string, csvHeader: string, index: number) => {
    let newArr = selectedColumns;

    if (selectedColumns.includes(option)) {
      let currentIndex = newArr.indexOf(option);

      newArr[currentIndex] = '';

      newArr[index] = option;

      setSelectedColumnsMap({
        ...selectedColumnsMap,
        [option]: option,
      });

      setSelectedColumns(newArr);
    }

    newArr[index] = option;
    setSelectedColumnsMap({
      ...selectedColumnsMap,
      [csvHeader]: option,
    });

    setSelectedColumns(newArr);
  };

  return (
    <Layout title="Import transactions">
      <Box className={cx(['w-full', 'p-4', 'flex', 'flex-row', 'space-x-4'])}>
        <Box
          className={cx([
            'p-4',
            'border',
            'border-2',
            'w-1/2',
            'rounded-lg',
            'bg-white',
            'overflow-x-scroll',
          ])}
        >
          <React.Suspense fallback={<div>Loading accounts...</div>}>
            <Await
              resolve={queryRes.data}
              errorElement={
                <div>
                  Could not load accounts. Please refresh the page and try
                  again.
                </div>
              }
            >
              <Form
                onSubmit={handleSubmit}
                onFileLoad={setCsvSample}
                isLoading={navigation.state === 'submitting'}
                setIsWrongFormat={setIsWrongFormat}
              />
            </Await>
          </React.Suspense>
        </Box>
        <Box
          className={cx([
            'flex',
            'p-4',
            'border',
            'border-2',
            'w-1/2',
            'rounded-lg',
            'bg-white',
          ])}
        >
          <React.Suspense fallback={<div>Loading last three imports...</div>}>
            <Await
              resolve={queryRes.data}
              errorElement={
                <div>
                  Could not load last five imports. Please refresh the page and
                  try again.
                </div>
              }
            >
              <LastFiveImports />
            </Await>
          </React.Suspense>
        </Box>
      </Box>
      <Box className={cx(['pl-4', 'pr-4', 'pb-4'])}>
        {match(csvSample)
          .with([], () => null)
          .with(P.not(P.nullish), (data) => {
            return (
              <Box
                className={cx([
                  'p-4',
                  'border',
                  'border-2',
                  'w-full',
                  'rounded-lg',
                  'bg-white',
                  'overflow-x-scroll',
                ])}
              >
                <Box>
                  <Alert
                    icon={FaInfo}
                    intent="info"
                    description={
                      'This sample shows the first 20 rows of your file.'
                    }
                    className={cx(['border', 'border-blue-300'])}
                    border
                  />
                  <Divider />
                  <Box className={cx(['border', 'rounded-md'])}>
                    <Table
                      data={data}
                      onSelectColumn={onSelectColumn}
                      selectedMap={selectedColumnsMap}
                      selectedColumns={selectedColumns}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })
          .otherwise(() => null)}
      </Box>
    </Layout>
  );
};

let LastFiveImports = () => {
  let { budgetId } = useParams();
  invariant(budgetId, 'budgetId is required.');

  let submit = useSubmit();

  const query = useSuspenseQuery(H.getFileImportsQuery(budgetId));

  let handleDelete = (id: string) => {
    submit(
      { id },
      {
        method: 'DELETE',
        encType: 'application/json',
      }
    );
  };

  return (
    <Box className={cx(['w-full'])}>
      Last three imports
      <Divider />
      <ul className={cx(['w-full', 'space-y-2'])}>
        {/*@ts-ignore*/}
        {query.data.slice(-3).map((fileImport) => {
          return (
            <li
              key={fileImport.id}
              className={cx([
                'p-2',
                'bg-gray-100',
                'border',
                'border-gray-200',
                'w-full',
                'rounded-lg',
              ])}
            >
              <Box className={cx(['flex', 'justify-between'])}>
                <Box className={cx(['font-bold', 'text-gray-800'])}>
                  <span>{fileImport.file_name}</span>
                </Box>

                <Box>
                  <Button
                    aria-label="Delete import"
                    size="sm"
                    onClick={() => {
                      handleDelete(fileImport.id);
                      query.refetch().then(() => {});
                    }}
                  >
                    <FontAwesomeIcon
                      icon={fad.faTrash}
                      className={cx(['text-base', 'text-red-800'])}
                    />
                  </Button>
                </Box>
              </Box>
              <Box className={cx(['text-sm'])}>
                <span>({fileImport.transactions.length} transactions)</span>
              </Box>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export { FileImportsPage };
