import { useMutation } from '@tanstack/react-query';
import * as req from './SignOut.req';
import * as C from './SignOut.constants';

const useSignOut = () => {
  return useMutation({
    mutationKey: [C.MUTATION_KEY],
    mutationFn: req.signOut,
  });
};

export { useSignOut };
