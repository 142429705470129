import * as Budgets from '../../../Budget';
import { Box } from '../../../Common';
import { BudgetImportTableElement } from './ImportBudget.types';
import { cx } from '../../../../utils';

const ImportBudgetTable: BudgetImportTableElement = ({ data }) => {
  console.log('data 2', data);
  const headers = Object.keys(data?.[0]).map((header) => {
    switch (header.toLowerCase()) {
      case 'categorygroup':
        return 'Category Group';
      case 'duedate':
        return 'Due Date';
      default:
        return header;
    }
  });
  return (
    <table className={cx(['min-w-full', 'divide-y', 'divide-gray-300'])}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th
              className={cx([
                'px-6',
                'py-3',
                'text-left',
                'text-xs',
                'font-medium',
                'text-gray-500',
                'uppercase',
                'tracking-wider',
              ])}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={cx(['divide-y', 'divide-gray-200', 'bg-white'])}>
        {data.map((row) => (
          <tr>
            {Object.values(row).map((value) => (
              <td
                className={cx([
                  'px-6',
                  'py-4',
                  'text-sm',
                  'text-gray-800',
                  'whitespace-nowrap',
                ])}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export { ImportBudgetTable };
