import React from 'react';
import {
  badgeStyles,
  dotStyles,
  removeButtonStyles,
  closeIconStyles,
} from './Badge.styles';
import { CgSpinner } from 'react-icons/cg';
import { Box } from '../Box';
import { cx } from '../../../utils';
import { BadgeComponent, RemoveButtonComponent } from './Badge.types';
import { LoadingIconWrapper } from '../LoadingIconWrapper';

const RemoveButton: RemoveButtonComponent = ({ intent, className }) => {
  const removeButtonClasses = cx(removeButtonStyles({ intent }), className);
  const closeIconClasses = cx(closeIconStyles({ intent }));
  return (
    <button type="button" className={removeButtonClasses}>
      <span className="sr-only">Remove</span>
      <svg viewBox="0 0 14 14" className={closeIconClasses}>
        <path d="M4 4l6 6m0-6l-6 6" />
      </svg>
      <span className="absolute -inset-1"></span>
    </button>
  );
};

const Badge: BadgeComponent = ({
  isLoading,
  flat = false,
  withDot = false,
  canRemove = false,
  size,
  asPill,
  className,
  intent,
  children,
}) => {
  const badgeClasses = cx(
    badgeStyles({ intent, withDot, asPill, canRemove, flat, size }),
    className
  );
  const dotClasses = cx(dotStyles({ intent }));
  return (
    <Box as="span" className={badgeClasses}>
      {withDot ? (
        <svg className={dotClasses} viewBox="0 0 6 6" aria-hidden="true">
          <circle cx="3" cy="3" r="3" />
        </svg>
      ) : null}
      {isLoading ? (
        <>
          <LoadingIconWrapper>
            <CgSpinner className={cx(['motion-safe:animate-spin'])} />
          </LoadingIconWrapper>
          <span className={cx(['invisible'])}>{children}</span>
        </>
      ) : (
        children
      )}
      {canRemove ? <RemoveButton intent={intent} /> : null}
    </Box>
  );
};

export { Badge };
