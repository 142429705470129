import * as T from './Combobox.types';
import {
  Combobox,
  ComboboxItem,
  ComboboxContent,
  ComboboxSection,
} from './Combobox';
export * from './Combobox';
export * from './Combobox.types';

let $ = Combobox;
let Root = Combobox;
let Item = ComboboxItem;
let Section = ComboboxSection;
let Content = ComboboxContent;

export type { T };

export { Root, $, Item, Section, Content };
