import * as React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FaInfo } from 'react-icons/fa6';
import { Alert, Box, Button, Divider, Input } from '../Common';
import * as T from './PasswordReset.types';

const PasswordResetForm = React.forwardRef<
  T.PasswordResetElement,
  T.PasswordResetProps
>(({ onSubmit, isLoading = false, ...props }, forwardedRef) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<T.PasswordResetInputs>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit_: SubmitHandler<T.PasswordResetInputs> = (data) => {
    onSubmit?.(data);
  };

  return (
    <Box as="form" ref={forwardedRef} onSubmit={handleSubmit(onSubmit_)}>
      <Box>
        <Input
          label="Email address"
          type="email"
          hasErrors={'email' in errors}
          helpText={errors.email?.message}
          {...register('email', { required: 'Email address required' })}
        />
      </Box>
      <Divider />
      <Box>
        <Button intent="primary" fullWidth isLoading={isLoading}>
          Submit
        </Button>
      </Box>
    </Box>
  );
});

export { PasswordResetForm };
