import React, { forwardRef } from 'react';
import dashify from 'dashify';
import { NumericFormat } from 'react-number-format';
import type { InputComponent, InputProps } from './Input.types';
import { inputStyles } from './Input.styles';
import { Box } from '../Box';
import { cx } from '../../../utils';
import { FaCircleXmark } from 'react-icons/fa6';

// @ts-ignore
const Input: InputComponent = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      intent,
      size,
      disabled,
      hideLabel,
      type = 'text',
      labelClassName,
      hasErrors,
      helpText,
      forMoney,
      currency,
      numberOptions,
      fullWidth = false,
      icon: Icon,
      containerClasses,
      symbol = '$',
      className,
      withClearIcon = false,
      onClear,
      ...rest
    },
    ref
  ) => {
    const htmlId = dashify(rest.label);

    const classes = cx(
      inputStyles({ intent, hasErrors, size, disabled, forMoney }),
      {
        'pl-10': !!Icon,
        'pr-12': !!currency,
        'text-right': forMoney,
        'pr-8': withClearIcon,
      },
      className
    );

    const containerClasses_ = cx([
      {
        'w-full': fullWidth,
        'max-w-xs': !fullWidth,
      },
      containerClasses,
    ]);

    return (
      <Box className={containerClasses_}>
        <label
          className={cx(
            [
              'block',
              'text-sm',
              'font-medium',
              'leading-6',
              'text-gray-600',
              'dark:text-white',
            ],
            { 'sr-only': hideLabel }
          )}
          htmlFor={htmlId}
        >
          <span className="label-text">{rest.label}</span>
        </label>
        <Box
          className={cx([
            'relative',
            'rounded-md',
            'shadow-sm',
            {
              'mt-2': !hideLabel,
            },
          ])}
        >
          {withClearIcon ? (
            <Box
              className={cx([
                'absolute',
                'inset-y-0',
                'right-2',
                'flex',
                'items-center',
                'pl-3',
              ])}
            >
              <button
                onClick={(event) => {
                  onClear?.(event);
                }}
              >
                <FaCircleXmark
                  className={cx(['h-4', 'w-4', 'text-gray-400'])}
                />
              </button>
            </Box>
          ) : null}
          {Icon ? (
            <Box
              className={cx([
                'pointer-events-none',
                'absolute',
                'inset-y-0',
                'left-0',
                'flex',
                'items-center',
                'pl-3',
              ])}
            >
              <Icon className={cx(['h-5', 'w-5', 'text-gray-400'])} />
            </Box>
          ) : null}
          {forMoney && (
            <div
              className={cx([
                'pointer-events-none',
                'absolute',
                'inset-y-0',
                'left-0',
                'flex',
                'items-center',
                'pl-3',
              ])}
            >
              <span className={cx(['text-gray-500', 'sm:text-sm'])}>
                {symbol}
              </span>
            </div>
          )}
          {forMoney ? (
            <NumericFormat
              className={classes}
              // @ts-ignore
              type={type}
              // Should work with {...rest} but for some reason I need to pass it
              // explicitly for the styles to appear
              disabled={disabled}
              valueIsNumericString
              id={htmlId}
              {...numberOptions}
              {...rest}
              ref={ref}
              symbol={symbol}
            />
          ) : (
            <input
              type={type}
              // Should work with {...rest} but for some reason I need to pass it
              // explicitly for the styles to appear
              disabled={disabled}
              id={htmlId}
              {...rest}
              className={classes}
              ref={ref}
            />
          )}
          {forMoney && currency && (
            <div
              className={cx([
                'pointer-events-none',
                'absolute',
                'inset-y-0',
                'right-0',
                'flex',
                'items-center',
                'pr-3',
              ])}
            >
              <span
                className={cx(['text-gray-500', 'sm:text-sm'])}
                id="price-currency"
              >
                {currency}
              </span>
            </div>
          )}
        </Box>
        {helpText && (
          <p
            className={cx(
              ['mt-2', 'text-sm', 'text-gray-500', 'dark:text-gray-300'],
              {
                'text-ew-error': hasErrors,
                'dark:text-ew-error': hasErrors,
              }
            )}
          >
            {helpText}
          </p>
        )}
      </Box>
    );
  }
);

export { Input };
