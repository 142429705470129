import * as React from 'react';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Layout, Divider, Public } from '../Common';
import { PasswordUpdateForm as Form } from './PasswordUpdate.form';
import { cx } from '../../utils';
import * as H from './PasswordUpdate.hooks';
import * as T from './PasswordUpdate.types';
import { SubmitHandler } from 'react-hook-form';
import invariant from 'tiny-invariant';

const PasswordUpdatePage = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  invariant(uid, 'uid is required');
  invariant(token, 'token is required');

  const passwordUpdate = H.usePasswordUpdate();

  const onSubmit: SubmitHandler<T.PasswordUpdateInputs> = (data) => {
    const toastId = toast.loading('Updating password...');
    passwordUpdate.mutate(
      { ...data, uid, token },
      {
        onSuccess: () => {
          toast.success(
            'Password updated! Try to login with your new password.',
            {
              id: toastId,
            }
          );
          navigate('/sign-in');
        },
        onError: (error) => {
          toast.error('Error updating password', {
            id: toastId,
          });
        },
      }
    );
  };

  return (
    <Public>
      <Box className={cx(['w-96', 'mx-auto', 'my-auto'])}>
        <img
          className={cx(['h-12', 'w-auto', 'mx-auto'])}
          src="https://beta.budgetwise.io/img/budgetwise_badge.png"
          alt=""
        />

        <Box
          className={cx([
            'bg-white',
            'px-8',
            'py-6',
            'rounded-xl',
            'border',
            'border-gray-300',
            // 'shadow',
          ])}
        >
          <h1
            className={cx([
              'text-2xl',
              'text-gray-600',
              'font-bold',
              'text-center',
            ])}
          >
            Update your password
          </h1>
          <Divider size="xl" />
          <Form onSubmit={onSubmit} isLoading={passwordUpdate.isPending} />
        </Box>
      </Box>
    </Public>
  );
};

export { PasswordUpdatePage };
