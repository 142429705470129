import { cva } from 'class-variance-authority';

const trigger = [
  'flex',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'px-2',
  'py-1.5',
  'text-sm',
  'outline-none',
  'focus:bg-accent',
  'data-[state=open]:bg-accent',
];

const content = [
  'z-50',
  'min-w-[8rem]',
  'overflow-hidden',
  'rounded-md',
  'shadow-md',
  'border',
  'dark:border-gray-900',
  'bg-white',
  'dark:bg-ew-bg-dark-600',
  'p-1',
  'text-gray-700',
  'dark:text-gray-400',
  'shadow-md',
  'data-[state=open]:animate-in',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:fade-out-0',
  'data-[state=open]:fade-in-0',
  'data-[state=closed]:zoom-out-95',
  'data-[state=open]:zoom-in-95',
  'data-[side=bottom]:slide-in-from-top-2',
  'data-[side=left]:slide-in-from-right-2',
  'data-[side=right]:slide-in-from-left-2',
  'data-[side=top]:slide-in-from-bottom-2',
];

const item = [
  'relative',
  'flex',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'px-2',
  'py-1.5',
  'text-sm',
  'outline-none',
  'transition-colors',
  'focus:bg-gray-100',
  'dark:focus:bg-gray-900',
  'dark:focus:text-gray-400',
  'data-[disabled]:pointer-events-none',
  'data-[disabled]:opacity-50',
];

const label = ['px-2', 'py-1.5', 'text-sm', 'font-semibold'];

const separator = ['-mx-1', 'my-1', 'h-px', 'bg-gray-200', 'dark:bg-gray-600'];

const itemStyles = cva(item, {
  variants: {
    dark: {
      true: ['bg-ew-bg-dark-700', 'text-gray-300'],
    },
    darker: {
      true: [
        'dark:hover:bg-ew-bg-dark-800',
        'dark:focus:bg-ew-bg-dark-800',
        'dark:hover:text-white',
      ],
    },
  },
  defaultVariants: {
    dark: false,
    darker: false,
  },
});

const dropdownStyles = cva(content, {
  variants: {
    dark: {
      true: ['bg-ew-bg-dark-700', 'text-gray-300'],
    },
    darker: {
      true: ['dark:bg-ew-bg-dark-700', 'dark:text-gray-300'],
    },
  },
  defaultVariants: {
    dark: false,
    darker: false,
  },
});

export { dropdownStyles, trigger, content, item, itemStyles, label, separator };
