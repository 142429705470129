import { AxiosResponse } from 'axios';
import { api } from '../../api';
import * as T from './CategoryGroups.types';
import * as C from './CategoryGroups.constants';

const postCategoryGroup = async (category: T.CategoryGroupPost) => {
  const response: AxiosResponse<T.CategoryGroupGet[]> = await api.post(
    `/${C.API_URL}/`,
    category
  );

  return response.data;
};

const getCategoryGroups = async (
  budgetId: string
): Promise<T.CategoryGroupGet[]> => {
  const response: AxiosResponse<T.CategoryGroupGet[]> = await api.get(
    `/${C.API_URL}/`,
    {
      params: {
        budget_id: budgetId,
      },
    }
  );

  return response.data;
};

export { getCategoryGroups, postCategoryGroup };
