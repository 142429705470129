import moment from 'moment/moment';
import * as Categories from '../Categories';
import * as Transactions from '../Transactions';
import * as CategoryGroups from '../CategoryGroups';
import * as Accounts from '../Account';
import invariant from 'tiny-invariant';

const getPeriodDates = (periodId: string) => {
  const format = 'YYYY-MM-DD';
  const date = moment(periodId);
  const firstOfMonth = date.startOf('month').format(format);
  const endOfMonth = date.endOf('month').format(format);

  return [firstOfMonth, endOfMonth];
};

const addAmountsToCategory = (
  category: Categories.T.CategoryRead,
  transactions: Transactions.T.TransactionRead[],
  callback?: (amount: number | string) => void
): Categories.T.CategoryTable => {
  invariant(category.id, 'Category must have an id');

  // Find the transaction that matches the category and grab the amount.
  const amount =
    Transactions.U.findTransactionByCategory(transactions, category.id)
      ?.entries?.[0]?.amount ?? '0.00';

  callback?.(amount);

  return {
    ...category,
    amount,
    activity: '0.00',
    remaining: '0.00',
  };
};

type AddAmountsToGroup = (
  groups: CategoryGroups.T.CategoryGroupBase[],
  transactions: Transactions.T.TransactionRead[],
  callback?: (amount: number | string) => void
) => CategoryGroups.T.CategoryGroupGet<Categories.T.CategoryTable>[];

const addAmountsToGroup: AddAmountsToGroup = (
  groups,
  transactions,
  callback
) => {
  return groups.map((group) => {
    return {
      ...group,
      accounts: group.accounts.map((account) => {
        return addAmountsToCategory(account, transactions, callback);
      }),
    };
  });
};

export { getPeriodDates, addAmountsToGroup, addAmountsToCategory };
