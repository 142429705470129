import React, { forwardRef } from 'react';
import dashify from 'dashify';
import type { TextareaComponent, TextareaProps } from './Textarea.types';
import { textareaStyles } from './Textarea.styles';
import { Box } from '../Box';
import { cx } from '../../../utils';

// @ts-ignore
const Textarea: TextareaComponent = forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(
  (
    {
      intent,
      size,
      disabled,
      hideLabel,
      labelClassName,
      hasErrors,
      helpText,
      fullWidth = false,
      ...rest
    },
    ref
  ) => {
    const htmlId = dashify(rest.label);

    const classes = cx(
      textareaStyles({ intent, hasErrors, size, disabled }),
      rest.className
    );

    const containerClasses_ = cx([
      {
        'w-full': fullWidth,
        'max-w-xs': !fullWidth,
      },
    ]);

    return (
      <Box className={containerClasses_}>
        <label
          className={cx(
            [
              'block',
              'text-sm',
              'font-medium',
              'leading-6',
              'text-gray-900',
              'dark:text-white',
            ],
            { 'sr-only': hideLabel }
          )}
          htmlFor={htmlId}
        >
          <span className="label-text">{rest.label}</span>
        </label>
        <Box
          className={cx([
            'relative',
            'rounded-md',
            'shadow-sm',
            {
              'mt-2': !hideLabel,
            },
          ])}
        >
          <textarea
            // Should work with {...rest} but for some reason I need to pass it
            // explicitly for the styles to appear
            disabled={disabled}
            id={htmlId}
            {...rest}
            className={classes}
            ref={ref}
          />

          {helpText && (
            <p
              className={cx(
                ['mt-2', 'text-sm', 'text-gray-500', 'dark:text-gray-300'],
                {
                  'text-ew-error': hasErrors,
                  'dark:text-ew-error': hasErrors,
                }
              )}
            >
              {helpText}
            </p>
          )}
        </Box>
      </Box>
    );
  }
);

export { Textarea };
