import * as React from 'react';
import EmojiPicker, { SkinTonePickerLocation } from 'emoji-picker-react';
import { ProgressBar, Label } from 'react-aria-components';
import { Box, Money, Modal, Button } from '../Common';
import { cx } from '../../utils';
import * as T from './Categories.types';
import { FaTrash } from 'react-icons/fa6';

const Progress = ({ value }: { value: number }) => {
  return (
    <ProgressBar
      className={cx(['flex', 'flex-col', 'gap-3', 'w-full'])}
      value={value}
    >
      {({ percentage }) => (
        <>
          <Label className={cx(['sr-only'])}>Activity</Label>
          <Box className={cx(['flex'])}>
            <Box
              className={cx([
                'h-2',
                'top-[50%]',
                'transform',
                'translate-y-[-50%]',
                'w-full',
                'rounded-full',
                'bg-gray-700',
                'bg-opacity-40',
              ])}
            >
              <Box
                className={cx([
                  'absolute',
                  'h-2',
                  'top-[50%]',
                  'transform',
                  'translate-y-[-50%]',
                  'rounded-full',
                  'bg-gray-700',
                ])}
                style={{ width: percentage + '%' }}
              />
            </Box>
          </Box>
        </>
      )}
    </ProgressBar>
  );
};

const CategoriesRow = React.forwardRef<T.RowElement, T.RowProps>(
  (
    {
      name,
      emoji,
      activityTotal,
      budgetedAmount,
      dueDate,
      onDelete,
      onMove,
      onEmojiSelect,
      ...props
    },
    forwardedRef
  ) => {
    let [modalOpen, setModalOpen] = React.useState(false);

    return (
      <Box
        className={cx(
          [
            'flex',
            'flex-col',
            'bg-gray-200',
            'border-2',
            'border-gray-400',
            'p-2',
            'rounded-xl',
          ],
          props.className
        )}
        ref={forwardedRef}
      >
        <Box className={cx(['flex', 'row', 'items-center'])}>
          <Modal
            open={modalOpen}
            setOpen={setModalOpen}
            className={cx(['bg-transparent'])}
            trigger={
              <Box
                className={cx([
                  'bg-gray-300',
                  'rounded-full',
                  'w-10',
                  'h-10',
                  'flex',
                  'items-center',
                  'justify-center',
                  'border',
                  'border-gray-400',
                  'cursor-pointer',
                  'text-2xl',
                ])}
              >
                {emoji}
              </Box>
            }
          >
            <EmojiPicker
              width={300}
              onEmojiClick={(emoji, event) => {
                onEmojiSelect?.(emoji.emoji);
                setModalOpen(false);
              }}
              skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
            />
          </Modal>
          <Box className={cx(['px-2', 'text-zinc-700'])}>{name}</Box>
          <Box
            className={cx([
              'text-zinc-700',
              'text-lg',
              'font-semibold',
              'ml-auto',
            ])}
          >
            <Money value={budgetedAmount} />
          </Box>
        </Box>
        <Box className={cx(['pt-4', 'pb-2'])}>
          <Progress value={(activityTotal / budgetedAmount) * 100} />
        </Box>
        <Box
          className={cx([
            'flex',
            'row',
            'w-full',
            'justify-center',
            'text-zinc-700',
          ])}
        >
          <Money value={activityTotal} /> <Box className={cx(['mx-1'])}>of</Box>
          <Money value={budgetedAmount} /> <Box className={cx(['mx-1'])}>-</Box>
          {dueDate ? 'Due by ' + dueDate : null}
        </Box>
      </Box>
    );
  }
);

export { CategoriesRow };
