import { api } from '../../api';
import * as C from './PasswordUpdate.constants';
import * as T from './PasswordUpdate.types';

const passwordUpdate = async (payload: T.PasswordUpdatePayload) => {
  const response = await api.post(C.API_URL, payload);

  return response.data;
};

export { passwordUpdate };
