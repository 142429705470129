import React, { PropsWithChildren, ReactElement } from 'react';
import { cx } from '../../../utils';

type LoadingIconWrapperComponent = (
  props: PropsWithChildren<{}>
) => ReactElement | null;

// This component helps loading icons inside other components to keep the same size as the content
// but showing the loading icon instead
const LoadingIconWrapper: LoadingIconWrapperComponent = ({ children }) => (
  <span
    className={cx([
      'absolute',
      'top-[50%]',
      'left-[50%]',
      '-translate-x-2/4',
      '-translate-y-2/4',
    ])}
  >
    {children}
  </span>
);

export { LoadingIconWrapper };
