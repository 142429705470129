import React, { MouseEventHandler } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Box, Input, Textarea, Divider, Button, Alert } from '../../Common';
import { cx } from '../../../utils';
import type {
  UpdateAccountFormComponent,
  UpdateAccountFormInputs,
} from '../Account.types';

const UpdateAccountForm: UpdateAccountFormComponent = ({
  initialValue,
  formId,
  onSubmit,
  onDelete,
  hiddeButtons,
  isLoading,
  settings,
}) => {
  console.log('initial value', initialValue);
  const deleteAccountIsMutating = useIsMutating({
    mutationKey: ['deleteAccount'],
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateAccountFormInputs>({
    defaultValues: {
      ...initialValue,
    },
  });

  const onSubmit_: SubmitHandler<UpdateAccountFormInputs> = (data, options) => {
    onSubmit(data, {
      // onSuccess() {},
      // onError() {},
      // onSettled() {},
    });
  };

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    onDelete?.();
  };

  let aprField = register('apr');
  console.log('apr', aprField);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit_)} id={formId}>
      <Box>
        <Input
          label="Account name"
          hasErrors={'accountName' in errors}
          helpText={errors?.name?.message}
          fullWidth
          {...register('name', { required: true })}
        />
      </Box>
      <Divider />
      <Box>
        <Textarea label="Account notes" {...register('notes')} fullWidth />
      </Box>
      <Divider />
      <Box className={cx('flex')}>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                label="Interest Rate"
                forMoney
                symbol={'%'}
                defaultValue={'0.00'}
                numberOptions={{
                  decimalScale: 2,
                  allowNegative: false,
                  allowLeadingZeros: false,
                  prefix: '',
                  getInputRef: aprField.ref,
                }}
                {...field}
                value={field.value}
              />
            );
          }}
          name={'apr'}
        />

        <Divider orientation="vertical" />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                label="Minimum Payment"
                forMoney
                symbol={settings?.symbol}
                defaultValue={'0.00'}
                numberOptions={{
                  thousandSeparator: settings?.thousands,
                  decimalSeparator: settings?.decimal,
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                {...field}
                value={field.value}
              />
            );
          }}
          name={'min_payment'}
        />
      </Box>
      <Box
        className={cx([
          'border-b',
          'border-gray-200',
          'dark:border-ew-border-dark',
          'my-9',
        ])}
      />
      <details>
        <summary
          className={cx([
            'dark:text-white',
            'px-3',
            'py-2',
            'cursor-pointer',
            'border',
            'border-yellow-400',
            'dark:border-yellow-700',
            'rounded-lg',
            'text-gray-600',
            'dark:text-gray-200',
          ])}
        >
          Delete account?
        </summary>
        <Divider />
        <Alert
          intent="warning"
          icon={FaExclamationTriangle}
          description="This action will delete this account and all its transactions."
        >
          <Button
            fullWidth
            intent="danger"
            onClick={handleDelete}
            isLoading={!!deleteAccountIsMutating}
          >
            Delete Account
          </Button>
        </Alert>
      </details>

      {!hiddeButtons ? (
        <>
          <Divider />
          <Box>
            <Button isLoading={isLoading} type="submit">
              Submit
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export { UpdateAccountForm };
