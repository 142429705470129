import { cx } from '../../../utils';

const labelStyles = cx([
  'block',
  'text-sm',
  'font-medium',
  'leading-6',
  'text-gray-600',
  'dark:text-white',
  'label-text',
]);

export { labelStyles };
