import { QueryClient } from '@tanstack/react-query';
import { defer, Params } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { api } from '../../api';
import * as C from './Transactions.constants';
import * as T from './Transactions.types';
import invariant from 'tiny-invariant';

const getTransactionDetail = async (id: string) => {
  const response: AxiosResponse<T.TransactionRead> = await api.get(
    C.API_URL + '/' + id
  );
  return response.data;
};

const transactionDetailQuery = (id: string) =>
  ({
    queryKey: [C.TRANSACTIONS_QUERY_KEY, { transactionId: id }],
    queryFn: async () => getTransactionDetail(id),
  } as const);

// https://github.com/TkDodo/blog-comments/discussions/84#discussioncomment-9700256
function transactionDetailLoader(queryClient: QueryClient, params: Params) {
  invariant(params.transactionId, 'transactionId is required');

  return defer({
    data: () => {
      return queryClient.ensureQueryData(
        transactionDetailQuery(String(params.transactionId))
      );
    },
  });
}

export {
  transactionDetailLoader,
  transactionDetailQuery,
  getTransactionDetail,
};
