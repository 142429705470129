import invariant from 'tiny-invariant';
import { AxiosResponse } from 'axios';
// @ts-ignore
import * as _ from 'lodash-es';
import * as Budgets from '../Budget';
import { AccountRead } from './Account.types';
import * as C from './Account.constants';
import * as T from './Account.types';
import { api } from '../../api';

const getAccount = async (accountId: string): Promise<AccountRead> => {
  const response: AxiosResponse<AccountRead> = await api.get(
    `/${C.API_URL}/${accountId}`
  );

  return response.data;
};

const deleteAccount = async (accountId: string) => {
  const response = await api.delete(`/${C.API_URL}/${accountId}`);

  return response.data;
};

const postAccount = async (
  newAccount: T.AccountPost,
  budgetId: string
): Promise<T.AccountRead> => {
  const response: AxiosResponse<T.AccountRead> = await api.post(
    `/${C.API_URL}/`,
    newAccount,
    {
      params: {
        budget_id: budgetId,
      },
    }
  );

  return response.data;
};

const patchAccount = async (account: T.AccountPost): Promise<T.AccountRead> => {
  // TODO: FIX
  // @ts-ignore
  invariant(!_.isUndefined(account.id), '`id` is required in patchAccount.');

  // TODO: FIX
  // @ts-ignore
  const { id: accountId, ...rest } = account;
  const response: AxiosResponse<T.AccountRead> = await api.patch(
    `/${C.API_URL}/${accountId}/`,
    rest
  );

  return response.data;
};

const getAccounts = async (budgetId: string): Promise<T.AccountRead[]> => {
  const response: AxiosResponse<T.AccountRead[]> = await api.get(
    `/${C.API_URL}/`,
    {
      params: {
        budget_id: budgetId,
        _as: 'ACCOUNT',
      },
    }
  );

  return response.data;
};

const getInternalAccounts = async (budgetId: string) => {
  const response: AxiosResponse<T.AccountRead[]> = await api.get(
    `/${C.API_URL}/`,
    {
      params: {
        budget_id: budgetId,
        type: 'INTERNAL',
      },
    }
  );

  return response.data;
};

const getAccountTotals = async (
  accountId: string,
  budgetId: string,
  periodId?: string,
  // only works for LEFT_TO_BUDGET
  budgetingStyle: string = Budgets.C.BUDGETING_STYLES.GLOBAL_LTB,
  params = {}
): Promise<T.AccountTotals> => {
  const response = await api.get(`/${C.API_URL}/${accountId}/totals`, {
    params: {
      budget_id: budgetId,
      period_id: periodId,
      budgeting_style: budgetingStyle,
      ...params,
    },
  });

  return response.data;
};

export {
  getAccount,
  deleteAccount,
  postAccount,
  patchAccount,
  getAccounts,
  getAccountTotals,
  getInternalAccounts,
};
