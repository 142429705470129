import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { Box } from './components';
import { cx } from './utils';
import { router } from './router';

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    if (localStorage.getItem('theme') === 'dark') {
      document.querySelector('html').classList.add('dark');
    }
  }, []);

  let routerInstance = router(queryClient);

  return (
    <QueryClientProvider client={queryClient}>
      <Box className={cx(['flex', 'h-full', 'flex-col'])}>
        <RouterProvider router={routerInstance} />
      </Box>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export { App };
