import * as _ from 'lodash-es';
import { ColumnDef } from '@tanstack/react-table';
import { ChangeEvent, FormEvent, useState, useRef, useEffect } from 'react';
// import { FaAngleDoubleRight } from 'react-icons/fa';
import {
  Input,
  // IconButton,
  Money,
  Box,
} from '../../Common';
import * as Category from '../../Categories';
import { cx } from '../../../utils';
import accounting from 'accounting';
import { defaultShouldDehydrateMutation } from '@tanstack/react-query';

const categoryBudgeted: Partial<ColumnDef<Category.T.Category>> = {
  cell: ({
    getValue,
    row: { index, original, getIsSelected, getCanExpand },
    column: { id: columnId },
    table,
  }) => {
    const initialValue = getValue();

    const settings = table.options.meta?.settings;
    const latestCategory = table.options.meta?.latestCategory;

    /* eslint-disable react-hooks/rules-of-hooks */
    // const shouldAutofocus = latestCategory === original.name;

    // const [value, setValue] = useState(initialValue);

    // @ts-ignore
    if (original.isGroupHeader) {
      return null;
    }

    if (getCanExpand()) {
      const total = _.sum(
        // TODO: fix types
        // @ts-ignore
        original.accounts?.map((account) => Number(account.amount))
      );
      return (
        <Box
          className={cx([
            'flex',
            'items-end',
            'justify-end',
            'w-[120px]',
            'font-semibold',
          ])}
        >
          <Money value={total} />
        </Box>
      );
    }

    const update = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      const amount = accounting
        .unformat(newValue, settings?.decimal)
        .toString();

      table.options.meta?.onBudgetedAmountUpdate(index, columnId, {
        amount,
        categoryId: original.id,
      });
    };

    /* eslint-enable */
    return (
      <Box className={cx(['flex', 'items-center', 'space-x-2', 'w-[120px]'])}>
        {/*<IconButton*/}
        {/*  className={cx(['z-10'])}*/}
        {/*  iconClasses={cx(['rounded-0'])}*/}
        {/*  size={'sm'}*/}
        {/*  label={'Test'}*/}
        {/*  icon={FaAngleDoubleRight}*/}
        {/*/>*/}
        <Input
          label="Budgeted"
          hideLabel
          defaultValue={initialValue?.toString()}
          forMoney
          symbol={settings?.symbol}
          numberOptions={{
            thousandSeparator: settings?.thousands,
            decimalSeparator: settings?.decimal,
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          onBlur={update}
        />
      </Box>
    );
  },
};

export { categoryBudgeted };
