import React from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { match, P } from 'ts-pattern';
import { Box, Textarea, Divider, Input } from '../../Common';
import * as Combobox from '../../Common/Combobox';
import { cx } from '../../../utils';
import { accountTypeOptions } from '../../../utilities/accountTypes';

import * as T from '../Account.types';
import * as C from '../Account.constants';

const NewAccountForm: T.NewAccountFormComponent = ({
  onSubmit,
  settings,
  formId,
}) => {
  const {
    // reset,
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    // setFocus,
  } = useForm<T.NewAccountFormInputs>({
    defaultValues: {},
  });

  const watchType = watch('type');
  console.log('type', watchType);
  console.log('debt', C.accountTypesDebt.includes(String(watchType)));

  const onSubmit_: SubmitHandler<T.NewAccountFormInputs> = (data, options) => {
    onSubmit(data, {});
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit_)} id={formId}>
      <Box>
        <Input
          label="Account name"
          autoFocus
          fullWidth
          hasErrors={'name' in errors}
          helpText={errors.name?.message}
          {...register('name', { required: true })}
        />
      </Box>

      <Divider />

      <Box>
        <Controller
          control={control}
          rules={{ required: 'Please select an account type ' }}
          render={({ field, fieldState }) => {
            return (
              <Combobox.$
                className={cx(['w-full'])}
                label="Account type"
                placeholder="Select account type"
                defaultItems={accountTypeOptions}
                errorMessage={fieldState.error?.message}
                {...field}
                onSelectionChange={field.onChange}
                isInvalid={!!fieldState.error}
              >
                {(data: Combobox.T.ComboboxOption) => {
                  return match(data)
                    .with({ value: P.array(P.not(P.nullish)) }, ({ value }) => {
                      return (
                        <Combobox.Section
                          title={data.label}
                          id={String(value)}
                          items={Array.isArray(value) ? value : []}
                        >
                          {(item: Combobox.T.ComboboxOption) => (
                            <Combobox.Item id={String(item.value)}>
                              {item.label}
                            </Combobox.Item>
                          )}
                        </Combobox.Section>
                      );
                    })
                    .with({ value: P.string }, () => (
                      <Combobox.Item id={String(data.value)}>
                        {data.label}
                      </Combobox.Item>
                    ))
                    .otherwise(() => <div />);
                }}
              </Combobox.$>
            );
          }}
          name={'type'}
        />
      </Box>
      <Divider />
      <Box>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                label="Current balance"
                defaultValue={'0.00'}
                forMoney
                fullWidth
                symbol={settings?.symbol}
                numberOptions={{
                  thousandSeparator: settings?.thousands,
                  decimalSeparator: settings?.decimal,
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                {...field}
                value={field.value}
              />
            );
          }}
          name={'opening_balance'}
        />
      </Box>
      {/*@ts-ignore*/}
      {C.accountTypesDebt.includes(String(watchType)) ? (
        <>
          <Divider />
          <Box className={cx('flex')}>
            <Controller
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <Input
                    label="Interest Rate"
                    forMoney
                    defaultValue={'0'}
                    symbol={'%'}
                    numberOptions={{
                      decimalScale: 2,
                      allowNegative: false,
                      allowLeadingZeros: false,
                      prefix: '',
                    }}
                    {...field}
                    value={field.value}
                  />
                );
              }}
              name={'apr'}
            />

            <Divider orientation="vertical" />
            <Controller
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <Input
                    label="Minimum Payment"
                    forMoney
                    defaultValue={'0.00'}
                    symbol={settings?.symbol}
                    numberOptions={{
                      thousandSeparator: settings?.thousands,
                      decimalSeparator: settings?.decimal,
                      fixedDecimalScale: true,
                      decimalScale: 2,
                    }}
                    {...field}
                    value={field.value}
                  />
                );
              }}
              name={'min_payment'}
            />
          </Box>
        </>
      ) : null}
      <Divider />
      <Box>
        <Textarea label="Account notes" fullWidth {...register('notes')} />
      </Box>
    </Box>
  );
};

export { NewAccountForm };
