import { api } from '../../api';
import * as C from './FileImports.constants';
import * as T from './FileImports.types';

let getFileImports = async (budgetId: string) => {
  const res = await api.get(C.API_URL, {
    params: {
      budget_id: budgetId,
    },
  });

  return res.data;
};

const createImport = async (payload: T.FileImportCreate) => {
  const res = await api.post(C.API_URL, payload);

  return res.data;
};

let deleteImport = async (id: string) => {
  const res = await api.delete(`${C.API_URL}${id}/`);

  return res.data;
};

export { createImport, getFileImports, deleteImport };
