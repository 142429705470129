import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { PaginationState } from '@tanstack/react-table';
import { AccountRead } from './Account.types';
// import type {} from '@redux-devtools/extension';

type AccountState = {
  accountToEdit?: AccountRead;
  setAccountToEdit: (account: AccountRead) => void;
  editAccountFormOpen: boolean;
  setEditAccountFormOpen: (open: boolean) => void;
  transactionsPaginationState: PaginationState;
  setTransactionsPaginationState: (
    transactionsPaginationState: PaginationState
  ) => void;
};

const useAccountStore = create<AccountState>()(
  devtools((set) => ({
    accountToEdit: undefined,
    editAccountFormOpen: false,
    transactionsPaginationState: { pageIndex: 0, pageSize: 10 },
    setTransactionsPaginationState: (transactionsPaginationState) =>
      set(() => ({ transactionsPaginationState })),
    setAccountToEdit: (account: AccountRead) =>
      set(() => ({ accountToEdit: account })),
    setEditAccountFormOpen: (open) =>
      set(() => {
        return { editAccountFormOpen: open };
      }),
  }))
);

export { useAccountStore };
