import * as React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Button, Divider, Input } from '../Common';
import type * as T from './Budget.types';
import { cx } from '../../utils';

const BudgetForm = React.forwardRef<T.BudgetFormElement, T.BudgetFormProps>(
  ({ isLoading = false, onSubmit, onCancel, ...props }, forwardedRef) => {
    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm<T.BudgetFormInputs>({
      defaultValues: {
        name: '',
      },
    });

    const submitHandler: SubmitHandler<T.BudgetFormInputs> = (data) => {
      onSubmit?.(data);
    };

    return (
      <Box
        as="form"
        {...props}
        onSubmit={handleSubmit(submitHandler)}
        ref={forwardedRef}
      >
        <Box>Create a new Budget</Box>
        <Divider />
        <Box>
          <Input
            label="Name"
            fullWidth
            hasErrors={!!errors.name}
            helpText={errors?.name?.message}
            {...register('name', { required: true })}
          />
        </Box>
        <Divider size="xl" />
        <Box className={cx(['flex', 'justify-end', 'space-x-3'])}>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button type="submit" intent="primary" isLoading={isLoading}>
            Create
          </Button>
        </Box>
      </Box>
    );
  }
);

export { BudgetForm };
