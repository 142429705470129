import { ClassValue, clsx } from 'clsx';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';

const cx = (...classes: ClassValue[]) => twMerge(clsx(classes));

const getCurrentMonthName = () => {
  const date = new Date();
  return date.toLocaleString('default', { month: 'long' });
};

const isNumberNegative = (amount: string | number) =>
  Math.sign(Number(amount)) === -1;

const getTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return timeZone;
};

const localToUTCTime = (date: string, format: string = 'YYYY-MM-DD') => {
  return moment.utc(date).format(format);
};

const UTCToLocalTime = (date: string, format: string = 'YYYY-MM-DD') => {
  return moment(date).local().format(format);
};

// @ts-ignore
const urlPath = (args: string[]) => {
  return `/${args.join('/')}/`;
};

const getPeriodId = (
  periodId = moment(new Date()).local().format('YYYY-MM')
) => {
  return {
    previous: moment(periodId).subtract(1, 'month').local().format('YYYY-MM'),
    current: periodId,
    next: moment(periodId).add(1, 'month').local().format('YYYY-MM'),
  };
};

export {
  cx,
  getCurrentMonthName,
  isNumberNegative,
  getTimeZone,
  localToUTCTime,
  UTCToLocalTime,
  urlPath,
  getPeriodId,
};
