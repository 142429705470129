import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { useFocusWithin, FocusScope } from 'react-aria';
import accounting from 'accounting';
import { ColumnDef } from '@tanstack/react-table';
import { TableTransaction } from '../../Transactions.types';
import { TYPES } from '../../Transactions.constants';
import { Input, Box, Money } from '../../../Common';
import { useAmountStore } from './AmountColumn.store';
import { cx } from '../../../../utils';
import { getCurrentAccountEntry, getEntries } from '../../Transactions.utils';
import invariant from 'tiny-invariant';

/**
 * This component was difficult to figure out. The logic is as follows.
 *
 * We are rendering two components from a single source of information, i.e.,
 * `transaction.amount`.
 *
 * We use a single amount to render two components:
 * - Inflow
 * - Outflow
 *
 * We use zustand to share state between both components independently of the
 * table's state. We save the value with a key referencing the index of the row
 * and the type of transaction, e.g.: `${index}-${type}` as 0-outflow.
 *
 * So if we update "0-outflow" we set "0-inflow" to 0 and vice versa. This state
 * update should reflect on the UI in real time, that's why we use the additional
 * state management.
 *
 * The change on UI makes it seem like they are to different amounts, but in reality
 * we are updating the current transaction amount field.
 *
 * The amount renders only if the column type equals the transaction type. And we
 * use the zustand state to render these values, because the component and the
 * table state are one, but the zustand store saves it in two different objects:
 * - 0-outflow: 23,
 * - 0-inflow: 0,
 * - 1-outflow: 12,
 * - 1-inflow: 0,
 * - 2-outflow: 0,
 * - 2-inflow: 43
 *
 * Notice how each index number can't have a value in both types, i.e., you can't
 * have values in outflow and inflow of the same row, if you update one the other
 * clears out.
 *
 * So we keep the zustand store state and the table state in sync but in different
 * representations:
 * - component state: { amount: 23, type: "outflow" }
 * - zustand state: { "0-outflow": 23, "0-inflow": 0 }
 *
 * We use those two representations to render our components.
 *
 */
const getAmountColumn = (
  type: typeof TYPES.INFLOW | typeof TYPES.OUTFLOW
): Partial<ColumnDef<TableTransaction>> => ({
  cell: ({
    getValue,
    row: { index, original, getIsSelected },
    column: { id },
    table,
  }) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    const [isFocusWithin, setFocusWithin] = useState(false);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithin: (event) => {
        setFocusWithin(true);
      },
      onBlurWithin: (event) => {
        setFocusWithin(false);
      },
    });

    // Transfer transactions have the existing account as the category_id and
    // have an internal type of "TRANSFER"
    const isTransfer =
      original.internal_type === 'TRANSFER' &&
      original.account_id !== table.options.meta?.accountId &&
      original.category_id === table.options.meta?.accountId;

    /* eslint-disable react-hooks/rules-of-hooks */
    const settings = table.options.meta?.settings;
    const { amounts, setAmount } = useAmountStore((state) => state);
    const initialValue = getValue<string>();

    const entry = getCurrentAccountEntry(original);
    invariant(
      entry,
      'Entry is undefined. Something when wrong when creating the transaction.'
    );

    const entries = getEntries(original);
    invariant(
      entries,
      'Entries are undefined. Something when wrong when creating the transaction.'
    );

    const account = entry.account_id;

    const key = `amount-${index}-${type}`;
    const oppositeType = type === TYPES.INFLOW ? TYPES.OUTFLOW : TYPES.INFLOW;
    const currentTypeKey = `${original.id}-${type}`;
    const oppositeTypeKey = `${original.id}-${oppositeType}`;

    useEffect(() => {
      setAmount?.(account, {
        [currentTypeKey]: initialValue,
      });
    }, []);

    const updateState = (value: string) => {
      setAmount?.(account, {
        [currentTypeKey]: value,
        [oppositeTypeKey]: '0',
      });
    };

    const updateStateAndDatabase = (newValue: string) => {
      // If value doesn't change on blur then do nothing.
      if (newValue === '0' || initialValue === newValue) {
        return;
      }

      updateState(newValue);

      const amount = accounting
        .unformat(newValue, settings?.decimal)
        .toString();

      // if we are updating from 0 to a value, then we need to update the current entry
      // and set the other entry as 0.
      if (initialValue === '0' && newValue !== '0') {
        table.options.meta?.onTransactionChange(index, id, {
          id: String(original.id),
          amount,
          type: original.type === TYPES.INFLOW ? TYPES.OUTFLOW : TYPES.INFLOW,
        });
      }

      // update amount but not type, i.e., change value in the same amount field
      if (initialValue !== '0' && newValue !== initialValue) {
        table.options.meta?.onTransactionChange(index, id, {
          id: String(original.id),
          amount,
        });
      }
    };

    // This will affect only the destination account. People won't be able to
    // change the amount, instead they will have to go to the source account and
    // change it there.
    // We might change it where clicking on this will open the Transaction popup,
    // so data consistency is kept.
    if (isTransfer) {
      return (
        <Box
          className={cx(['w-[100px]', 'text-right', 'ml-auto'], {
            'opacity-0': initialValue !== '0',
          })}
        >
          <Money value={amounts?.[account]?.[oppositeTypeKey]} />
        </Box>
      );
    }

    const isIncome = original.category?._as === 'LEFT_TO_BUDGET';

    return (
      <Box
        tabIndex={isFocusWithin ? -1 : 0}
        className={cx(['w-[100px]', 'text-right', 'ml-auto'])}
        {...focusWithinProps}
      >
        {match(isFocusWithin)
          .with(false, () => (
            <Box
              className={cx({
                'opacity-0': initialValue === '0',
              })}
            >
              <Money
                withColor={isIncome}
                value={type === original.type ? initialValue : '0'}
              />
            </Box>
          ))
          .with(true, () => {
            return (
              <Input
                symbol={settings?.symbol}
                containerClasses={cx(['w-[100px]', 'ml-auto'])}
                className={cx(['w-[100px]'])}
                key={key}
                label="Transaction amount"
                hideLabel
                forMoney
                value={
                  type === original.type
                    ? amounts?.[account]?.[currentTypeKey]
                    : 0
                }
                onValueChange={(values, sourceInfo) =>
                  updateState(values?.value)
                }
                onFocus={(event) => event.target.select()}
                onBlur={() =>
                  updateStateAndDatabase(amounts?.[account]?.[currentTypeKey])
                }
                numberOptions={{
                  thousandSeparator: settings?.thousands,
                  decimalSeparator: settings?.decimal,
                  fixedDecimalScale: true,
                  decimalScale: 2,
                }}
                autoFocus
              />
            );
          })
          .otherwise(() => null)}
      </Box>
    );
    /* eslint-enable */
  },
});

export { getAmountColumn };
