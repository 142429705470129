let API_URL = '/entries';

const ENTRIES_QUERY_KEY = 'entries';
const ENTRIES_MUTATION_KEY = ENTRIES_QUERY_KEY;

const DIRECTIONS = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
};

// Pagination
const PAGINATION_LIMIT = 20;
const PAGINATION_OFFSET = 20;

export {
  API_URL,
  ENTRIES_QUERY_KEY,
  ENTRIES_MUTATION_KEY,
  DIRECTIONS,
  PAGINATION_OFFSET,
  PAGINATION_LIMIT,
};
