import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type TransactionsState = {
  transactionFormOpen: boolean;
  setTransactionFormOpen: (open: boolean) => void;
};

const transactionsStore = create<TransactionsState>()(
  devtools((set) => ({
    transactionFormOpen: false,
    setTransactionFormOpen: (open) =>
      set(() => ({ transactionFormOpen: open })),
  }))
);

export { transactionsStore };
