import { cva } from 'class-variance-authority';

const base = [
  'relative',
  'inline-flex',
  'items-center',
  'rounded-md',
  'text-xs',
  'font-medium',
];

const primary = [
  'bg-ew-primary-50',
  'dark:bg-ew-primary-500/30',
  'text-ew-primary-600',
  'dark:text-ew-primary-300',
  'ring-ew-primary-600/20',
  'dark:ring-ew-primary-500/20',
];

const secondary = [
  'bg-gray-50',
  'dark:bg-gray-400/10',
  'text-gray-600',
  'dark:text-gray-400',
  'ring-gray-500/10',
  'dark:ring-gray-400/20',
];

const success = [
  'bg-green-50',
  'dark:bg-green-500/10',
  'text-green-700',
  'dark:text-green-400',
  'ring-green-600/20',
  'dark:ring-green-500/20',
];

const danger = [
  'bg-red-50',
  'dark:bg-red-400/10',
  'text-red-700',
  'dark:text-red-400',
  'ring-red-600/10',
  'dark:ring-red-400/20',
];

const warning = [
  'bg-yellow-50',
  'dark:bg-yellow-400/10',
  'text-yellow-800',
  'dark:text-yellow-500',
  'ring-yellow-600/20',
  'dark:ring-yellow-400/20',
];

const withDot = ['gap-x-1.5'];

const asPill = ['rounded-full'];

const canRemove = ['gap-x-0.5'];

const badgeStyles = cva(base, {
  variants: {
    intent: {
      primary,
      secondary,
      success,
      danger,
      warning,
    },
    size: {
      sm: ['px-1.5', 'py-0.5'],
      md: ['px-2', 'py-1'],
    },
    withDot: {
      true: withDot,
    },
    asPill: {
      true: asPill,
    },
    canRemove: {
      true: canRemove,
    },
    flat: {
      true: [''],
      false: ['ring-1', 'ring-inset'],
    },
  },
  defaultVariants: {
    intent: 'secondary',
    flat: false,
    size: 'md',
  },
});

const dotStyles = cva(['w-1.5', 'h-1.5'], {
  variants: {
    intent: {
      primary: ['fill-ew-primary-500', 'dark:fill-ew-primary-400'],
      secondary: ['fill-gray-500', 'dark:fill-gray-400'],
      success: ['fill-green-500', 'dark:fill-green-400'],
      danger: ['fill-red-500', 'dark:fill-red-400'],
      warning: ['fill-yellow-500', 'dark:fill-yellow-400'],
    },
  },
  defaultVariants: {
    intent: 'secondary',
  },
});

const removeButtonStyles = cva(
  [
    'group',
    'relative',
    '-mr-1',
    'h-3.5',
    'w-3.5',
    'rounded-sm',
    'focus:outline-none',
    'focus-visible:ring-2',
  ],
  {
    variants: {
      intent: {
        primary: [
          'hover:bg-ew-primary-500/20',
          'dark:hover:bg-ew-primary-300/20',
          'focus-visible:ring-ew-primary',
        ],
        secondary: [
          'hover:bg-gray-500/20',
          'dark:hover:bg-gray-300/20',
          'focus-visible:ring-gray-600',
          'dark:focus-visible:ring-gray-400',
        ],
        success: [
          'hover:bg-green-600/20',
          'dark:hover:bg-green-300/20',
          'focus-visible:ring-green-700',
          'dark:focus-visible:ring-green-400',
        ],
        danger: [
          'hover:bg-red-600/20',
          'dark:hover:bg-red-300/20',
          'focus-visible:ring-red-700',
          'dark:focus-visible:ring-red-400',
        ],
        warning: [
          'hover:bg-yellow-600/20',
          'dark:hover:bg-yellow-300/20',
          'focus-visible:ring-yellow-800',
          'dark:focus-visible:ring-yellow-500',
        ],
      },
    },
    defaultVariants: {
      intent: 'secondary',
    },
  }
);

const closeIconStyles = cva(['h-3.5', 'w-3.5', 'focus-visible:ring-none'], {
  variants: {
    intent: {
      primary: [
        'stroke-ew-primary-600/50',
        'dark:stroke-ew-primary-200/50',
        'group-hover:stroke-ew-primary-600/75',
        'dark:group-hover:stroke-ew-primary-300/75',
      ],
      secondary: [
        'stroke-gray-600/50',
        'dark:stroke-gray-300/50',
        'group-hover:stroke-gray-600/75',
        'dark:group-hover:stroke-gray-300/75',
      ],
      success: [
        'stroke-green-700/50',
        'dark:stroke-green-300/50',
        'group-hover:stroke-green-700/75',
        'dark:group-hover:stroke-green-300/75',
      ],
      warning: [
        'stroke-yellow-700/50',
        'dark:stroke-yellow-300/50',
        'group-over:stroke-yellow-700/75',
        'dark:group-over:stroke-yellow-300/75',
      ],
      danger: [
        'stroke-red-600/50',
        'dark:stroke-red-300/50',
        'group-hover:stroke-red-600/75',
        'dark:group-hover:stroke-red-300/75',
      ],
    },
  },
});

export { badgeStyles, dotStyles, removeButtonStyles, closeIconStyles };
