import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type Amounts = Record<string, string>;

type AmountsByAccount = Record<string, Amounts>;

type AmountState = {
  amounts: AmountsByAccount;
  setAmount: (account: string, amount: Amounts) => void;
};

// Data structure
// const accountAmounts = {
//   main: {
//     '0-inflow': '0',
//     '0-outflow': '5309.00',
//     '1-inflow': '23',
//     '1-outflow': '5309.00',
//   },
//   'second-account': {
//     '0-inflow': '12',
//     '0-outflow': '232',
//   },
// };

const useAmountStore = create<AmountState>()(
  devtools(
    (set) => ({
      amounts: {},
      setAmount: (account, amount) =>
        set((state) => {
          if (state.amounts[account]) {
            return {
              amounts: {
                ...state.amounts,
                [account]: {
                  ...state.amounts[account],
                  ...amount,
                },
              },
            };
          } else {
            return {
              amounts: {
                ...state.amounts,
                [account]: {
                  ...amount,
                },
              },
            };
          }
        }),
    }),
    { name: 'amount store' }
  )
);

export { useAmountStore };
