import React, { FC, useState, useMemo } from 'react';
import * as _ from 'lodash-es';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import { match } from 'ts-pattern';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  FilterFn,
  getFilteredRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import { FaSistrix } from 'react-icons/fa';
import * as C from './Transactions.constants';
import * as T from './Transactions.types';
import { fuzzyFilter } from './Transactions.utils';
import { cx, UTCToLocalTime } from '../../utils';
import * as Accounts from '../Account';
import {
  notesColumn,
  categoryColumn,
  dateColumn,
  payeeColumn,
  getAmountColumn,
  approvedColumn,
} from './column-fields';
import { Checkbox, Box, Button, Divider, Input } from '../Common';
import { TableRow, TablePagination, TableMobileRow } from './components';
import moment from 'moment/moment';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const TransactionsTable: FC<T.TransactionsTableProps> = ({
  accounts,
  transactions,
  transactionsCount,
  payees,
  categoryGroups,
  accountId,
  budgetId,
  onTransactionChange,
  onTransactionDelete,
  onEntryChange,
  onPayeeCreate,
  settings,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const { setTransactionsPaginationState, transactionsPaginationState } =
    Accounts.S.useAccountStore((state) => state);

  const transactionsAreFetching = useIsFetching({
    queryKey: [C.TRANSACTIONS_QUERY_KEY, accountId, budgetId],
  });
  const transactionsAreLoading = transactionsAreFetching > 0;

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'date', desc: false },
  ]);

  const [globalFilter, setGlobalFilter] = useState('');
  const [rowSelection, setRowSelection] = useState({});
  const [rowFocus, setRowFocus] = useState({
    // to debug focused uncomment these lines below
    // '0': true,
    // '1': true,
    // '2': true,
  });

  const isMutating = useIsMutating({
    mutationKey: [C.DELETE_QUERY_KEY, accountId, budgetId],
  });
  const isDeletingTransactions = isMutating > 0;

  const columns = useMemo<ColumnDef<T.TableTransaction>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <Checkbox
              className={cx(['block', '-mb-2'])}
              checked={table.getIsAllRowsSelected()}
              onCheckedChange={(checked) => {
                // TODO:FIX
                // @ts-ignore
                table.toggleAllRowsSelected(checked);
              }}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <Checkbox
              className={cx(['block', '-mb-2'])}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              onCheckedChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      {
        accessorKey: 'date',
        // TODO:FIX
        // @ts-ignore
        header: 'Date',
        // TODO:FIX
        // @ts-ignore
        filterFn: 'fuzzy',

        // TODO: fix
        // @ts-ignore
        accessorFn: (row): string => {
          const date = row.date;
          const UTCDate = moment(String(date)).toString();
          return UTCToLocalTime(UTCDate, settings?.date_format);
        },

        // @ts-ignore
        sortingFn: (rowA, rowB, columnId, desc) => {
          // We grab the time of the created column, so we can sort transactions
          // of the same date and keep the order even if the day changes.
          // This prevents a glitching when updating transactions of the same date.
          const rowAValue = rowA.original.date;
          const rowBValue = rowB.original.date;
          const rowACreated = rowA.original.created;
          const rowBCreated = rowB.original.created;

          const rowACreatedTime = rowACreated.split('T')[1];
          const rowBCreatedTime = rowBCreated.split('T')[1];

          const a = new Date(rowAValue + 'T' + rowACreatedTime).getTime();
          const b = new Date(rowBValue + 'T' + rowBCreatedTime).getTime();

          return b > a ? 1 : -1;
        },
        ...dateColumn,
      },
      {
        accessorKey: 'payee',
        header: 'Payee',
        ...payeeColumn,
        accessorFn: (row) => {
          if (row.internal_type === 'TRANSFER') {
            return 'Transfer';
          }

          return row.payee?.name;
        },
      },
      {
        accessorKey: 'category',
        header: 'Category',
        ...categoryColumn,

        accessorFn: (row) => {
          // return row.category?.name;
          return row.category?.name;
        },
      },
      {
        accessorKey: 'memo',
        // TODO: FIX
        // @ts-ignore
        header: 'Notes',
        ...notesColumn('memo'),
      },
      {
        accessorKey: 'outflow',
        header: 'Outflow',
        ...getAmountColumn(C.TYPES.OUTFLOW),
      },
      {
        accessorKey: 'inflow',
        header: 'Inflow',
        ...getAmountColumn(C.TYPES.INFLOW),
      },
      {
        accessorKey: 'approved',
        // TODO: FIX
        // @ts-ignore
        header: 'Cleared',
        ...approvedColumn,
      },
    ],
    []
  );

  const pagination = useMemo(() => {
    return {
      pageIndex: transactionsPaginationState.pageIndex,
      pageSize: transactionsPaginationState.pageSize,
    };
  }, [
    transactionsPaginationState.pageIndex,
    transactionsPaginationState.pageSize,
  ]);

  const table = useReactTable({
    // TODO: FIX
    // @ts-ignore
    data: transactions,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      rowSelection,
      globalFilter,
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    enableSorting: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,

    // global filter.
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,

    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    // Pagination
    manualPagination: true,
    pageCount: Math.ceil(transactionsCount / C.PAGINATION_LIMIT),
    onPaginationChange: (paginationState) => {
      // TODO: FIX
      // @ts-ignore
      const newState = paginationState(transactionsPaginationState);
      setTransactionsPaginationState(newState);
    },
    // getPaginationRowModel: getPaginationRowModel(),

    getRowId: (row) => row.id,
    // debugTable: true,
    // debugRows: true,
    // debugColumns: true,
    // TODO: FIX
    // @ts-ignore
    meta: {
      accounts,
      settings,
      accountId,
      budgetId,
      payees,
      categoryGroups,
      rowFocus,
      onTransactionChange(rowIndex, columnId, value) {
        onTransactionChange?.(value);
      },
      onEntryChange(rowIndex, columnId, value) {
        onEntryChange?.(value);
      },
      onPayeeCreate(newPayee, transactionId, callback) {
        invariant(
          !_.isUndefined(transactionId),
          'Transaction id is required in `table.meta.onPayeeCreate` to patch transaction with new payee'
        );

        onPayeeCreate?.(newPayee, transactionId, callback);
      },
    },
  });

  const selectedRows = Object.keys(rowSelection)?.length ?? 0;

  const handleTransactionDelete = () => {
    const payload = Object.keys(rowSelection);
    onTransactionDelete?.(payload, {
      onSuccess: () => {
        table.setRowSelection({});
        setShowSelect(false);
      },
    });
  };

  return (
    <>
      <Box className={cx(['flex', 'flex-row', 'items-center'])}>
        <Box className={cx('flex', 'items-center', 'ml-auto', 'space-x-3')}>
          <Box className={cx(['flex', 'flex-row', 'space-x-1'])}>
            <Button
              intent="ghost"
              onClick={() => {
                setShowSelect(!showSelect);
              }}
            >
              Select
            </Button>
            {match(selectedRows)
              .with(0, (num) => null)
              .with(1, (num) => (
                <Button
                  onClick={handleTransactionDelete}
                  intent="danger"
                  isLoading={isDeletingTransactions}
                >
                  Delete {num} transaction
                </Button>
              ))
              .otherwise((num) => (
                <Button
                  onClick={handleTransactionDelete}
                  intent="danger"
                  isLoading={isDeletingTransactions}
                >
                  Delete {num} transactions
                </Button>
              ))}
          </Box>
          <Input
            label="Search all transactions"
            type="text"
            hideLabel
            value={globalFilter ?? ''}
            onChange={(event) => {
              const value = event.target?.value;
              setGlobalFilter(String(value));
            }}
            placeholder="Search all transactions"
            icon={FaSistrix}
            withClearIcon
            onClear={(event) => {
              setGlobalFilter('');
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        className={cx([
          'min-w-full',
          'rounded-xl',
          'overflow-visible',
          'align-middle',
          'shadow',
          'sm:rounded-lg',
          'border',
          'border-gray-200',
          'dark:border-gray-700',
        ])}
      >
        <table
          className={cx([
            // 'min-w-full',
            // 'divide-y',
            // 'divide-gray-200',
            // 'dark:divide-gray-700',

            'rounded-xl',
            'min-w-full',
            'divide-y',
            'divide-gray-200',
            'dark:divide-gray-700',
          ])}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr
                  className={cx([
                    'whitespace-nowrap',
                    'px-2',
                    'py-3.5',
                    'text-left',
                    'font-semibold',
                    'text-gray-900',
                  ])}
                  key={headerGroup.id}
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        scope="col"
                        key={header.id}
                        className={cx(
                          [
                            'hidden',
                            'lg:table-cell',
                            // 'bg-gray-50',
                            // 'dark:bg-ew-bg-dark',
                            // 'px-3',
                            // 'py-2',
                            // 'text-left',
                            // 'text-sm',
                            // 'font-normal',
                            // 'text-gray-500',
                            // 'dark:text-ew-text-dark',

                            'dark:bg-ew-bg-dark',
                            'px-3',
                            'py-2',
                            'text-left',
                            'text-xs',
                            'font-normal',
                            'text-zinc-600',
                            'dark:text-ew-text-dark',
                            'uppercase',
                            'font-semibold',
                          ],
                          {
                            'text-right':
                              header.id === 'inflow' || header.id === 'outflow',
                            'text-center': header.id === 'approved',
                            'table-cell':
                              header.id === 'date' || header.id === 'memo',
                            'lg:hidden': header.id === 'select' && !showSelect,
                          }
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody
            className={cx([
              'divide-y',
              'divide-gray-200',
              'bg-white',
              'dark:bg-ew-bg-dark',
              'dark:divide-gray-700',
            ])}
          >
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  rowFocus={rowFocus}
                  setRowFocus={setRowFocus}
                  row={row}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => {
                    const mobileRowId = `${row.id}-mobile`;
                    return (
                      <>
                        <TableMobileRow
                          columnId={cell.column.id}
                          rowObject={row.original}
                          key={mobileRowId}
                        />
                        <td
                          className={cx(
                            [
                              'hidden',
                              'max-w-0',
                              'py-1',
                              'pl-4',
                              'pr-3',
                              // 'font-medium',
                              'text-gray-900',
                              'md:w-auto',
                              'md:max-w-none',
                              // 'md:pl-0',
                              'lg:table-cell',
                              // 'px-3',
                              // 'py-4',
                              // 'text-sm',
                              // 'text-gray-900',
                              // 'lg:table-cell',
                              // 'whitespace-nowrap',
                              // 'px-3',
                              // 'py-1',
                              // 'text-gray-900',
                              // 'dark:text-ew-text-dark',
                            ],
                            {
                              'text-center': cell.column.id === 'approved',
                              'max-w-xs': cell.column.id === 'memo',
                              'hidden lg:table-cell':
                                cell.column.id === 'memo' ||
                                cell.column.id === 'select',
                              'font-mono':
                                cell.column.id === 'amount' ||
                                cell.column.id === 'inflow' ||
                                cell.column.id === 'outflow',
                              'lg:hidden':
                                cell.column.id === 'select' && !showSelect,
                            }
                          )}
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      </>
                    );
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </table>
      </Box>
      {transactionsCount > 20 ? (
        <TablePagination
          isLoading={transactionsAreLoading}
          itemsCount={transactionsCount}
          pageIndex={table.getState().pagination.pageIndex}
          pageCount={table.getPageCount()}
          goToPreviousPage={table.previousPage}
          canGoToPreviousPage={table.getCanPreviousPage()}
          goToNextPage={table.nextPage}
          canGoToNextPage={table.getCanNextPage()}
        />
      ) : null}
    </>
  );
};

export { TransactionsTable };
