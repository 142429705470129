import * as React from 'react';
import * as _ from 'lodash-es';
import moment from 'moment';
import toast from 'react-hot-toast';
import invariant from 'tiny-invariant';
import { match, P } from 'ts-pattern';
import { useQueryClient } from '@tanstack/react-query';
import { useHotkeys, HotkeysProvider } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FaChevronLeft,
  FaChevronRight,
  FaFolderOpen,
  FaCog,
  FaPlus,
} from 'react-icons/fa';
import { FaCopy, FaTrash, FaFileExport, FaFileImport } from 'react-icons/fa';
import * as Settings from '../Settings';
import * as Transactions from '../Transactions';
import * as Accounts from '../Account';
import * as Categories from '../Categories';
import * as CategoryGroups from '../CategoryGroups';
import * as Budgets from '../Budget';
import * as C from './Budget.constants';
import * as T from './Budget.types';
import { BudgetTable } from './Budget.table';
import * as BudgetImport from './components/ImportBudget';
import {
  Box,
  Button,
  Dropdown,
  Divider,
  Empty,
  IconButton,
  Layout,
  Modal,
  Money,
  DropdownItem,
  CSV,
} from '../Common';
import { cx, getPeriodId } from '../../utils';
import { ACCOUNT_TOTALS_KEY } from '../Account/Account.constants';
import { addAmountsToGroup } from './Budget.utils';
import { BudgetForm } from './Budget.form.tsx';
import { usePostBudget } from './Budget.hooks.ts';
import { BudgetFormInputs } from './Budget.types';

type BudgetBoxProps = {
  title: string;
  amount: string | number;
  note: React.ReactChild;
  borderless?: boolean;
};

type BudgetBoxComponent = (props: BudgetBoxProps) => React.ReactElement | null;

const BudgetBox: BudgetBoxComponent = ({
  title,
  amount,
  note,
  borderless = false,
}) => {
  return (
    <Box
      className={cx(['text-left', 'px-4', 'py-2', 'sticky', 'top-0'], {
        'border-r border-r-zinc-300 dark:border-r-gray-600': !borderless,
      })}
    >
      <p
        className={cx([
          'uppercase',
          'font-bold',
          'text-zinc-500',
          'dark:text-ew-primary-dark-400',
        ])}
      >
        {title}
      </p>
      <Divider size="sm" />

      <p>
        <span
          className={cx(['text-2xl', 'text-zinc-900', 'dark:text-zinc-200'])}
        >
          <Money value={amount} />
        </span>
      </p>
      <Divider size={'sm'} />
      <p className={cx(['text-base', 'text-zinc-600', 'dark:text-zinc-400'])}>
        {note}
      </p>
    </Box>
  );
};

const BudgetPage = () => {
  const [copyModalIsOpen, setCopyModalIsOpen] = React.useState(false);
  const [clearModalIsOpen, setClearModalIsOpen] = React.useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = React.useState(false);
  const [num, setNum] = React.useState(0);
  const [csvLoading, setCsvLoading] = React.useState(false);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { budgetId, periodId } = useParams();
  invariant(budgetId, 'No budget ID found in URL.');
  invariant(periodId, 'No period ID found in URL.');

  const [budgetFormIsOpen, setBudgetFormIsOpen] = React.useState(false);
  const [categoryFormIsOpen, setCategoryFormIsOpen] = React.useState(false);
  const [categoryGroupFormIsOpen, setCategoryGroupFormIsOpen] =
    React.useState(false);
  const [latestCategory, setLatestCategory] = React.useState('');

  useHotkeys(
    'n',
    () => {
      setCategoryFormIsOpen(true);
    },
    {
      scopes: ['budget'],
    }
  );

  // This block figures out the previous and next periods.
  // Also uses that information to create the previous link, next link and the label.
  const { previous, next } = getPeriodId(periodId);
  const baseTarget = '/u/b/' + budgetId + '/';
  const previousTarget = baseTarget + previous;
  const nextTarget = baseTarget + next;
  const label = moment(periodId).format('MMMM YYYY');

  // Labels
  const previousMonth = moment(previous).format('MMMM');
  const currentMonth = moment(periodId).format('MMMM');

  // Category groups
  const groupsQueryKey = [CategoryGroups.C.QUERY_KEY, budgetId];
  const groups = CategoryGroups.H.useGetCategoryGroups(budgetId);

  // Budgets
  const copyFromLastMonth = Budgets.H.useCopyFromLastMonth(
    budgetId,
    previous,
    periodId
  );

  const clearCurrentMonth = Budgets.H.useClearCurrentMonth(budgetId, periodId);

  // Budget
  const postBudget = usePostBudget(budgetId);

  // Categories
  // const categories = Categories.H.useGetCategories(budgetId);
  const postCategory = Categories.H.usePostCategory(budgetId);

  const patchCategory = Categories.H.usePatchCategory();

  // Category groups
  const postCategoryGroup = CategoryGroups.H.usePostCategoryGroup(budgetId);

  // Settings
  const settings = Settings.Hooks.useGetSettings(budgetId);

  // Accounts
  const internalAccounts = Accounts.H.useGetInternalAccounts(budgetId);

  const leftToBudget = internalAccounts.data?.find((account) => {
    return account._as === 'LEFT_TO_BUDGET';
  });

  const importBudget = BudgetImport.H.useImportBudget(budgetId, periodId);

  /**
   * Transactions
   */

  /** Mutations */
  // Update budgeted amounts.
  const upsertTransaction = Transactions.H.useUpsertTransaction(
    String(leftToBudget?.id), // Account we need to clear cache on when this mutation happens
    budgetId,
    periodId,
    settings.data?.[0].budgeting_style ?? C.BUDGETING_STYLES.GLOBAL_LTB
  );

  /** Queries */
  // [x] Forward from last month (accountTotals of leftToBudget with previous periodId)
  const forwardFromLastMonth = Accounts.H.useGetAccountTotals(
    String(leftToBudget?.id),
    budgetId,
    previous
  );

  /** Calculations */
  // [x] Income for current month (txns of category income with previous periodId)
  const incomeTransactionsForThisMonth =
    Transactions.H.useGetTransactionsByPeriod(budgetId, periodId, {
      category_id: String(leftToBudget?.id),
      internal_type: 'INCOME',
      approved: true,
      limit: 10000,
    });

  const incomeForCurrentMonth = _.sum(
    incomeTransactionsForThisMonth.data?.results?.map((txn) =>
      Number(txn.entries?.[0]?.amount)
    )
  );

  // [x] Budgeted in current month (balance of all categories with first of this month and end of this month by a given periodId)
  // for invalidations in this file.
  const budgetedForTheMonthQueryKey = [
    Transactions.C.TRANSACTIONS_QUERY_KEY,
    budgetId,
    periodId,
    {
      account_id: String(leftToBudget?.id),
      internal_type: 'BUDGET',
      approved: true,
    },
  ];
  // HERE
  const budgetedTransactionsForTheMonth =
    Transactions.H.useGetTransactionsByPeriod(budgetId, periodId, {
      account_id: String(leftToBudget?.id),
      internal_type: 'BUDGET',
      approved: true,
      limit: 10000,
    });

  const budgetedForTheMonth = _.sum(
    budgetedTransactionsForTheMonth.data?.results?.map((txn) =>
      Number(txn.entries?.[0]?.amount ?? '0.00')
    )
  );

  // [x] Budgeted ahead (balance of all categories with first of next month and end of next month by a given periodId)
  // [x] ~TODO: This is wrong, it should be from last of this month to infinity in the future.~
  const budgetedTransactionsForNextMonth =
    Transactions.H.useGetTransactionsByPeriod(budgetId, next, {
      account_id: String(leftToBudget?.id),
      internal_type: 'BUDGET',
      balance_type: 'AHEAD',
    });

  const budgetedAhead = _.sum(
    budgetedTransactionsForNextMonth.data?.results?.map((txn) =>
      Number(txn.entries?.[0].amount)
    )
  );

  // [x] Left to budget (left to budget balance from 1900-01-01 to last day of given periodId)
  // const queryKey = [
  //   ACCOUNT_TOTALS_KEY,
  //   accountId,
  //   budgetId,
  //   periodId,
  //   budgetingStyle,
  // ];
  // For invalidations in this file.
  const periodIdForLeftToBudget =
    settings.data?.[0].budgeting_style !== 'GLOBAL_LTB' ? periodId : '';

  // We are duplicating this constant in the Budget.table.tsx file
  const leftToBudgetQueryKey = [
    Accounts.C.ACCOUNT_TOTALS_KEY,
    String(leftToBudget?.id),
    budgetId,
    periodIdForLeftToBudget,
    settings.data?.[0].budgeting_style,
  ];
  const leftToBudgetTotals = Accounts.H.useGetAccountTotals(
    String(leftToBudget?.id),
    budgetId,
    periodIdForLeftToBudget,
    settings.data?.[0].budgeting_style
  );

  const firstOfMonth = moment(periodId).startOf('month').format('YYYY-MM-DD');
  // TODO: Add `approved: true` param
  const leftToBudgetTransactionsQueryKey = [
    Transactions.C.TRANSACTIONS_QUERY_KEY,
    String(leftToBudget?.id),
    budgetId,
    firstOfMonth,
    { limit: 1000 },
  ];
  const leftToBudgetTransactions = Transactions.H.useGetTransactionsByDate(
    String(leftToBudget?.id),
    budgetId,
    firstOfMonth,
    {
      limit: 1000,
    }
  );

  const handlePostBudget = (payload: BudgetFormInputs) => {
    postBudget.mutate(payload, {
      onSuccess: (res) => {
        setBudgetFormIsOpen(false);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [C.BUDGET_QUERY_KEY],
        });
      },
    });
  };

  // Callback for the inline category group creation in the combobox.
  const handlePostCategoryGroup = ({
    name,
  }: CategoryGroups.T.CategoryGroupFormInputs) => {
    const newCategoryGroup = {
      name,
      budget_id: budgetId,
    };

    postCategoryGroup.mutate(newCategoryGroup, {
      onSuccess: (res) => {
        setCategoryGroupFormIsOpen(false);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [CategoryGroups.C.QUERY_KEY, budgetId],
        });
      },
    });
  };

  // Callback for the create-category modal.
  const handlePostCategory = (category: Categories.T.CategoryFormInputs) => {
    const newCategory: Categories.T.CategoryPost = {
      ...category,
      category_group_id: String(category.category_group),
      type: 'INTERNAL',
      internal_type: 'LIABILITY',
      _as: 'CATEGORY',
      budget_id: budgetId,
    };
    postCategory.mutate(newCategory, {
      onSuccess: (res) => {
        setCategoryFormIsOpen(false);
        setLatestCategory(category.name);
      },
      onError: (err) => {
        setLatestCategory('');
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [CategoryGroups.C.QUERY_KEY, budgetId],
        });
      },
    });
  };

  const handleCategoryUpdate = (category: Categories.T.CategoryPatch) => {
    patchCategory.mutate(category, {
      onSuccess: (res) => {
        // console.log('res', res);
      },
      onError: (err) => {
        // console.log('err', err);
      },
    });
  };

  type HandleBudgetedAmountUpdate = (args: T.BudgetedAmountUpdate) => void;
  const handleBudgetedAmountUpdate: HandleBudgetedAmountUpdate = ({
    categoryId,
    amount,
  }) => {
    // @ts-ignore
    const transactionToUpsert: Transactions.T.TransactionUpdate = {
      date: firstOfMonth,
      account_id: leftToBudget?.id, // left to budget
      category_id: categoryId, // category
      budget_id: budgetId,
      approved: true,
      type: Transactions.C.TYPES.OUTFLOW, // INFLOW = DEBIT to LTB account, CREDIT to category
      // @ts-ignore
      source: Transactions.C.SOURCES.WEB_MANUAL,
      // @ts-ignore
      internal_type: Transactions.C.INTERNAL_TYPES.BUDGET,
      amount,
    };

    const remainingQueryKey = [
      ACCOUNT_TOTALS_KEY,
      categoryId, // local scope
      budgetId,
      periodId,
      // budgetingStyle, // budgetingStyle,
      // settings.data?.[0].budgeting_style,
    ];

    upsertTransaction.mutate(transactionToUpsert, {
      onSuccess: async (res) => {
        // - [x] invalidate LTB
        await queryClient.invalidateQueries({ queryKey: leftToBudgetQueryKey });

        // - [x] invalidate Remaining
        await queryClient.invalidateQueries({
          queryKey: remainingQueryKey,
        });

        // - [x] invalidate Budgeted this month
        await queryClient.invalidateQueries({
          queryKey: budgetedForTheMonthQueryKey,
        });
      },
      // onError: (err) => {
      // },
      // onSettled: async () => {
      //
      // }
    });
  };

  const handleClearCurrentMonth = () => {
    const toastId = toast.loading('Clearing current month...');
    clearCurrentMonth.mutate(undefined, {
      onSuccess: async () => {
        toast.success('Cleared current month!', { id: toastId });
        setClearModalIsOpen(false);
      },
      onError: (err) => {
        toast.error('Failed to clear current month.', { id: toastId });
      },

      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: leftToBudgetQueryKey });
        await queryClient.invalidateQueries({
          queryKey: budgetedForTheMonthQueryKey,
        });
        await queryClient.invalidateQueries({ queryKey: groupsQueryKey });

        await queryClient.invalidateQueries({
          queryKey: budgetedForTheMonthQueryKey,
        });

        // Re-fetch budgeted amounts.
        await queryClient.invalidateQueries({
          queryKey: leftToBudgetTransactionsQueryKey,
        });
        // setNum((prev) => prev + 1);
      },
    });
  };

  const handleCopyFromLastMonth = () => {
    const toastId = toast.loading('Copying from last month...');
    copyFromLastMonth.mutate(undefined, {
      onSuccess: async () => {
        toast.success('Copied from last month!', { id: toastId });
        setCopyModalIsOpen(false);
      },
      onError: (err) => {
        toast.error('Failed to copy from last month.', { id: toastId });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: leftToBudgetQueryKey });
        await queryClient.invalidateQueries({
          queryKey: budgetedForTheMonthQueryKey,
        });
        await queryClient.invalidateQueries({ queryKey: groupsQueryKey });

        await queryClient.invalidateQueries({
          queryKey: budgetedForTheMonthQueryKey,
        });

        // Re-fetch budgeted amounts.
        await queryClient.invalidateQueries({
          queryKey: leftToBudgetTransactionsQueryKey,
        });
        // setNum((prev) => prev + 1);
      },
    });
  };

  // CSV download logic
  const handleImportBudget = (data: T.BudgetExport[]) => {
    const toastId = toast.loading('Importing budget...');
    importBudget.mutate(data, {
      onSuccess: async (res) => {
        toast.success('Budget imported!', { id: toastId });
        setImportModalIsOpen(false);
      },
      onError: (err) => {
        toast.error('Failed to import budget.', { id: toastId });
        // console.log('error', err);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: leftToBudgetQueryKey });
        await queryClient.invalidateQueries({
          queryKey: budgetedForTheMonthQueryKey,
        });
        await queryClient.invalidateQueries({ queryKey: groupsQueryKey });

        await queryClient.invalidateQueries({
          queryKey: budgetedForTheMonthQueryKey,
        });

        // Re-fetch budgeted amounts.
        await queryClient.invalidateQueries({
          queryKey: leftToBudgetTransactionsQueryKey,
        });
      },
    });
  };

  const handleExportBudget = (periodId: string) => {
    if (groups?.data) {
      const groupsWithAmount = addAmountsToGroup(
        groups.data,
        leftToBudgetTransactions.data?.results ?? []
      ).filter((group) => group?.accounts.length >= 1);

      const csv = CSV.utils.groupsToBudgetExport(groupsWithAmount);
      CSV.utils.downloadCsv(csv, periodId);
      setCsvLoading(false);
    }
  };

  const budgetOptions: DropdownItem[] = [
    {
      icon: FaCopy,
      label: 'Copy from last month',
      onSelect: () => setCopyModalIsOpen(true),
    },
    {
      icon: FaTrash,
      label: 'Clear current month',
      onSelect: () => setClearModalIsOpen(true),
    },
    {
      icon: FaFileImport,
      label: 'Import from CSV',
      onSelect: () => {
        setImportModalIsOpen(true);
      },
    },
    {
      icon: FaFileExport,
      label: csvLoading ? 'Generating CSV...' : 'Export to CSV',
      onSelect: () => {
        setCsvLoading(true);
        handleExportBudget(periodId);
      },
    },
  ];

  // const creditCardGroups = groups.data?.find(
  //   (group) => group.name === C.CC_GROUP_NAME
  // );

  return (
    <HotkeysProvider initiallyActiveScopes={['budget']}>
      <Layout
        title="Budget"
        actionsCenter={
          <Box
            className={cx([
              'flex',
              'justify-center',
              'text-xl',
              'text-gray-700',
            ])}
          >
            <Box className={cx(['flex', 'items-center', 'space-x-3'])}>
              <IconButton
                onClick={() => navigate(previousTarget)}
                className={cx(['bg-zinc-200'])}
                iconClasses={cx(['text-sm'])}
                label={'Previous period'}
                icon={FaChevronLeft}
              />
              <p className={cx('font-base')}>{label}</p>
              <IconButton
                onClick={() => navigate(nextTarget)}
                className={cx(['bg-zinc-200'])}
                iconClasses={cx(['text-sm'])}
                label="Next period"
                icon={FaChevronRight}
              />
            </Box>
          </Box>
        }
        actionsEnd={
          <Box
            className={cx([
              'flex',
              'flex-row',
              'items-center',
              'justify-end',
              'space-x-2',
            ])}
          >
            <Modal
              open={budgetFormIsOpen}
              setOpen={setBudgetFormIsOpen}
              trigger={
                <Button
                  size={'sm'}
                  intent="secondary"
                  icon={FaPlus}
                  aria-label="Create a new Category Group"
                >
                  Budget
                </Button>
              }
            >
              <Box className={cx(['bg-white', 'rounded-md'])}>
                <BudgetForm
                  onSubmit={handlePostBudget}
                  onCancel={() => setBudgetFormIsOpen(false)}
                  isLoading={postBudget.isPending}
                />
              </Box>
            </Modal>
            <Divider orientation={'horizontal'} size="sm" />
            <Modal
              open={categoryGroupFormIsOpen}
              setOpen={setCategoryGroupFormIsOpen}
              trigger={
                <Button
                  size={'sm'}
                  intent="secondary"
                  icon={FaPlus}
                  aria-label="Create a new Category Group"
                >
                  Group
                </Button>
              }
            >
              <Box className={cx(['bg-white', 'rounded-md'])}>
                <CategoryGroups.Form
                  onSubmit={handlePostCategoryGroup}
                  onCancel={() => setCategoryGroupFormIsOpen(false)}
                  isLoading={postCategoryGroup.isPending}
                />
              </Box>
            </Modal>
            <Divider orientation={'horizontal'} size="sm" />
            <Modal
              open={categoryFormIsOpen}
              setOpen={setCategoryFormIsOpen}
              trigger={
                <Button
                  size={'sm'}
                  intent="primary"
                  icon={FaPlus}
                  aria-label="Create a new Category"
                >
                  Category
                </Button>
              }
            >
              <Box className={cx(['bg-white', 'rounded-md'])}>
                <Categories.Form
                  onSubmit={handlePostCategory}
                  onCancel={() => setCategoryFormIsOpen(false)}
                  categoryGroups={groups.data}
                  isLoading={
                    postCategoryGroup.isPending || postCategory.isPending
                  }
                />
              </Box>
            </Modal>
          </Box>
        }
      >
        <Box>
          <Box className={cx(['p-4', 'sticky', 'top-0', 'z-20'])}>
            <Box
              className={cx([
                'bg-white',
                'border',
                'border-b-zinc-300',
                'dark:border-0',
                'dark:bg-ew-bg-dark-600',
                'rounded-xl',
                'grid',
                'grid-cols-3',
                // 'gap-x-4',
              ])}
            >
              <BudgetBox
                title={'Income'}
                amount={incomeForCurrentMonth}
                note={
                  <>
                    +{' '}
                    <Money
                      value={String(forwardFromLastMonth?.data?.approved)}
                    />{' '}
                    (from {previousMonth})
                  </>
                }
              />
              <BudgetBox
                title={'Budgeted'}
                amount={budgetedForTheMonth}
                note={
                  <>
                    + <Money value={budgetedAhead} /> (ahead)
                  </>
                }
              />
              <BudgetBox
                title={'Left to Budget'}
                amount={leftToBudgetTotals?.data?.approved ?? '0.00'}
                note={
                  <>
                    {settings?.data?.[0].budgeting_style === 'GLOBAL_LTB'
                      ? 'Global LTB'
                      : 'Walled-Off Months'}
                  </>
                }
                borderless
              />
            </Box>
          </Box>
          <Box className={cx(['px-4', 'pt-2', 'pb-4', 'border-zinc-300'])}>
            <Box className={cx(['flex', 'w-full', 'mb-2'])}>
              <Box className={cx(['ml-auto', 'space-x-2'])}>
                <Modal
                  open={importModalIsOpen}
                  setOpen={setImportModalIsOpen}
                  className={cx(['w-1/2'])}
                >
                  <Box>
                    <BudgetImport.Form
                      isLoading={importBudget.isPending}
                      onCancel={() => setImportModalIsOpen(false)}
                      onSubmit={(data) => {
                        handleImportBudget(data);
                      }}
                    />
                  </Box>
                </Modal>
                <Modal
                  open={copyModalIsOpen}
                  setOpen={setCopyModalIsOpen}
                  // trigger={
                  //   <Button intent="primary" size="sm">
                  //     Copy from last month
                  //   </Button>
                  // }
                >
                  <Box>
                    <Divider size={'lg'} />
                    <Box>
                      <p className={cx(['lead'])}>
                        Please confirm you want to copy the budgeted amounts
                        from last month.
                      </p>
                      <Divider />
                      <p className={cx(['italic'])}>
                        Any amount bigger than 0.00 won't be updated.
                      </p>
                    </Box>
                    <Divider />
                    <Box className={cx(['flex', 'justify-end'])}>
                      <Button
                        isLoading={copyFromLastMonth.isPending}
                        intent="primary"
                        onClick={() => handleCopyFromLastMonth()}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                <Modal
                  open={clearModalIsOpen}
                  setOpen={setClearModalIsOpen}
                  // trigger={<Button size="sm">Clear</Button>}
                >
                  <Box>
                    <Divider size={'lg'} />
                    <Box>
                      <p className={cx(['lead'])}>
                        Please confirm you want to clear the budgeted amounts.
                      </p>
                      <Divider />
                      <p className={cx(['italic'])}>
                        All amounts for this month will be set to 0.00.
                      </p>
                    </Box>
                    <Divider />
                    <Box className={cx(['flex', 'justify-end'])}>
                      <Button
                        isLoading={clearCurrentMonth.isPending}
                        size="sm"
                        onClick={() => handleClearCurrentMonth()}
                      >
                        Clear
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <Dropdown items={budgetOptions}>
                  <Button icon={FaCog} size="sm" isLoading={csvLoading}>
                    Actions
                  </Button>
                </Dropdown>
              </Box>
            </Box>

            {match([groups, settings])
              .with(P.array({ isLoading: true }), () => {
                return <p>Loading...</p>;
              })
              .with(
                [
                  {
                    isLoading: false,
                    isError: false,
                    data: P.when((groups) => {
                      // We are checking groups, and we want to show an empty table
                      // if there are no accounts in any of the groups.
                      // We use a for loop because we can break out of it.
                      let hasAccounts = false;
                      for (let i = 0; i < groups.length; i++) {
                        const group = groups[i];
                        if (!_.isEmpty(group.accounts)) {
                          hasAccounts = true;
                          break;
                        }
                      }
                      return !hasAccounts;
                    }),
                  },
                  {
                    isLoading: false,
                    isError: false,
                  },
                ],
                () => (
                  <Box className={cx([])}>
                    <Empty
                      heading="Manage your categories"
                      content="Create, update, and delete your categories."
                      icon={FaFolderOpen}
                      actionLabel="New Category"
                      action={() => setCategoryFormIsOpen(true)}
                    />
                  </Box>
                )
              )
              .with(
                P.array({
                  isLoading: false,
                  isError: false,
                  data: P.when((data) => !_.isEmpty(data)),
                }),
                ([groups_, settings_]) => {
                  let amounts: number[] = [];

                  const groups =
                    groups_.data as CategoryGroups.T.CategoryGroupBase[];
                  const settings =
                    settings_.data as Settings.Types.SettingsRead[];

                  const groupsWithAmount = addAmountsToGroup(
                    groups,
                    leftToBudgetTransactions.data?.results ?? [],
                    (amount) => {
                      amounts.push(Number(amount));
                    }
                    // Filter out empty groups.
                  ).filter((group) => group?.accounts.length >= 1);

                  let assetGroupWithAmounts: CategoryGroups.T.CategoryGroupGet<Accounts.T.AccountTable>[] =
                    [];
                  let ccGroupWithAmounts: CategoryGroups.T.CategoryGroupBase<Accounts.T.AccountTable>[] =
                    [];

                  groupsWithAmount.forEach((group) => {
                    if (group?.name === C.CC_GROUP_NAME) {
                      ccGroupWithAmounts.push(group);
                    } else {
                      assetGroupWithAmounts.push(group);
                    }
                  });

                  // After calculation the amounts change, but the component doesn't because it caches the component.
                  // If we use the sum of the amounts as a key section we ensure that when the amount changes React will re-rener
                  // the component.
                  const totals = _.sum(amounts);
                  const key = periodId + '-' + totals.toString() + '-' + num;

                  return (
                    <>
                      <BudgetTable
                        onCategoryUpdate={handleCategoryUpdate}
                        latestCategory={latestCategory}
                        key={key}
                        budgetId={budgetId}
                        periodId={periodId}
                        leftToBudgetId={String(leftToBudget?.id)}
                        settings={settings[0]}
                        data={assetGroupWithAmounts}
                        onBudgetedAmountUpdate={handleBudgetedAmountUpdate}
                      />

                      {!_.isEmpty(ccGroupWithAmounts) ? (
                        <>
                          <Divider />

                          <BudgetTable
                            isCC
                            onCategoryUpdate={handleCategoryUpdate}
                            latestCategory={latestCategory}
                            key={key}
                            budgetId={budgetId}
                            periodId={periodId}
                            leftToBudgetId={String(leftToBudget?.id)}
                            settings={settings[0]}
                            data={ccGroupWithAmounts}
                            onBudgetedAmountUpdate={handleBudgetedAmountUpdate}
                          />
                        </>
                      ) : null}
                    </>
                  );
                }
              )
              // No category groups
              .otherwise(() => (
                <p>No Category groups.</p>
              ))}
          </Box>
        </Box>
      </Layout>
    </HotkeysProvider>
  );
};

export { BudgetPage };
