import create from "zustand";
import { devtools } from "zustand/middleware";

interface DividerState {
    foo: string;
    setFoo: (foo: string) => void;
}

const useDividerStore = create<DividerState>()(
    devtools(
        (set) => ({
            foo: '',
            setFoo: (foo) => set((state) => ({ foo })),
        }),
    )
);

export { useDividerStore };
