import * as React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Form, useParams } from 'react-router-dom';

// @ts-ignore
import * as _ from 'lodash-es';

import Papa from 'papaparse';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fad } from '@awesome.me/kit-7767038e99/icons';
import { Box, Divider, Button } from '../Common';
import * as Combobox from '../Common/Combobox';
import { cx } from '../../utils';
import * as Accounts from '../Account';
import * as T from './FileImports.types';
import * as styles from './FileImports.styles';
import invariant from 'tiny-invariant';

const FileImportsForm = React.forwardRef<
  T.FileImportsElement,
  T.FileImportsFormProps
>(
  (
    { isLoading, onCancel, onSubmit, onFileLoad, setIsWrongFormat, ...props },
    forwardRef
  ) => {
    let { budgetId } = useParams();
    invariant(budgetId, 'budgetId is required.');

    const { data: accounts } = useSuspenseQuery(
      Accounts.H.getAccountsQuery(budgetId)
    );

    let formattedAccounts = accounts?.map((account) => {
      return {
        label: account.name,
        value: account.id,
      };
    });

    let [file, setFile] = React.useState<File | null>(null);
    const {
      control,
      register,
      trigger,
      handleSubmit,
      // formState: { errors },
    } = useForm<T.FileImportFormFields>({
      defaultValues: {
        file: null,
        csvData: [],
      },
    });

    let [csvData, setCSVData] = React.useState<T.FileImport[]>([]);

    let handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      let file = event.target.files?.[0];

      if (!_.isUndefined(file)) {
        Papa.parse<T.FileImport>(file, {
          skipEmptyLines: true,
          preview: 0,
          header: true,
          complete(results) {
            setFile(file);
            setCSVData(results.data);

            let amountOfRowsForPreview = 20;
            onFileLoad?.([...results.data.slice(0, amountOfRowsForPreview)]);
            setIsWrongFormat?.(false);
          },
        });
      }
    };

    // @ts-ignore
    let onHandleSubmit = async (data) => {
      let isValid = await trigger();
      let selectedAccount = accounts.find(
        (account) => account.id === data.account
      );

      if (isValid) {
        onSubmit?.({
          file,
          csvData,
          // account: data.account,
          account: selectedAccount,
        });
      }
    };

    return (
      <Box className={cx(['w-full', 'p-2'])}>
        <Form
          method={'post'}
          className={cx([props.className])}
          onSubmit={handleSubmit(onHandleSubmit)}
          ref={forwardRef}
        >
          <Box>
            <Controller
              name="account"
              control={control}
              rules={{
                required: 'Account is required',
              }}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
              }) => {
                return (
                  <Combobox.$
                    // className={cx(['w-full'])}
                    isRequired
                    label="Select account"
                    placeholder={'Select Account'}
                    isInvalid={invalid}
                    errorMessage={error?.message}
                    validationBehavior="aria"
                    {...field}
                    // Controlled state
                    defaultItems={formattedAccounts}
                    onSelectionChange={(key) => {
                      field.onChange(key);
                    }}
                  >
                    {(data: Combobox.T.ComboboxOption) => (
                      <Combobox.Item id={String(data.value)}>
                        {data.label}
                      </Combobox.Item>
                    )}
                  </Combobox.$>
                );
              }}
            />
          </Box>
          <Divider />
          <Box>
            <span
              className={cx([
                'block',
                'text-sm',
                'font-medium',
                'leading-6',
                'text-gray-600',
                'dark:text-white',
              ])}
            >
              <span className="label-text">Select CSV file</span>
            </span>
            <Box
              className={cx([
                'mt-2',
                'flex',
                'items-center',
                'space-x-3',
                'p-2',
                'bg-gray-100',
                'w-full',
                'rounded-md',
              ])}
            >
              <Box
                className={cx([
                  'bg-gray-100',
                  'rounded-3xl',
                  'p-2',
                  'border-2',
                  'border-gray-800',
                ])}
              >
                <FontAwesomeIcon
                  icon={fad.faFileCsv}
                  className={cx(['text-2xl', 'text-gray-800'])}
                />
              </Box>
              <Box>
                <label>
                  <span className={cx(['sr-only'])}>Select a CSV file</span>
                  <input
                    {...register('file', {
                      required: true,
                    })}
                    onChange={handleFile}
                    className={cx(styles.base, styles.primary, [
                      'block',
                      'w-full',
                      'text-sm',
                      'text-slate-800',
                      'font-semibold',
                    ])}
                    type="file"
                  />
                </label>
              </Box>
            </Box>
          </Box>
          {props.children ? (
            <>
              <Divider />
              {props.children}
            </>
          ) : null}
          <Divider size="xl" />
          <Box className={cx(['flex', 'justify-end', 'space-x-3'])}>
            <Button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
                setFile(null);
                setCSVData([]);
                onCancel?.();
              }}
            >
              Cancel
            </Button>
            <Button isLoading={isLoading} intent="primary">
              Import
            </Button>
          </Box>
        </Form>
      </Box>
    );
  }
);

export { FileImportsForm };
