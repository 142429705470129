import React, { PropsWithChildren, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { match } from 'ts-pattern';
import * as Users from '../User';

type IsAuthorizedComponent = (
  props: PropsWithChildren<{}>
) => ReactElement | null;

// Only redirect when authentication fails. Loading or idle state are okay.
const IsAuthorized: IsAuthorizedComponent = ({ children }) => {
  const user = Users.Hooks.useGetUser();
  const location = useLocation();

  return match([user])
    .with([{ status: 'error' }], () => (
      <Navigate to="/sign-in" state={{ from: location }} />
    ))
    .otherwise(() => children as ReactElement);
};

export { IsAuthorized };
