import * as React from 'react';
import * as _ from 'lodash-es';
import * as Transactions from '../../Transactions';
import * as Settings from '../../Settings';
import * as Accounts from '../../Account';
import { Divider } from '../Divider';
import { Box } from '../Box';
import { Money } from '../Money';
import { cx, UTCToLocalTime } from '../../../utils';
import moment from 'moment/moment';

type ActivityTableProps = React.PropsWithChildren<{
  isCC?: boolean;
  account?: Accounts.T.AccountRead;
  month: string;
  data: Transactions.T.TransactionRead[];
  total: number;
  settings?: Settings.Types.SettingsRead;
}>;

const ActivityTable = ({
  isCC = false,
  account,
  month,
  data,
  total,
  settings,
}: ActivityTableProps) => {
  const headers = ['Date', 'Account', 'Memo', 'Amount'];
  const rowClasses = [
    'px-3',
    'py-1.5',
    'text-sm',
    'text-gray-800',
    'whitespace-nowrap',
  ];
  return (
    <Box>
      <Box>
        Transactions for{' '}
        <span className={cx(['font-semibold'])}>{account?.name}</span> in{' '}
        {month}
      </Box>
      <Divider />
      <Box className={cx(['border', 'rounded-md'])}>
        <table className={cx(['min-w-full', 'divide-y', 'divide-gray-300'])}>
          <thead>
            <tr className={cx(['bg-zinc-200'])}>
              {headers.map((header) => (
                <th
                  className={cx(
                    [
                      'px-3',
                      'py-1.5',
                      'text-left',
                      'text-xs',
                      'font-medium',
                      'text-gray-500',
                      'uppercase',
                      'tracking-wider',
                    ],
                    {
                      'text-right': header === 'Amount',
                    }
                  )}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={cx(['divide-y', 'divide-gray-200', 'bg-white'])}>
            {data?.map((row) => {
              const UTCDate = moment(row.date).toString();
              const localDate = UTCToLocalTime(UTCDate, settings?.date_format);
              let sign: string | null = null;

              let entry = row.entries.find(
                (entry) => entry.account.id === account?.id
              );

              if (isCC && entry?.direction === 'DEBIT') {
                sign = '-';
              }

              return (
                <tr>
                  <td className={cx([rowClasses])}>{localDate}</td>
                  <td className={cx([rowClasses])}>{row.account.name}</td>
                  <td className={cx([rowClasses])}>{row.memo}</td>
                  <td
                    className={cx([
                      rowClasses,
                      'text-right',
                      {
                        'text-green-700': sign === '-' && isCC,
                      },
                    ])}
                  >
                    {sign}
                    <Money value={row.entries[0].amount} withSign />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td
                className={cx([
                  'bg-gray-100',
                  rowClasses,
                  'text-xs',
                  'font-semibold',
                  'uppercase',
                ])}
              >
                Total
              </td>
              <td className={cx(['bg-gray-100'])}></td>
              <td className={cx(['bg-gray-100'])}></td>
              <td
                className={cx([
                  'bg-gray-100',
                  rowClasses,
                  'font-semibold',
                  'text-right',
                ])}
              >
                <Money value={total} />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export { ActivityTable };
