import React from 'react';
import { Toaster } from 'react-hot-toast';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { App } from './App';

Sentry.init({
  dsn: 'https://4b35bb9f44e54831800c0f0111415a92@o1136487.ingest.sentry.io/4504433103077376',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
  tracesSampleRate: 0.25,
});

const container = document.getElementById('budgetwise');
const root = createRoot(container);
root.render(
  <>
    <App />
    <Toaster />
  </>
);
