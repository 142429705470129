import { AxiosResponse } from 'axios';
import { api } from '../../api';
import * as T from './Budget.types';
import * as C from './Budget.constants';
import { BudgetFormInputs } from './Budget.types';

const copyFromLastMonth = async (
  budgetId: string,
  lastPeriodId: string,
  currentPeriodId: string
) => {
  const response = await api.post(
    '/budgets/' + budgetId + '/copy_from_last_month/',
    {
      current_month_period_id: currentPeriodId,
      last_month_period_id: lastPeriodId,
    }
  );

  return response.data;
};

const clearCurrentMonth = async (budgetId: string, periodId: string) => {
  const response = await api.post(
    '/budgets/' + budgetId + '/clear_budgeted_amounts/',
    {
      period_id: periodId,
    }
  );

  return response.data;
};

const postBudget = async (payload: BudgetFormInputs) => {
  const response: AxiosResponse<T.BudgetRead> = await api.post(
    `/${C.API_URL}/`,
    payload
  );

  return response.data;
};

export { copyFromLastMonth, clearCurrentMonth, postBudget };
