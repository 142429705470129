import * as React from 'react';
import * as $ from '@radix-ui/react-dropdown-menu';
import * as T from './Dropdown.types';
import * as styles from './Dropdown.styles';
import { cx } from '../../../utils';

const Dropdown = React.forwardRef<T.DropdownElement, T.DropdownProps>(
  ({ items, children, dark, darker, className, ...props }, forwardedRef) => {
    const classes = cx([styles.dropdownStyles({ dark, darker }), className]);
    return (
      <$.Root>
        <$.Trigger asChild>{children}</$.Trigger>
        <$.Content {...props} className={classes} ref={forwardedRef}>
          {items?.map(
            ({ label, icon: Icon, onSelect, onClick, group }, index) => {
              if (group) {
                return (
                  <>
                    <$.Label className={cx([styles.label])}>{label}</$.Label>
                    {group.map((groupItem, groupIndex) => {
                      return (
                        <>
                          <$.Group>
                            <$.Item
                              className={cx([
                                styles.itemStyles({ dark, darker }),
                              ])}
                              key={`${groupIndex}-${groupItem.label}`}
                              onSelect={groupItem.onSelect}
                              onClick={groupItem.onClick}
                            >
                              {!!groupItem.icon ? (
                                <groupItem.icon
                                  className={cx(['mr-2', 'h-4', 'w-4'])}
                                />
                              ) : null}
                              {groupItem.label}
                            </$.Item>
                          </$.Group>
                        </>
                      );
                    })}
                    {index !== items.length - 1 ? (
                      <$.Separator className={cx([styles.separator])} />
                    ) : null}
                  </>
                );
              }

              return (
                <$.Item
                  className={cx([styles.itemStyles({ darker })])}
                  key={`${index}-${label}`}
                  onSelect={onSelect}
                  onClick={onClick}
                >
                  {!!Icon ? (
                    <Icon className={cx(['mr-2', 'h-4', 'w-4'])} />
                  ) : null}
                  {label}
                </$.Item>
              );
            }
          )}
        </$.Content>
      </$.Root>
    );
  }
);

export { Dropdown };
