import React from 'react';
import { CardTitleComponent } from './Card.types';
import { cardTitleStyles } from './Card.styles';
import { cx } from '../../../utils';

const CardTitle: CardTitleComponent = ({ children, ...rest }) => {
  const classes = cx(cardTitleStyles(), rest.className);
  return (
    <div className={classes}>
      <h3 className={cx(['text-base', 'font-semibold', 'leading-6'])}>
        {children}
      </h3>
    </div>
  );
};

export { CardTitle };
