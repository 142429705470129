import { FaSpinner } from 'react-icons/fa';
import { Layout } from '../Layout';
import { Box } from '../Box';
import { cx } from '../../../utils';

const Loading = () => {
  return (
    <Layout title="Loading data...">
      <Box className={cx(['flex', 'justify-center', 'items-center', 'p-14'])}>
        <FaSpinner
          className={cx(['w-16', 'h-16', 'text-ew-primary', 'fa-spin'])}
        />
      </Box>
    </Layout>
  );
};

export { Loading };
