import { SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import * as React from 'react';
import { match } from 'ts-pattern';
import { Box, Layout, Public, Divider, Alert } from '../Common';
import { cx } from '../../utils';
import { PasswordResetForm as Form } from './PasswordReset.form';
import * as H from './PasswordReset.hooks';
import * as T from './PasswordReset.types';

const PasswordResetPage = () => {
  const passwordReset = H.usePasswordReset();
  const [isSuccess, setIsSuccess] = React.useState(false);

  const onSubmit: SubmitHandler<T.PasswordResetInputs> = (data) => {
    passwordReset.mutate(data, {
      onSuccess: (response) => {
        // console.log(response);
        setIsSuccess(true);
      },
      onError: (error) => {
        // console.log(error);
      },
      onSettled: () => {
        // console.log('settled');
      },
    });
  };

  return (
    <Public>
      <Box className={cx(['w-96', 'mx-auto', 'my-auto'])}>
        <img
          className={cx(['h-12', 'w-auto', 'mx-auto'])}
          src="https://beta.budgetwise.io/img/budgetwise_badge.png"
          alt=""
        />
        {match(isSuccess)
          .with(true, () => (
            <>
              <Divider size="xl" />
              <Box>
                <Alert
                  icon={FaCheckCircle}
                  intent="success"
                  title={'Email reset sent!'}
                  description="We've sent you an email with a link to reset your password."
                />
              </Box>
            </>
          ))
          .otherwise(() => null)}

        <Divider size="xl" />
        <Box
          className={cx([
            'bg-white',
            'px-8',
            'py-6',
            'rounded-xl',
            'border',
            'border-gray-300',
            // 'shadow',
          ])}
        >
          <h1
            className={cx([
              'text-2xl',
              'text-gray-600',
              'font-bold',
              'text-center',
            ])}
          >
            Reset your password
          </h1>
          <Divider size="xl" />
          <Form onSubmit={onSubmit} isLoading={passwordReset.isPending} />
        </Box>
        <Divider size="md" />
        <Box className={cx(['text-center'])}>
          <Link
            to="/sign-in"
            className={cx(['text-gray-600', 'underline', 'font-semibold'])}
          >
            Sign in
          </Link>
        </Box>
      </Box>
    </Public>
  );
};

export { PasswordResetPage };
