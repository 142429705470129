import * as React from 'react';
import { Calendar, CalendarCell, CalendarGrid } from 'react-aria-components';
import { Popover, PopoverTrigger, PopoverContent } from '../Popover';
import { FaCalendar, FaRegCalendar } from 'react-icons/fa6';
import { match, P } from 'ts-pattern';
import { Button } from '../Button';
import { Box } from '../Box';
// import { DayNumber } from '../DayNumber';
import { cx } from '../../../utils';
import * as T from './DayOfTheMonth.types';
import { IconButton } from '../IconButton';

const DayOfTheMonth: T.DayOfTheMonthTElement = ({ value, onChange }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {match(value)
          .with(P.union(P.nullish, NaN, 0), () => (
            <Box
              className={cx([
                'w-[30px]',
                'flex',
                'items-center',
                'justify-center',
              ])}
            >
              <IconButton
                label={'Select a due date'}
                icon={FaRegCalendar}
                className={cx([
                  'text-center',
                  'text-gray-500',
                  'hover:text-gray-700',
                  'text-sm',
                ])}
              />
            </Box>
          ))
          .otherwise((value) => {
            return (
              <Button
                size="sm"
                intent="ghost"
                className={cx([
                  'w-[30px]',
                  'text-gray-500',
                  'flex',
                  'items-center',
                  'justify-center',
                  'text-center',
                ])}
              >
                {value}
              </Button>
            );
          })}
      </PopoverTrigger>
      <PopoverContent>
        <Box
          className={cx([
            'border',
            'border-gray-300',
            'shadow',
            'inline-block',
            'p-2',
            'rounded-lg',
            'bg-gray-50',
          ])}
        >
          <Calendar
            aria-label="Appointment date"
            onChange={(dateValue) => {
              onChange?.(dateValue);
              setOpen(false);
            }}
          >
            <CalendarGrid>
              {(date) => <CalendarCell date={date} />}
            </CalendarGrid>
          </Calendar>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export { DayOfTheMonth };
