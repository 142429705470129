import * as React from 'react';
import { cx } from '../../utils';
import { Box, Select } from '../Common';
import * as T from './FileImports.types';

const columns = ['Date', 'Memo', 'Type', 'Amount', 'Inflow', 'Outflow'];

const FileImportsTable: T.FileImportsTable = ({
  data,
  onSelectColumn,
  selectedColumns,
}) => {
  const headers = Object.keys(data?.[0]).map((header) => {
    switch (header.toLowerCase()) {
      default:
        return header;
    }
  });
  return (
    <table className={cx(['min-w-full', 'divide-y', 'divide-gray-300'])}>
      <thead>
        <tr>
          {headers.map((header, index) => {
            let defaultValue = selectedColumns?.[index] || '';

            return (
              <th
                className={cx([
                  'px-6',
                  'py-3',
                  'text-left',
                  'text-xs',
                  'font-medium',
                  'text-gray-500',
                  'uppercase',
                  'tracking-wider',
                ])}
              >
                <Select
                  hideLabel
                  value={defaultValue}
                  onValueChange={(value) => {
                    onSelectColumn?.(value, header, index);
                  }}
                  label={'Select column'}
                  options={columns.map((option) => {
                    let value = option === 'Select' ? '' : option;
                    return {
                      label: option,
                      value,
                    };
                  })}
                  placeholder="Select column"
                />
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={cx(['divide-y', 'divide-gray-200', 'bg-white'])}>
        {data.map((row) => (
          <tr>
            {Object.values(row).map((value) => (
              <td
                className={cx([
                  'px-6',
                  'py-4',
                  'text-sm',
                  'text-gray-800',
                  'whitespace-nowrap',
                ])}
              >
                {/*@ts-ignore*/}
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export { FileImportsTable };
