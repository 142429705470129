import { cva } from 'class-variance-authority';

const base = [
  'relative',
  'font-semibold',
  'shadow-sm',
  'focus-visible:ring-2',
  'focus-visible:ring-offset-2',
  'focus-visible:ring-ew-primary',
];

/** Intent */
const primary = [
  'text-white',
  'bg-ew-primary',
  'hover:bg-ew-primary-600',
  'dark:bg-ew-primary-dark',
  'dark:hover:bg-ew-primary-dark-600',
];

const secondary = [
  'text-gray-900',
  'border',
  'border-gray-300',
  'bg-white',
  'hover:bg-gray-50',
  'dark:text-white',
  'dark:bg-ew-secondary-dark',
  'dark:border-ew-secondary-dark',
  'dark:hover:bg-ew-secondary-dark-600',
  'dark:hover:border-ew-secondary-dark-600',
  'dark:ring-transparent',
  'dark:hover:ring-ew-text-700',
  'dark:focus-visible:ring-ew-primary-dark',
];

const accent = ['text-white', 'bg-ew-accent', 'hover:bg-ew-accent-600'];

const danger = ['text-white', 'bg-ew-error', 'hover:bg-ew-error-600'];

const errors = [
  'text-ew-error',
  'ring-ew-error',
  'border-ew-error',
  'focus-visible:ring-ew-error',
];

const ghost = [
  'p-0',
  'm-0',
  'bg-transparent',
  'shadow-none',
  'hover:bg-gray-200',
];

/** Size */
const xs = ['rounded', 'px-2', 'py-1', 'text-xs'];
const sm = ['rounded-md', 'px-2', 'py-1', 'text-sm'];
const md = ['rounded-md', 'px-2.5', 'py-1.5', 'text-sm'];
const lg = ['rounded-md', 'px-3', 'py-2', 'text-sm'];
const xl = ['rounded-md', 'px-3.5', 'py-2.5', 'text-sm'];

const buttonStyles = cva(base, {
  variants: {
    intent: {
      primary,
      secondary,
      accent,
      ghost,
      danger,
    },
    size: {
      xs,
      sm,
      md,
      lg,
      xl,
    },
    fullWidth: {
      true: 'w-full',
    },
    isDisabled: {
      true: 'border border-red-300',
    },
    isLoading: {
      true: 'loading',
    },
    outlined: {
      true: 'btn-outline',
    },
    hasErrors: {
      true: errors,
    },
  },
  defaultVariants: {
    intent: 'secondary',
    size: 'md',
  },
});

export { buttonStyles };
