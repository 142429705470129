import React, { forwardRef } from 'react';
import { cx } from '../../../utils';
import { BoxComponent, BoxProps } from './Box.types';
import { PolymorphicRef } from '../Common.types';

// @ts-ignore
const Box: BoxComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, color, children, ...rest }: BoxProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || 'div';

    const classes = cx('box-border', 'm-0', 'min-w-0', rest.className);

    return (
      <Component className={classes} {...rest} ref={ref}>
        {children}
      </Component>
    );
  }
);

export { Box };
