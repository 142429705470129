import React from 'react';
import { AlertComponent } from './Alert.types';
import { Box } from '../Box';
import { alertStyles } from './Alert.styles';
import { cx } from '../../../utils';
import { Divider } from '../Divider';

const Alert: AlertComponent = ({
  intent,
  title,
  description,
  border,
  icon: Icon,
  children,
  ...rest
}) => {
  const classes = cx(alertStyles({ intent }), rest.className, {});

  const isSuccess = intent === 'success';
  const isError = intent === 'error';
  const isWarning = intent === 'warning';
  const isInfo = intent === 'info';

  const containerClasses = {
    'bg-green-50 border border-green-500': isSuccess,
    'bg-red-50': isError,
    'bg-yellow-200 border border-yellow-400 dark:bg-yellow-500 dark:border-yellow-700':
      isWarning,
    'bg-blue-50 border border-1 border-blue-400': isInfo,
  };

  const iconClasses = {
    'text-green-400': isSuccess,
    'text-red-400': isError,
    'text-yellow-500 dark:text-yellow-800': isWarning,
    'text-blue-400': isInfo,
  };

  const titleClasses = {
    'text-green-800': isSuccess,
    'text-red-800': isError,
    'text-yellow-800 dark:text-yellow-300': isWarning,
    'text-blue-800': isInfo,
  };

  const contentClasses = {
    'text-green-700': isSuccess,
    'text-red-700': isError,
    'text-yellow-700 dark:text-yellow-900': isWarning,
    'text-blue-700': isInfo,
  };

  return (
    <Box {...rest} className={cx(['rounded-md', 'p-4', containerClasses])}>
      <Box className={cx(['flex'])}>
        <Box
          className={cx(['flex-shrink-0'], {
            'mt-1': !!title,
          })}
        >
          <Icon
            className={cx(['h-5', 'w-5', iconClasses])}
            aria-hidden="true"
          />
        </Box>
        {title ? (
          <Box className={cx(['ml-3', 'flex-1'])}>
            <p className={cx(['text-lg', 'font-medium', titleClasses])}>
              {title}
            </p>

            {description && (
              <Box className={cx(['mt-2', 'text-base', contentClasses])}>
                <p>{description}</p>
              </Box>
            )}
            {children ? (
              <>
                <Divider />
                {children}
              </>
            ) : null}
          </Box>
        ) : (
          <Box
            className={cx([
              'ml-3',
              'flex',
              'flex-1',
              'flex-col',
              'md:justify-between',
            ])}
          >
            <Box className={cx(['text-sm', contentClasses])}>
              <p>{description}</p>
            </Box>
            {children ? (
              <>
                <Divider />
                {children}
              </>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { Alert };
