import React, { useState, PropsWithChildren, ReactElement } from 'react';
import * as _ from 'lodash-es';
import toast from 'react-hot-toast';
import { match, P } from 'ts-pattern';
import { FaCalculator, FaFileImport, FaPlus } from 'react-icons/fa';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { fad } from '@awesome.me/kit-7767038e99/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  ComboboxOption,
  IconButton,
  Select,
  SlideOver,
  unformatMoney,
} from '../Common';
import * as Accounts from '../Account';
import * as Settings from '../Settings';
import * as Sidebar from '../Common/Sidebar';
import * as Budget from '../Budget';
import { cx } from '../../utils';
import {
  accountTypesSpending,
  accountTypesDebt,
  accountTypesOffBudgetAssets,
  accountTypesOffBudgetLiabilities,
} from '../../utilities/accountTypes';
// TODO: Move these types to Account.Types
import { UpdateAccountFormInputs } from '../Account/Account.types';
import invariant from 'tiny-invariant';
import { SelectOption } from '../Common/Select/Select.types.ts';

const SidebarHeader = ({
  budgetName = 'My budget',
  isLoading = false,
  budgets,
}: {
  budgetName: string;
  isLoading: boolean;
  budgets?: Budget.T.BudgetPaginatedRead;
}) => {
  const navigate = useNavigate();

  const formattedBudgets: SelectOption[] | undefined =
    budgets?.results?.map((budget) => {
      return {
        label: budget.name,
        value: budget.hashid,
      };
    }) || [];

  let selected = formattedBudgets.find((b) => b.label === budgetName);

  let handleChange = (option: string) => {
    navigate(`/b/${option}`);
  };

  return (
    <div className={cx(['flex', 'items-center'])}>
      <img
        className="h-6 w-auto"
        src="https://beta.budgetwise.io/img/budgetwise_badge.png"
        alt=""
      />
      <div
        className={cx([
          'min-w-[5rem]',
          'p-3',
          'text-base',
          'font-medium',
          'text-white',
          'truncate',
        ])}
      >
        {match([isLoading, formattedBudgets, budgetName])
          .with([true, P._, P._], () => 'loading...')
          .with([false, [], P._], ([loading, budgets, name]) => name)
          .with(
            [false, P.not(P.nullish), P.not(P.nullish)],
            ([loading, budgets, name]) => {
              return (
                <Select
                  defaultValue={selected?.value as string}
                  onValueChange={handleChange}
                  iconClasses={cx([
                    'w-auto',
                    'mt-1',
                    'ml-3',
                    'text-ew-text-dark-600',
                    'w-3',
                    'h-3',
                  ])}
                  viewportClasses={cx(['bg-ew-bg-dark-800'])}
                  triggerClasses={cx([
                    'w-auto',
                    'items-center',
                    'text-inherit',
                    'bg-transparent',
                    'ring-0',
                    'dark:ring-ew-primary-dark',
                    'text-white',
                    'justify-start',
                    'hover:bg-ew-bg-dark-800',
                  ])}
                  dropdownClasses={cx([
                    'bg-ew-bg-dark-800',
                    'border-0',
                    'outline-0',
                    'text-white',
                  ])}
                  label={'Budget'}
                  placeholder={name}
                  hideLabel
                  options={budgets}
                />
              );
            }
          )
          .otherwise(() => null)}
      </div>
    </div>
  );
};

type SidebarAccountSectionProps = {
  title?: string;
  accounts?: Accounts.T.AccountRead[];
  onEdit?: (account: Accounts.T.AccountRead) => void;
};

type SidebarAccountSectionElement = (
  props: SidebarAccountSectionProps
) => ReactElement | null;

const SidebarAccountSection: SidebarAccountSectionElement = ({
  title,
  accounts,
  onEdit,
}) => {
  return (
    <Box>
      <Box>
        <p
          className={cx([
            'py-2',
            'text-sm',
            'text-ew-primary',
            'uppercase',
            'font-semibold',
          ])}
        >
          {title}
        </p>
      </Box>
      <Box>
        <ul>
          {accounts?.map((account) => (
            <Sidebar.AccountLink
              key={account.id}
              account={account}
              onEdit={() => {
                onEdit?.(account);
              }}
            />
          ))}
        </ul>
      </Box>
    </Box>
  );
};

type DashboardLayoutProps = {
  compact?: boolean;
};

type DashboardLayoutComponent = (
  props: PropsWithChildren<DashboardLayoutProps>
) => ReactElement | null;

const nav = [
  {
    href: '.',
    icon: FaCalculator,
    label: 'Budget',
  },
  {
    href: 'import',
    icon: FaFileImport,
    label: 'Imports',
  },
];

const DashboardLayout: DashboardLayoutComponent = ({ compact = false }) => {
  const navigate = useNavigate();
  const {
    editAccountFormOpen,
    setEditAccountFormOpen,
    accountToEdit,
    setAccountToEdit,
  } = Accounts.S.useAccountStore((state) => state);

  const [createAccountFormOpen, setCreateAccountFormOpen] = useState(false);

  const { budgetId } = useParams();
  invariant(budgetId, 'No budget ID found in URL.');

  const budgets = Budget.H.useGetBudgets();
  const budget = Budget.H.useGetBudget(budgetId);
  const settings = Settings.Hooks.useGetSettings(budgetId);
  const accounts = Accounts.H.useGetAccounts(budgetId);
  const postAccountMutation = Accounts.H.usePostAccount(budgetId);
  const patchAccountMutation = Accounts.H.usePatchAccount(budgetId);
  const deleteAccountMutation = Accounts.H.useDeleteAccount(budgetId);

  const onAccountPost = (account: Accounts.T.NewAccountFormInputs) => {
    const toastId = toast.loading('Creating account...');

    invariant(account.type, 'Type is required for account creation');
    invariant(_.isString(account.type), 'Type must be a string');

    const newAccount: Accounts.T.AccountCreate = {
      ...account,
      opening_balance: unformatMoney(account.opening_balance),
      type: account.type,
      budget_id: budgetId,
      _as: Accounts.C.ACCOUNT_AS.ACCOUNT,
      category_group_id: null,
    };
    // TODO:FIX
    // @ts-ignore
    postAccountMutation.mutate(newAccount, {
      onSuccess() {
        toast.success('Account created!', {
          id: toastId,
        });

        setCreateAccountFormOpen(false);
      },
      onError() {
        toast.error('Could not create account :(', {
          id: toastId,
        });
      },
      onSettled() {},
    });
  };

  const onAccountPatch = (account: UpdateAccountFormInputs) => {
    const toastId = toast.loading('Updating account');

    if (!_.isUndefined(accountToEdit) && 'id' in accountToEdit) {
      const newAccount: Accounts.T.AccountPost = {
        // TODO:FIX
        // @ts-ignore
        id: accountToEdit?.id,
        // @ts-ignore
        name: account.name,
        // @ts-ignore
        notes: account.notes,
        apr: account.apr,
        min_payment: account.min_payment,
      };

      patchAccountMutation.mutate(newAccount, {
        onSuccess() {
          toast.success('Account updated!', {
            id: toastId,
          });

          setEditAccountFormOpen(false);
        },
        onError(error) {
          toast.error('Could not update account :(', {
            id: toastId,
          });
        },
        onSettled() {},
      });
    }
  };

  const onAccountDelete = () => {
    const toastId = toast.loading('Deleting account...');

    if (!_.isNil(accountToEdit) && 'id' in accountToEdit) {
      deleteAccountMutation.mutate(accountToEdit?.id, {
        onSuccess() {
          toast.success('Account deleted!', {
            id: toastId,
          });

          let target = `/u/b/${budgetId}/a/${accounts?.data?.[0].id}`;
          navigate(target);
          setEditAccountFormOpen(false);
        },
        onError() {
          toast.error('Could not delete account :(', {
            id: toastId,
          });
        },
        onSettled() {},
      });
    }
  };

  // const todayDate = new Date();
  // const UTCTime = localToUTCTime(todayDate.toDateString());

  let spendingAccounts: Accounts.T.AccountRead[] = [];
  let debtAccounts: Accounts.T.AccountRead[] = [];
  let offBudgetAccountsAssets: Accounts.T.AccountRead[] = [];
  let offBudgetAccountsLiabilities: Accounts.T.AccountRead[] = [];

  accounts?.data?.forEach((account) => {
    if (accountTypesSpending.includes(account.type)) {
      spendingAccounts.push(account);
    }

    if (accountTypesDebt.includes(account.type)) {
      debtAccounts.push(account);
    }

    if (accountTypesOffBudgetAssets.includes(account.type)) {
      offBudgetAccountsAssets.push(account);
    }

    if (accountTypesOffBudgetLiabilities.includes(account.type)) {
      offBudgetAccountsLiabilities.push(account);
    }
  });

  const sidebarAccounts = [
    _.isEmpty(spendingAccounts)
      ? {}
      : {
          title: 'Spending & Savings',
          accounts: spendingAccounts,
        },
    _.isEmpty(debtAccounts)
      ? {}
      : {
          title: 'Credit Cards & Loans',
          accounts: debtAccounts,
        },
    _.isEmpty(offBudgetAccountsAssets)
      ? {}
      : {
          title: 'Off-Budget Assets',
          accounts: offBudgetAccountsAssets,
        },

    _.isEmpty(offBudgetAccountsLiabilities)
      ? {}
      : {
          title: 'Off-Budget Liabilities',
          accounts: offBudgetAccountsLiabilities,
        },
  ];

  return (
    <>
      <SlideOver
        formId={'new-account'}
        title={'New Account'}
        open={createAccountFormOpen}
        setOpen={setCreateAccountFormOpen}
        actionLabel={'Create Account'}
      >
        <Accounts.Forms.NewAccountForm
          settings={settings.data?.[0]}
          formId={'new-account'}
          onSubmit={onAccountPost}
        />
      </SlideOver>
      {match({
        // It takes a bit to load this data from the zustand store, so we wait until
        // these aren't null to render the component.
        // name: accountToEdit?.name,
        // notes: accountToEdit?.notes,
        ...accountToEdit,
      })
        .with(
          {
            name: P.not(P.nullish),
          },
          (account) => (
            <SlideOver
              formId={'edit-account'}
              title={'Edit Account'}
              open={editAccountFormOpen}
              isLoading={patchAccountMutation.isPending}
              setOpen={setEditAccountFormOpen}
            >
              <Accounts.Forms.UpdateAccountForm
                formId={'edit-account'}
                // TODO:FIX
                // @ts-ignore
                initialValue={account}
                onSubmit={onAccountPatch}
                onDelete={onAccountDelete}
                isLoading={patchAccountMutation.isPending}
                hiddeButtons
                settings={settings.data?.[0]}
              />
            </SlideOver>
          )
        )
        .otherwise(() => null)}
      <Box className={cx(['flex', 'flex-row', 'flex-grow'])}>
        <Sidebar.$
          title="Budgetwise"
          header={
            <SidebarHeader
              isLoading={budget.isLoading}
              budgetName={budget.data?.name}
              budgets={budgets?.data}
            />
          }
          className={cx(['w-[19rem]'])}
          onChange={(open) => {}}
        >
          <Box
            as="nav"
            className={cx([
              'relative',
              'flex',
              'flex-1',
              'flex-col',
              'focus:outline-none',
              'focus-visible:rounded-md',
              'focus-visible:ring-2',
              'focus-visible:ring-ew-primary',
            ])}
            aria-label="Sidebar"
            tabIndex={0}
          >
            <ul
              className={cx(['flex', 'flex-1', 'flex-col', 'gap-y-7'], {
                'gap-y-3': compact,
              })}
            >
              <li>
                <ul
                  role="list"
                  className={cx(['space-y-1'], {
                    'space-y-0.5': compact,
                  })}
                >
                  {nav.map(({ href, icon, label }) => {
                    if (label === 'Budget') {
                      href = `/${budgetId}`;
                    }

                    return (
                      <Sidebar.PageLink
                        key={label}
                        href={href}
                        icon={icon}
                        label={label}
                      />
                    );
                  })}
                </ul>
              </li>

              <li className={cx(['sticky', 'top-0'])}>
                <ul
                  className={cx(['mt-2', 'space-y-1'], {
                    'mt-1': compact,
                    'space-y-0.5': compact,
                  })}
                >
                  <li>
                    <Box
                      className={cx([
                        'mb-4',
                        'flex',
                        'flex-row',
                        'text-base',
                        'leading-6',
                        'uppercase',
                        'tracking-wider',
                        'font-bold',
                        'text-gray-200',
                      ])}
                    >
                      Accounts
                      <IconButton
                        intent="primary"
                        label={'Add Account'}
                        icon={FaPlus}
                        onClick={() => setCreateAccountFormOpen(true)}
                        className={cx(['ml-auto'])}
                      />
                    </Box>
                  </li>

                  {sidebarAccounts?.map(({ title, accounts }) => (
                    <li key={title}>
                      <SidebarAccountSection
                        title={title}
                        accounts={accounts}
                        onEdit={(account) => {
                          setAccountToEdit(account);
                          setEditAccountFormOpen(true);
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </Box>
        </Sidebar.$>
        <Box className={cx(['flex', 'flex-grow'])}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export { DashboardLayout };
