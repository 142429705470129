import * as React from 'react';
import { cx } from '../../../utils';
import { match, P } from 'ts-pattern';
import { Box, IconButton } from '../../Common';
import { FaCheckCircle, FaChevronRight } from 'react-icons/fa';
import * as accounting from 'accounting';
import * as T from '../Transactions.types';
import * as S from '../Transactions.store';
import { Money } from '../../Common';

const styles = {
  base: [
    'hidden',
    'py-4',
    'px-6',
    'text-sm',
    'font-medium',
    'text-gray-900',
    'lg:pl-0',
  ],
};

type TableMobileRowProps = React.ComponentPropsWithoutRef<'td'> & {
  columnId: string;
  rowObject: T.TableTransaction;
};

const TableMobileRow = React.forwardRef<'td', TableMobileRowProps>(
  ({ columnId, rowObject }) => {
    const { transactionFormOpen, setTransactionFormOpen } = S.transactionsStore(
      (state) => state
    );
    const columnsToShow = ['date', 'memo', 'approved'];
    return (
      <td
        className={cx([styles.base], {
          'table-cell lg:hidden': columnsToShow.includes(columnId),
          'w-3 pl-0 pr-6': columnId === 'approved',
        })}
      >
        {match(columnId)
          .with('date', (columnId) => {
            return (
              <>
                <Box className={cx(['flex', 'items-center', 'space-x-2'])}>
                  <Box className={cx(['text-lg'])}>
                    {rowObject['payee']?.name ?? '--'}
                  </Box>
                  <Box>
                    <FaCheckCircle
                      className={cx(['text-ew-primary', 'text-lg'])}
                    />
                  </Box>
                </Box>
                <dl className={cx(['font-normal', 'lg:hidden', 'mt-2'])}>
                  <dt className={cx(['sr-only'])}>Category</dt>
                  <dd>
                    <span className={cx(['text-gray-500'])}>
                      {rowObject['category']?.name}
                    </span>
                  </dd>
                </dl>
              </>
            );
          })
          .with('memo', (columnId) => {
            const item = rowObject;
            const sign = item['type'] === 'INFLOW' ? '+' : '-';
            return (
              <dl className={cx(['font-normal'])}>
                <dt className={cx(['sr-only'])}>Amount</dt>
                <dd className={cx(['text-right'])}>
                  <span
                    className={cx(['text-lg', 'font-semibold'], {
                      'text-ew-primary': item['type'] === 'INFLOW',
                      'text-gray-600': item['type'] === 'OUTFLOW',
                    })}
                  >
                    {sign} <Money value={item['amount']} />
                  </span>
                </dd>
                <dt className={cx(['sr-only'])}>Date</dt>
                <dd className={cx('text-right')}>
                  <span className={cx(['text-gray-400'])}>{item['date']}</span>
                </dd>
              </dl>
            );
          })
          .with('approved', (columnId) => {
            return (
              <>
                <dt className={cx(['sr-only'])}>Edit</dt>
                <dd className={cx(['text-right'])}>
                  <IconButton
                    label="Edit"
                    icon={FaChevronRight}
                    onClick={() => setTransactionFormOpen(true)}
                  />
                </dd>
              </>
            );
          })
          .with(P.nullish, () => '')
          .otherwise(() => null)}
      </td>
    );
  }
);

export { TableMobileRow };
