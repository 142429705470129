import { useMutation } from '@tanstack/react-query';
import * as C from './PasswordUpdate.constants';
import { passwordUpdate } from './PasswordUpdate.req';

const usePasswordUpdate = () => {
  return useMutation({
    mutationKey: [C.MUTATION_KEY],
    mutationFn: passwordUpdate,
  });
};

export { usePasswordUpdate };
