import * as React from 'react';
import { Box } from '../../Box';
import { cx } from '../../../../utils';
import {
  firstDotStyles,
  secondDotStyles,
  thirdDotStyles,
} from './ThreeDots.styles';
import * as T from './ThreeDots.types';

const ThreeDots = React.forwardRef<T.ThreeDotsElement, T.ThreeDotsProps>(
  (
    { intent = 'secondary', size = 'md', inverted = false, ...props },
    forwardedRef
  ) => {
    return (
      <Box
        className={cx(['flex'], {
          'p-1.5 rounded-lg inline-flex': inverted,
          'bg-gray-800 text-gray-800': inverted && intent === 'secondary',
          'bg-ew-primary text-white': inverted && intent === 'primary',

          'space-x-1 rounded-lg': size === 'sm',
          'space-x-0.5 rounded-xl': size === 'md',
          'space-x-1.5 rounded-2xl': size === 'lg',
        })}
      >
        <Box className={cx([firstDotStyles({ intent, size, inverted })])} />
        <Box className={cx([secondDotStyles({ intent, size, inverted })])} />
        <Box className={cx([thirdDotStyles({ intent, size, inverted })])} />
      </Box>
    );
  }
);

export { ThreeDots };
