import React, { PropsWithChildren } from 'react';
import { OverviewBoxProps } from '../Transactions.types';
// import { useCopyToClipboard } from 'usehooks-ts';
import {
  Money,
  Box,
  // Divider,
  // IconButton
} from '../../Common';
import { cx } from '../../../utils';
import { FaRegCopy } from 'react-icons/fa';
import isUndefined from 'lodash.isundefined';
import accounting from 'accounting';

const OverviewBox = ({
  icon: Icon,
  label,
  amount,
  isLoading = true,
}: // type,
// settings,
// borderless = false,
PropsWithChildren<OverviewBoxProps>) => {
  // const [value, copy] = useCopyToClipboard();
  return (
    <Box className={cx(['relative', 'text-right', 'px-4', 'group'], {})}>
      {/*{!!amount && (*/}
      {/*  <IconButton*/}
      {/*    label={`copy ${type} to clipboard`}*/}
      {/*    onClick={() => copy(amount.toString())}*/}
      {/*    className={cx([*/}
      {/*      'absolute',*/}
      {/*      'bottom-0',*/}
      {/*      'right-0',*/}
      {/*      'text-gray-400',*/}
      {/*      'hidden',*/}
      {/*      'group-hover:block',*/}
      {/*      'active:text-gray-700',*/}
      {/*    ])}*/}
      {/*    intent="ghost"*/}
      {/*    icon={FaRegCopy}*/}
      {/*  />*/}
      {/*)}*/}
      <Box className={cx([])}>
        <Box className={cx(['flex', 'items-center'])}>
          {/*<Box className={cx([])}>*/}
          {/*  <Icon*/}
          {/*    className={cx([*/}
          {/*      '-mr-4',*/}
          {/*      'text-2xl',*/}
          {/*      {*/}
          {/*        'text-ew-text dark:text-ew-text-dark': type === 'balance',*/}
          {/*        'text-gray-400': type === 'unapproved',*/}
          {/*        'text-ew-primary dark:text-ew-primary-dark':*/}
          {/*          type === 'approved',*/}
          {/*      },*/}
          {/*    ])}*/}
          {/*  />*/}
          {/*</Box>*/}
          <Box className={cx(['ml-2', 'w-0', 'flex-1'])}>
            <Box
              className={cx([
                'truncate',
                'uppercase',
                'font-bold',
                'text-gray-500',
                'dark:text-ew-primary-dark-400',
              ])}
            >
              {label}
            </Box>
            <Box
              className={cx([
                'text-2xl',
                'text-gray-700',
                'dark:text-ew-text-dark',
                {
                  'text-red-700': Math.sign(Number(amount)) === -1,
                },
              ])}
            >
              {isLoading || isUndefined(amount) ? (
                'Loading...'
              ) : (
                <Money value={amount} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { OverviewBox };
