import React from 'react';
import { Box } from '../Box';
import { cx } from '../../../utils';
import { cardStyles, cardBodyStyles, cardFooterStyles } from './Card.styles';
import {
  CardBodyComponent,
  CardFooterComponent,
  CardComponent,
} from './Card.types';
import { CardTitle } from './CardTitle';

const CardBody: CardBodyComponent = ({ children, ...rest }) => {
  const classes = cx(cardBodyStyles(), rest.className);
  return <div className={classes}>{children}</div>;
};

const CardFooter: CardFooterComponent = ({ children, ...rest }) => {
  const classes = cx(cardFooterStyles(), rest.className);
  return <div className={classes}>{children}</div>;
};

const Card_: CardComponent = ({ title, children, ...rest }) => {
  const classes = cx(cardStyles(), rest.className);
  return <Box className={classes}>{children}</Box>;
};

const Card = Object.assign(Card_, {
  Title: CardTitle,
  Body: CardBody,
  Footer: CardFooter,
});

export { Card };
