import React, { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { Slot } from '@radix-ui/react-slot';
import { FaTimes, FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { useTransition, animated, config } from '@react-spring/web';
import { Toggle } from '../Toggle';
import { Button } from '../Button';
import { SlideOverComponent } from './SlideOver.types';
import { cx } from '../../../utils';

const SlideOver: SlideOverComponent = ({
  title,
  open,
  setOpen,
  actionLabel,
  actionCallback,
  secondaryActionCallback,
  secondaryActionLabel,
  children,
  asChild,
  formId,
  isLoading = false,
  toggleLabel,
  toggleState = false,
  updateToggle,
  ...props
}) => {
  // Close on Escape
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        setOpen(false);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [setOpen]);

  const Component = asChild ? Slot : 'div';

  // https://www.radix-ui.com/docs/primitives/overview/animation#delegating-unmounting-for-javascript-animation
  const transitions = useTransition(open, {
    from: { opacity: 0, x: 100 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0, x: 100 },
    config: config.wobbly,
  });

  const onClickAction = () => {
    actionCallback?.();
  };

  const toggleClasses = cx([
    'w-7',
    'h-7',
    'text-gray-500',
    'dark:text-gray-400',
  ]);

  return transitions((style, item) =>
    item ? (
      <Component
        className={cx(['relative', 'z-50'])}
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
        {...props}
      >
        <>
          {open && (
            <animated.div
              className={cx([
                'fixed',
                'inset-0',
                'bg-ew-bg-dark',
                'bg-opacity-75',
                'dark:bg-ew-bg-dark',
                'dark:bg-opacity-50',
              ])}
              style={{ opacity: style.opacity }}
            />
          )}

          <div
            className={cx(['fixed', 'inset-0', 'overflow-hidden'])}
            // onClick={closeWhenClickingOutsideOfContent}
          >
            <div className={cx(['absolute', 'inset-0', 'overflow-hidden'])}>
              <div
                className={cx([
                  'pointer-events-none',
                  'fixed',
                  'inset-y-0',
                  'right-0',
                  'flex',
                  'max-w-full',
                  'pl-10',
                ])}
              >
                <animated.div
                  className={cx([
                    'pointer-events-auto',
                    'w-screen',
                    'max-w-md',
                  ])}
                  style={{ ...style }}
                >
                  <div
                    className={cx([
                      'flex',
                      'h-full',
                      'flex-col',
                      'divide-y',
                      'divide-gray-200',
                      'dark:divide-gray-700',
                      'bg-white',
                      'dark:bg-ew-bg-dark',
                      'shadow-xl',
                      'dark:bg-ew-bg-dark',
                      'dark:divide-gray-700',
                    ])}
                  >
                    <div
                      className={cx([
                        'flex',
                        'min-h-0',
                        'flex-1',
                        'flex-col',
                        'overflow-y-scroll',
                      ])}
                    >
                      <div
                        className={cx([
                          'bg-gray-50',
                          'px-4',
                          'py-6',
                          'sm:px-6',
                          'border-b',
                          'border-gray-200',
                          'dark:bg-ew-bg-dark',
                          'dark:border-gray-600',
                        ])}
                      >
                        <div
                          className={cx([
                            'flex',
                            'items-start',
                            'justify-between',
                          ])}
                        >
                          <h2
                            className={cx([
                              'text-base',
                              'font-semibold',
                              'leading-6',
                              'text-gray-900',
                              'dark:text-gray-300',
                            ])}
                            id="slide-over-title"
                          >
                            {title}
                          </h2>
                          <div className={cx(['ml-3', 'flex', 'items-center'])}>
                            <Button
                              onClick={() => setOpen(false)}
                              className={cx([
                                'rounded-full',
                                'flex',
                                'items-center',
                                'w-5',
                                'h-5',
                                'p-1.5',
                                'bg-gray-50',
                                'text-gray-400',
                                'ring-0',
                                'shadow-none',
                                'hover:text-gray-500',
                                'hover:bg-transparent',
                                'focus:outline-none',
                                'focus-visible:ring-2',
                                'focus-visible:ring-ew-primary',
                                'focus-visible:ring-offset-2',
                                'dark:focus-visible:ring-offset-ew-bg-dark',
                                'border-0',
                              ])}
                            >
                              <span className={cx(['sr-only'])}>
                                Close panel
                              </span>
                              <FaTimes />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div
                        data-component="slide-over"
                        className={cx([
                          'relative',
                          'mt-6',
                          'flex-1',
                          'px-4',
                          'sm:px-6',
                        ])}
                      >
                        <div className={cx(['space-y-6', 'pb-5', 'pt-6'])}>
                          {children}
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx([
                        'flex',
                        'flex-shrink-0',
                        'justify-end',
                        'items-center',
                        'px-4',
                        'py-4',
                      ])}
                    >
                      <Button
                        className={cx([
                          'mr-auto',
                          'dark:ring-offset-ew-bg-dark',
                        ])}
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>

                      {toggleLabel && updateToggle && (
                        <Toggle
                          size="sm"
                          className={cx([
                            'p-0',
                            'focus-visible:ring-2',
                            'focus-visible:ring-offset-2',
                            'focus-visible:ring-ew-primary',
                            'dark:dark:ring-offset-ew-bg-dark',
                          ])}
                          aria-label="Toggle approved"
                          data-component="toggle"
                          onPressedChange={updateToggle}
                        >
                          {toggleState ? (
                            <FaToggleOn
                              className={cx(toggleClasses, [
                                'text-ew-primary',
                                'dark:text-ew-primary-dark',
                              ])}
                            />
                          ) : (
                            <FaToggleOff className={toggleClasses} />
                          )}{' '}
                          <span
                            className={cx([
                              'px-3',
                              'text-base',
                              'dark:text-ew-text-dark',
                            ])}
                          >
                            {toggleLabel}
                          </span>
                        </Toggle>
                      )}

                      <Button
                        size="lg"
                        isLoading={isLoading}
                        className={cx(['ml-4', 'dark:ring-offset-ew-bg-dark'])}
                        intent="primary"
                        form={formId}
                        onClick={onClickAction}
                      >
                        {actionLabel ?? 'Save'}
                      </Button>
                    </div>
                  </div>
                </animated.div>
              </div>
            </div>
          </div>
        </>
      </Component>
    ) : (
      <div />
    )
  );
};

export { SlideOver };
