import { AxiosError, AxiosResponse } from 'axios';
import { api } from '../../api';
import * as T from './Categories.types';
import * as C from './Categories.constants';

const postCategory = async (category: T.CategoryPost) => {
  const response: AxiosResponse<T.CategoryRead[]> = await api.post(
    `/${C.API_URL}/`,
    category
  );

  return response.data;
};

const getCategories = async (budgetId: string): Promise<T.CategoryRead[]> => {
  const response: AxiosResponse<T.CategoryRead[]> = await api.get(
    `/${C.API_URL}/`,
    {
      params: {
        budget_id: budgetId,
        _as: 'CATEGORY',
      },
    }
  );

  return response.data;
};

const patchCategory = async (category: T.CategoryPatch) => {
  const { id, ...restOfCategory } = category;
  const response: AxiosResponse<T.CategoryRead[]> = await api.patch(
    `/${C.API_URL}/${id}/`,
    restOfCategory
  );

  return response.data;
};

export { getCategories, postCategory, patchCategory };
