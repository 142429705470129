import { useQuery } from '@tanstack/react-query';
import { UserAPIResponse } from './User.types';
import { api } from '../../api';
import { AxiosResponse } from 'axios';

const useGetUser = () => {
  return useQuery({
    queryKey: ['USER'],
    queryFn: async (): Promise<UserAPIResponse> => {
      const response: AxiosResponse<UserAPIResponse> = await api.get('/users/');

      return response.data;
    },
  });
};

export { useGetUser };
