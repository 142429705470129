import React from 'react';
import toast from 'react-hot-toast';
import accounting from 'accounting';
import invariant from 'tiny-invariant';
import _ from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import { match, P } from 'ts-pattern';
import { ACCOUNT_TOTALS_KEY } from './Account.constants';
import * as CategoryGroups from '../CategoryGroups';
import * as Transactions from '../Transactions';
import * as Settings from '../Settings';
import * as Entries from '../Entries';
import * as Payees from '../Payees';
import * as Store from './Account.store';
import * as H from './Account.hooks';
import * as C from './Account.constants';
import * as T from './Account.types';
import { OnSubmit, Loading, unformatMoney } from '../Common';
import { TransferFormInputs } from '../Transfers/Transfers.types';
import { localToUTCTime } from '../../utils';
import {
  accountTypesDebt,
  accountTypesOffBudgetAssets,
  accountTypesOffBudgetLiabilities,
  accountTypesSpending,
} from '../../utilities/accountTypes.ts';

function AccountPage() {
  const queryClient = useQueryClient();
  const transactionsPaginationState = Store.useAccountStore(
    (state) => state.transactionsPaginationState
  );

  // queryKey: [ACCOUNT_TOTALS_KEY, accountId, budgetId],
  const { budgetId, accountId } = useParams();
  invariant(budgetId && accountId, 'No budget or account ID found in URL.');

  const payees = Payees.H.useGetPayees(budgetId);
  const accounts = H.useGetAccounts(budgetId);
  const internalAccounts = H.useGetInternalAccounts(budgetId);
  const settings = Settings.Hooks.useGetSettings(budgetId);

  const account = H.useGetAccount(accountId, budgetId);
  let accountType = account.data?.type;

  // For CC accounts we want to skip BUDGET transactions in the balance totals.
  // `undefined` show all, we use this for any ASSET account.
  let internalTypes =
    accountType === C.CREDIT_CARD || accountType === C.DEBT_OTHER
      ? [
          Transactions.C.INTERNAL_TYPES.TRANSACTION,
          Transactions.C.INTERNAL_TYPES.INCOME,
          Transactions.C.INTERNAL_TYPES.SPLIT_TRANSACTION,
          Transactions.C.INTERNAL_TYPES.TRANSFER,
          Transactions.C.INTERNAL_TYPES.RECURRING,
        ]
      : undefined;

  // TODO: Rename account totals to account balances
  const accountTotals = H.useGetAccountTotals(
    accountId,
    budgetId,
    // TODO: In the future we will show only the month's transactions
    undefined,
    settings.data?.[0].budgeting_style,
    {
      internal_types: internalTypes?.join(','),
    }
  );

  // const categoryGroups = Categories.Hooks.useGetCategoryGroups(budgetId);
  const categoryGroups = CategoryGroups.H.useGetCategoryGroups(budgetId);
  const transactionsPaginated = Transactions.H.useGetTransactions(
    accountId,
    budgetId,
    transactionsPaginationState.pageIndex,
    {
      internal_types: internalTypes?.join(','),
    }
  );

  const mutateTransaction = Transactions.H.useMutateTransaction(
    accountId,
    budgetId,
    transactionsPaginationState.pageIndex
  );
  const patchEntry = Entries.Hooks.usePatchEntry();
  const createPayeeMutation = Payees.H.useCreatePayee(budgetId);
  // const createTransferMutation = useCreateTransferMutation(accountId, budgetId);

  const createTransactionMutation = Transactions.H.useCreateTransaction(
    accountId,
    budgetId
  );

  const deleteTransactionsMutation = Transactions.H.useDeleteTransactions(
    accountId,
    budgetId
  );

  let spendingAccounts: T.Account[] = [];
  let debtAccounts: T.Account[] = [];

  accounts?.data?.forEach((account) => {
    if (accountTypesSpending.includes(account.type)) {
      spendingAccounts.push(account);
    }

    if (accountTypesDebt.includes(account.type)) {
      debtAccounts.push(account);
    }
  });

  const onTransactionDelete: Transactions.T.OnTransactionDelete = (
    payload,
    options
  ) => {
    const msg = payload.length > 1 ? 'transactions' : 'transaction';
    const toastId = toast.loading(`Deleting ${msg}...`);

    deleteTransactionsMutation.mutate(payload, {
      onSuccess(data, variables, context) {
        toast.success(`Your ${msg} has been deleted.`, {
          id: toastId,
        });

        options?.onSuccess?.(
          data,
          // @ts-ignore
          variables,
          context
        );
      },
      onError(data, variables, context) {
        toast.error(
          `Could not delete your ${msg}. Not your fault! The error has been reported.`,
          {
            id: toastId,
          }
        );

        options?.onError?.(
          data,
          // @ts-ignore
          variables,
          context
        );
      },
    });
  };

  const onTransactionChange: Transactions.T.OnTransactionChange = (
    transaction
  ) => {
    const toastId = toast.loading('Updating transaction...');
    // @ts-ignore
    mutateTransaction.mutate(transaction, {
      onSuccess() {
        toast.success('Your transaction has been updated.', {
          id: toastId,
        });
      },
      onError(error) {
        toast.error(
          'Could not update your transaction. Not your fault! The error has been reported.',
          {
            id: toastId,
          }
        );
      },
    });
  };

  const onEntryChange: Transactions.T.OnEntryChange = (entry) => {
    const toastId = toast.loading('Updating transaction...');

    patchEntry.mutate(entry, {
      onSuccess() {
        toast.success('Your transaction has been updated.', {
          id: toastId,
        });
      },
      onError(error) {
        toast.error(
          'Could not update your transaction. Not your fault! The error has been reported.',
          {
            id: toastId,
          }
        );
      },
    });
  };

  /**
   * Creates a new payee and if API call succeeds, patch transaction with created
   * payee. Optional side effect.
   */
  const onPayeeCreate: Transactions.T.OnPayeeCreate = (
    payeeName,
    transactionId,
    callback
  ) => {
    const newPayee = {
      name: payeeName,
      budget_id: budgetId,
    };

    createPayeeMutation.mutate(newPayee, {
      onSuccess(data, variables, context) {
        // Only patch transaction if we are given a transaction id
        if (!_.isUndefined(transactionId)) {
          onTransactionChange({
            id: transactionId,
            payee_id: data.id,
          });
        }

        callback?.(data);
      },
      onSettled() {},
    });
  };

  const onTransferCreate: OnSubmit<TransferFormInputs> = (
    transfer,
    options
  ) => {
    invariant(
      categoryGroups.data,
      'We need category groups to be loaded before performing a transfer.'
    );

    const category = transfer.account;

    const newTransaction = {
      category_id: category,
      approved: true,
      ...transfer,
      amount: accounting
        .unformat(transfer.amount, settings.data?.[0].decimal)
        .toString(),
      budget_id: budgetId,
      date: localToUTCTime(transfer.date).toString(),
      source: 'WEB_MANUAL',
      internal_type: 'TRANSFER',
    };

    //  Remove account form object
    delete newTransaction['account'];

    const newTransfer = {
      ...newTransaction,
      type: Transactions.C.TYPES.OUTFLOW,
      account_id: accountId,
      transfer_to: {
        ...newTransaction,
        type: Transactions.C.TYPES.INFLOW,
        account_id: transfer.account,
      },
    };

    // @ts-ignore
    createTransactionMutation.mutate(newTransfer, {
      async onSuccess(data, variables, context) {
        // Refresh source account
        await queryClient.invalidateQueries({
          queryKey: [ACCOUNT_TOTALS_KEY, accountId, budgetId],
        });

        // Refresh target account
        await queryClient.invalidateQueries({
          queryKey: [ACCOUNT_TOTALS_KEY, transfer.account, budgetId],
        });

        options?.onSuccess?.(
          data,
          // @ts-ignore
          variables,
          context
        );
      },
      onError(data, variables, context) {
        options?.onError?.(
          data,
          // @ts-ignore
          variables,
          context
        );
      },
    });
  };

  const leftToBudgetAccount = internalAccounts.data?.find(
    (account) => account.name === 'Left to Budget'
  );

  /** Sends an API request to create a transaction */
  const onTransactionCreate: OnSubmit<Transactions.T.FormInputsTransaction> = (
    transaction,
    options
  ) => {
    invariant(leftToBudgetAccount, 'Left to Budget account is required.');

    const isIncome = transaction?.category === leftToBudgetAccount?.id;

    const internal_type = isIncome
      ? Transactions.C.INTERNAL_TYPES.INCOME
      : Transactions.C.INTERNAL_TYPES.TRANSACTION;

    const type = isIncome ? Transactions.C.TYPES.INFLOW : transaction.type;

    // TODO:
    // - [ ] Grab the Unassigned category
    // - [ ] Check if category is not selected
    // - [ ] Set category to Unassigned

    const newTransaction = {
      ...transaction,
      id: uuid(),
      amount: unformatMoney(transaction.amount),

      // The new Combobox gives us the value directly as string
      category_id: transaction.category,
      // The new Combobox gives us the value directly as string
      payee_id: transaction.payee,

      // type: transaction.type,
      type,
      source: Transactions.C.SOURCES.WEB_MANUAL,

      // memo: transaction.memo,
      approved: true,

      date: localToUTCTime(transaction.date).toString(),

      budget_id: budgetId,
      account_id: accountId,

      internal_type,
    } as unknown as Transactions.T.Transaction;

    // TODO: FIX
    // @ts-ignore
    createTransactionMutation.mutate(newTransaction, {
      async onSuccess(data, variables, context) {
        await queryClient.invalidateQueries({
          queryKey: [ACCOUNT_TOTALS_KEY, accountId, budgetId],
        });

        options?.onSuccess?.(
          data,
          // @ts-ignore
          variables,
          context
        );
      },
      onError(data, variables, context) {
        options?.onError?.(
          data,
          // @ts-ignore
          variables,
          context
        );
      },
    });
  };

  const result = [
    account,
    accounts,
    accountTotals,
    transactionsPaginated,
    payees,
    categoryGroups,
    settings,
  ];

  return match(result)
    .with(
      P.array({
        isLoading: false,
        isError: false,
        data: P.not(P.nullish),
      }),
      ([
        account,
        accounts,
        accountTotals,
        transactions,
        payees,
        categoryGroups,
        settings,
      ]) => {
        return (
          <Transactions.Page
            // TODO: FIX
            // @ts-ignore
            transactions={transactions.data}
            // TODO: FIX
            // @ts-ignore
            payees={payees.data}
            // TODO: FIX
            // @ts-ignore
            categoryGroups={categoryGroups.data}
            accountId={accountId}
            budgetId={budgetId}
            // TODO: FIX
            // @ts-ignore
            account={account.data}
            // TODO: FIX
            // @ts-ignore
            accounts={accounts.data}
            // TODO: FIX
            // @ts-ignore
            internalAccounts={internalAccounts.data}
            // TODO: FIX
            // @ts-ignore
            accountTotals={accountTotals}
            onTransactionChange={onTransactionChange}
            onEntryChange={onEntryChange}
            onPayeeCreate={onPayeeCreate}
            onTransactionCreate={onTransactionCreate}
            onTransactionDelete={onTransactionDelete}
            onTransferCreate={onTransferCreate}
            // TODO: FIX
            // @ts-ignore
            settings={settings.data[0] as Settings.Types.SettingsRead}
          />
        );
      }
    )
    .otherwise(() => <Loading />);
}

export { AccountPage };
