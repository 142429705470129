import { ComboboxOption } from '../components';
import * as Accounts from '../components/Account';

export const accountTypesSpending = [
  Accounts.C.CHECKING,
  Accounts.C.CASH,
  Accounts.C.GIFT_CARD,
  Accounts.C.SPENDING,
  Accounts.C.SAVINGS,
];

export const accountTypesDebt = [Accounts.C.CREDIT_CARD, Accounts.C.DEBT_OTHER];

export const accountTypesOffBudgetAssets = [
  Accounts.C.INVESTMENT,
  Accounts.C.OFF_BUDGET_ASSET,
];

export const accountTypesOffBudgetLiabilities = [
  Accounts.C.MORTGAGE,
  Accounts.C.OFF_BUDGET_LIABILITY,
];

// Pass in the account type value, and it'll return the formatted, printable version
const getAccountType = (type: string) => {
  if (accountTypesSpending.includes(type)) {
    return 'Spending & Savings';
  }

  if (accountTypesDebt.includes(type)) {
    return 'Credit Cards & Loans';
  }

  if (accountTypesOffBudgetAssets.includes(type)) {
    return 'Off-Budget Assets';
  }

  if (accountTypesOffBudgetLiabilities.includes(type)) {
    return 'Off-Budget Liabilities';
  }
};

const accountTypeOptions: ComboboxOption[] = [
  {
    label: 'Spending & Savings',
    value: [
      {
        label: 'Checking',
        value: Accounts.C.CHECKING,
      },
      {
        label: 'Cash',
        value: Accounts.C.CASH,
      },
      {
        label: 'Prepaid Cash',
        value: Accounts.C.GIFT_CARD,
      },
      {
        label: 'Spending Other',
        value: Accounts.C.SPENDING,
      },
      {
        label: 'Savings',
        value: Accounts.C.SAVINGS,
      },
    ],
  },
  {
    label: 'Debt',
    value: [
      {
        label: 'Credit Card',
        value: Accounts.C.CREDIT_CARD,
      },
      {
        label: 'Debt - Other',
        value: Accounts.C.DEBT_OTHER,
      },
    ],
  },
  // {
  //   label: 'Off Budget',
  //   value: [
  //     {
  //       label: 'Mortgage',
  //       value: Accounts.C.MORTGAGE,
  //     },
  //     {
  //       label: 'Investment',
  //       value: Accounts.C.INVESTMENT,
  //     },
  //
  //     {
  //       label: 'Asset - Other',
  //       value: Accounts.C.OFF_BUDGET_ASSET,
  //     },
  //
  //     {
  //       label: 'Liability - Other',
  //       value: Accounts.C.OFF_BUDGET_LIABILITY,
  //     },
  //   ],
  // },
];

export { accountTypeOptions, getAccountType };
