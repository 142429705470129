import { useQuery } from '@tanstack/react-query';
import { api } from '../../api';
import * as C from './FileImports.constants';
import { getFileImports } from './FileImports.req';

// Used for loaders
let getFileImportsQuery = (budgetId: string) => {
  return {
    queryKey: [C.FILE_IMPORTS_QUERY_KEY, budgetId],
    queryFn: () => getFileImports(budgetId),
  };
};

const useGetFileImports = (budgetId: string) => {
  return useQuery(getFileImportsQuery(budgetId));
};

export const useFileImports = () => {
  const fetchFileImports = async () => {
    return api.get(C.API_URL);
  };

  return {
    fetchFileImports,
  };
};

export { useGetFileImports, getFileImportsQuery };
