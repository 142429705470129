import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as CategoryGroups from '../CategoryGroups';
import * as C from './Categories.constants';
import { getCategories, postCategory, patchCategory } from './Categories.req';

const usePostCategory = (budgetId: string) => {
  const queryClient = useQueryClient();
  const categoryGroupsQueryKey = [CategoryGroups.C.QUERY_KEY, budgetId];
  return useMutation({
    mutationKey: [C.CATEGORY_MUTATION_KEY],
    mutationFn: postCategory,
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: categoryGroupsQueryKey,
      });
    },
  });
};

const useGetCategories = (budgetId: string) =>
  useQuery({
    queryKey: [C.CATEGORIES_QUERY_KEY, budgetId],
    queryFn: () => getCategories(budgetId),
  });

const usePatchCategory = () => {
  const queryClient = useQueryClient();
  const categoryQueryKey = [C.CATEGORY_PATCH_KEY];
  return useMutation({
    mutationKey: [C.CATEGORY_PATCH_KEY],
    mutationFn: patchCategory,
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: categoryQueryKey,
      });
    },
  });
};

export { useGetCategories, usePostCategory, usePatchCategory };
