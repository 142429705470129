import { QueryClient } from '@tanstack/react-query';
import { defer, Params } from 'react-router-dom';
import { getFileImportsQuery } from './FileImports.hooks';
import * as Accounts from '../Account';

function loader(queryClient: QueryClient, params: Params) {
  return defer({
    fileImports: () => {
      return queryClient.ensureQueryData(
        getFileImportsQuery(String(params.budgetId))
      );
    },
    accounts: () => {
      return queryClient.ensureQueryData(
        Accounts.H.getAccountsQuery(String(params.budgetId))
      );
    },
  });
}

export { loader };
