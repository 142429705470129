import { cva } from 'class-variance-authority';

const base = [''];

const horizontal = ['px-0', 'w-full'];
const vertical = ['py-0', 'h-full'];

const dividerStyles = cva(base, {
  variants: {
    size: {
      sm: ['p-1'],
      md: ['p-2'],
      lg: ['p-3'],
      xl: ['p-4'],
    },
    orientation: {
      horizontal,
      vertical,
    },
  },
  defaultVariants: {
    size: 'md',
    orientation: 'horizontal',
  },
});

export { dividerStyles };
