import { ChangeEvent, useState } from 'react';
import { match } from 'ts-pattern';
import { useFocusWithin, FocusScope } from 'react-aria';
import moment from 'moment';
import { ColumnDef } from '@tanstack/react-table';
import { Transaction } from '../../Transactions.types';
import { Input, Box } from '../../../Common';
import { cx, UTCToLocalTime, localToUTCTime } from '../../../../utils';

const dateColumn: Partial<ColumnDef<Transaction>> = {
  cell: ({
    getValue,
    row: { index, getValue: getRowValue, original, getIsSelected },
    column: { id },
    table,
  }) => {
    /*
     * Remember that any date that the user interacts with shows in loca, but it
     * comes as UTC from the API, so we need to convert anything that either displays
     * or receives input from the user as local.
     */

    // const isFocused = table.options.meta && table.options.meta.rowFocus[index];
    // const isSelected = getIsSelected();
    const settings = table.options.meta?.settings;

    /* eslint-disable react-hooks/rules-of-hooks */
    const [isFocusWithin, setFocusWithin] = useState(false);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithin: (event) => {
        setFocusWithin(true);
      },
      onBlurWithin: (event) => {
        setFocusWithin(false);
      },
    });

    // const initialValue = getValue();
    const dateObj = original.date;

    // We get UTC from database
    const UTCDate = moment(dateObj as string).toString();

    // Convert to local for user interactions
    // const localDate = UTCToLocalTime(UTCDate, 'MM/DD/YYYY');
    const localDate = UTCToLocalTime(UTCDate, settings?.date_format);

    const localDateForInput = UTCToLocalTime(UTCDate, 'YYYY-MM-DD');

    const updateCallback = (event: ChangeEvent<HTMLInputElement>) => {
      // We get local from user input, but we convert to UTC on callback
      const newDate = event.target.value;
      const UTCDate = localToUTCTime(newDate, 'YYYY-MM-DD');

      if (!dateObj?.toString().includes(newDate)) {
        if (original.date !== newDate && newDate !== '') {
          table.options.meta?.onTransactionChange(index, id, {
            id: original.id,
            date: UTCDate,
          });
        }
      }
    };

    /* eslint-enable */
    return (
      <Box
        tabIndex={isFocusWithin ? -1 : 0}
        className={cx(['w-40'])}
        {...focusWithinProps}
      >
        {match(isFocusWithin)
          .with(false, () => localDate)
          .with(true, () => {
            return (
              <Input
                autoFocus
                containerClasses={cx(['w-40'])}
                label="Transaction date"
                hideLabel
                type="date"
                defaultValue={localDateForInput}
                onBlur={updateCallback}
              />
            );
          })
          .otherwise(() => null)}
      </Box>
    );
  },
};

export { dateColumn };
