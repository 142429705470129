import { cva } from 'class-variance-authority';

const base = [
  'block',
  'w-full',
  'rounded-md',
  'border-0',
  'py-1.5',
  'text-gray-900',
  // 'shadow-sm',
  'text-ellipsis',
  'ring-1',
  'ring-inset',
  'ring-ew-border',
  'placeholder:text-sm',
  'placeholder:text-gray-400',
  'focus:ring-2',
  'focus:ring-inset',
  'focus:ring-ew-primary',
  'dark:text-gray-200',
  'dark:bg-ew-bg-dark',
  'dark:ring-ew-border-dark',
  'dark:focus:ring-ew-primary',
];

const errors = [
  'text-ew-error',
  'ring-ew-error',
  'placeholder:text-ew-error',
  'focus:ring-ew-error',
  'dark:ring-ew-error-400',
  'dark:text-ew-error-400',
  'dark:placeholder:text-ew-error-400',
  'dark:focus:ring-ew-error-400',
];

const disabled = [
  'disabled:cursor-not-allowed',
  'disabled:bg-gray-50',
  'disabled:text-gray-500',
  'disabled:ring-gray-200',
  'dark:disabled:bg-ew-bg-dark-600',
];

// const forMoney = ['pl-7', 'pr-12'];
const forMoney = ['pl-7'];

const inputStyles = cva(base, {
  variants: {
    intent: {},
    disabled: {
      true: disabled,
    },
    size: {},
    hasErrors: {
      true: errors,
    },
    forMoney: {
      true: forMoney,
    },
  },
  defaultVariants: {
    // size: "md",
  },
});

export { inputStyles, base, disabled, errors, forMoney };
