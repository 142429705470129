import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { api } from '../../api';
import { Box, Divider, Public } from '../Common';
import { cx } from '../../utils';
import { SignInForm } from './SignIn.form';
import { UserPayload } from './SignIn.types';
import * as User from '../User';
import { UserAPIResponse } from '../User/User.types';

const userLogin = async (userPayload: UserPayload) => {
  const response: AxiosResponse<UserAPIResponse> = await api.post(
    '/auth/login/',
    userPayload
  );

  return response.data;
};

const useUserLogin = () => {
  return useMutation<UserAPIResponse, AxiosError, UserPayload>({
    mutationKey: ['userSignIn'],
    mutationFn: userLogin,
    // onSuccess: (data, variables, context) => {},
    // onError: (data, variables, context) => {},
    // onSettled: (data, error, variables, context) => {},
  });
};

const SignInPage = () => {
  const setUser = User.Store.useUserStore((state) => state.setUser);
  const login = useUserLogin();
  const navigate = useNavigate();

  const handleSubmit = (data: UserPayload) => {
    const toastId = toast.loading('Signing in...');
    login.mutate(data, {
      onSuccess(response) {
        setUser(response.user);

        toast.success('Ok good. Redirecting you to your account', {
          id: toastId,
        });

        navigate('/b/' + response.user.default_budget);
      },
      onError(error, variables) {
        if (isAxiosError(error)) {
          switch (error.response?.status) {
            case 400:
              toast.error('Invalid credentials', {
                id: toastId,
              });
              break;
            case 401:
              toast.error('Invalid credentials', {
                id: toastId,
              });
              break;
            default:
              toast.error('Unknown error.', {
                id: toastId,
              });
          }
        }
      },
    });
  };

  return (
    <Public>
      <Box className={cx(['w-96', 'mx-auto', 'my-auto'])}>
        <img
          className={cx(['h-12', 'w-auto', 'mx-auto'])}
          src="https://beta.budgetwise.io/img/budgetwise_badge.png"
          alt=""
        />
        <Divider size="xl" />
        <Box
          className={cx([
            'bg-white',
            'px-8',
            'py-6',
            'rounded-xl',
            'border',
            'border-gray-300',
            // 'shadow',
          ])}
        >
          <h1
            className={cx([
              'text-2xl',
              'text-gray-600',
              'font-bold',
              'text-center',
            ])}
          >
            Sign in to your account
          </h1>
          <Divider size="xl" />
          <SignInForm onSubmit={handleSubmit} isLoading={login.isPending} />
          <Divider size="md" />
          <Box className={cx(['text-center'])}>
            <Link
              to="/password-reset"
              className={cx(['text-gray-600', 'underline', 'font-semibold'])}
            >
              Forgot your password?
            </Link>
          </Box>
        </Box>
        {/*<Divider size="md" />*/}
        {/*<Box className={cx(['text-center'])}>*/}
        {/*  New to Budgetwise?{' '}*/}
        {/*  <Link*/}
        {/*    to="/sign-up"*/}
        {/*    className={cx(['text-gray-600', 'underline', 'font-semibold'])}*/}
        {/*  >*/}
        {/*    Create an account*/}
        {/*  </Link>*/}
        {/*</Box>*/}
      </Box>
    </Public>
  );
};

export { SignInPage };
