const trigger = [
  'flex',
  'w-full',
  'items-center',
  'justify-between',
  'rounded-md',
  'border',
  'border-1',
  'border-gray-300',
  'px-2.5',
  'py-1.5',
  'text-sm',
  // 'focus:outline-none',
  // 'focus:border',
  // 'focus:border-ew-primary',
  'placeholder:text-muted-foreground',
  'disabled:cursor-not-allowed',
  'disabled:opacity-50',
];

const select = [
  'relative',
  'z-50',
  'min-w-[5rem]',
  'overflow-hidden',
  'rounded-md',
  'border',
  'shadow-md',
  'data-[state=open]:animate-in',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:fade-out-0',
  'data-[state=open]:fade-in-0',
  'data-[state=closed]:zoom-out-95',
  'data-[state=open]:zoom-in-95',
  'data-[side=bottom]:slide-in-from-top-2',
  'data-[side=left]:slide-in-from-right-2',
  'data-[side=right]:slide-in-from-left-2',
  'data-[side=top]:slide-in-from-bottom-2',
  'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
];

const viewport = [
  'h-[var(--radix-select-trigger-height)]',
  'w-full',
  'min-w-[var(--radix-select-trigger-width)]',
];

const item = [
  'relative',
  'flex',
  'w-full',
  'cursor-default',
  'select-none',
  'items-center',
  'rounded-sm',
  'py-1.5',
  'pr-8',
  'pl-2',
  'text-sm',
  'outline-none',
  'hover:bg-ew-primary',
  'group',
  'hover:text-white',
  // 'focus:bg-accent',
  // 'focus:text-accent-foreground',
  'data-[disabled]:pointer-events-none',
  'data-[disabled]:opacity-50',
];

export { trigger, select, viewport, item };
