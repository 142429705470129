import { ReactElement } from 'react';
import { IconType } from 'react-icons';
import { FaPlus } from 'react-icons/fa';
import { cx } from '../../../utils';
import { Box } from '../Box';
import { Button } from '../Button';

type EmptyProps = {
  heading: string;
  content?: string;
  icon?: IconType;
  actionLabel?: string;
  action?: () => void;
};

type EmptyComponent = (props: EmptyProps) => ReactElement | null;
const Empty: EmptyComponent = ({
  heading,
  content,
  icon: Icon,
  action,
  actionLabel,
}) => {
  return (
    <Box
      className={cx([
        'text-center',
        'border-2',
        'border-gray-300',
        'dark:border-gray-600',
        'border-dashed',
        'rounded-xl',
        'p-12',
      ])}
    >
      <Box className={cx(['flex', 'justify-center'])}>
        {Icon && (
          <Icon
            className={cx([
              'w-12',
              'h-12',
              'text-gray-500',
              'dark:text-ew-text-dark',
            ])}
          />
        )}
      </Box>
      <h3 className="mt-2 text-lg font-semibold text-gray-900 dark:text-ew-text-dark">
        {heading}
      </h3>
      <p className="mt-1 text-base text-gray-500 dark:text-ew-text-dark">
        {content}
      </p>
      <div className="mt-6">
        {action && actionLabel && (
          <Button
            size="xl"
            intent="primary"
            icon={FaPlus}
            onClick={() => action?.()}
          >
            {actionLabel}
          </Button>
        )}
      </div>
    </Box>
  );
};

export { Empty };
