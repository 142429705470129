import { Sidebar } from './Sidebar';

import { SidebarAccountLink } from './SidebarAccountLink';
import { SidebarPageLink } from './SidebarPageLink';

import * as Store from './Sidebar.store';
import * as Types from './Sidebar.types';

const AccountLink = SidebarAccountLink;
const PageLink = SidebarPageLink;
const $ = Sidebar;

export { AccountLink, $, Store, Types, PageLink };
