import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as C from './Settings.constants';
import * as T from './Settings.types';
import * as req from './Settings.req';

const useGetSettings = (budgetId: string) => {
  return useQuery<T.SettingsRead[], AxiosError>({
    queryKey: [C.SETTINGS_QUERY_KEY, budgetId],
    queryFn: () => req.getSettings(budgetId),
  });
};

const usePutSettings = (budgetId: string) => {
  const queryClient = useQueryClient();
  const queryKey = [C.SETTINGS_QUERY_KEY, budgetId];
  return useMutation({
    mutationKey: [C.SETTINGS_MUTATION_KEY],
    mutationFn: req.putSettings,
    onMutate: async (settings) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousSettings = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (old: T.SettingsRead[] | undefined) => {
          if (!old) return old;
          return old.map((s) =>
            s.id === settings.id ? { ...s, ...settings } : s
          );
        }
      );

      return { previousSettings };
    },

    onError: (err, newSettings, context) => {
      queryClient.setQueryData(queryKey, context?.previousSettings);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });
};

export { useGetSettings, usePutSettings };
