import { api } from '../../../../api';
import * as T from '../../Budget.types';
import * as C from '../../Budget.constants';

const importBudget = async (
  data: T.BudgetExport[],
  budgetId: string,
  periodId: string
) => {
  const response = await api.post(C.API_URL + '/' + budgetId + '/import/', {
    period_id: periodId,
    budget_items: data,
  });

  return response.data;
};

export { importBudget };
