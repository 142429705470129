const API_URL = 'transactions';
const DAYS_OF_MONTH = [
  { value: 1, label: '1st' },
  { value: 2, label: '2nd' },
  { value: 3, label: '3rd' },
  { value: 4, label: '4th' },
  { value: 5, label: '5th' },
  { value: 6, label: '6th' },
  { value: 7, label: '7th' },
  { value: 8, label: '8th' },
  { value: 9, label: '9th' },
  { value: 10, label: '10th' },
  { value: 11, label: '11th' },
  { value: 12, label: '12th' },
  { value: 13, label: '13th' },
  { value: 14, label: '14th' },
  { value: 15, label: '15th' },
  { value: 16, label: '16th' },
  { value: 17, label: '17th' },
  { value: 18, label: '18th' },
  { value: 19, label: '19th' },
  { value: 20, label: '20th' },
  { value: 21, label: '21st' },
  { value: 22, label: '22nd' },
  { value: 23, label: '23rd' },
  { value: 24, label: '24th' },
  { value: 25, label: '25th' },
  { value: 26, label: '26th' },
  { value: 27, label: '27th' },
  { value: 28, label: '28th' },
  { value: 29, label: '29th' },
  { value: 30, label: '30th' },
  { value: 31, label: '31st' },
];

const INFLOW = 'INFLOW';
const OUTFLOW = 'OUTFLOW';

const TYPES = {
  INFLOW,
  OUTFLOW,
};

const SOURCES = {
  WEB_MANUAL: 'WEB_MANUAL',
  WEB_IMPORT: 'WEB_IMPORT',
  INITIAL_BALANCE: 'INITIAL_BALANCE',
};

const INTERNAL_TYPES = {
  TRANSFER: 'TRANSFER',
  INCOME: 'INCOME',
  TRANSACTION: 'TRANSACTION',
  SPLIT_TRANSACTION: 'SPLIT_TRANSACTION',
  BUDGET: 'BUDGET',
  RECURRING: 'RECURRING',
};

const TRANSACTIONS_QUERY_KEY = 'transactions';
const TRANSACTIONS_CREATE_KEY = 'createTransaction';

const TRANSACTIONS_UPSERT_KEY = 'UPSERT_TRANSACTION';

const DELETE_QUERY_KEY = 'deleteTransactions';

/**
 * Double-entry specific constants
 */

const DEBIT = 'DEBIT';
const CREDIT = 'CREDIT';

const DOUBLE_ENTRY_DIRECTIONS = {
  DEBIT,
  CREDIT,
};

const DATE_RANGES = [
  { label: 'This Month', value: 'THIS_MONTH' },
  { label: 'Last 90 Days', value: '90_DAYS' },
  { label: 'Last 6 Months', value: '6_MONTHS' },
  { label: 'All', value: 'ALL' },
];

// Make sure pagination settings match the backend.
const PAGINATION_LIMIT = 20;
const PAGINATION_OFFSET = 20;

export {
  PAGINATION_LIMIT,
  PAGINATION_OFFSET,
  API_URL,
  DAYS_OF_MONTH,
  TRANSACTIONS_QUERY_KEY,
  TRANSACTIONS_CREATE_KEY,
  TRANSACTIONS_UPSERT_KEY,
  INFLOW,
  OUTFLOW,
  TYPES,
  SOURCES,
  INTERNAL_TYPES,
  DATE_RANGES,
  // Double-entry
  DOUBLE_ENTRY_DIRECTIONS,
  DELETE_QUERY_KEY,
};
