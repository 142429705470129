const API = import.meta.env.VITE_API;
const PADDLE_MONTH = import.meta.env.VITE_PADDLE_MONTH;
const PADDLE_YEAR = import.meta.env.VITE_PADDLE_YEAR;
const PADDLE_LIFETIME = import.meta.env.VITE_PADDLE_LIFETIME;
const PADDLE_TRIAL = import.meta.env.VITE_PADDLE_TRIAL;
const TELEGRAM_BOT_NAME = import.meta.env.VITE_TELEGRAM_BOT_NAME;

const API_ENDPOINTS = {
  ENTRIES: '/entries/',
};

export {
  TELEGRAM_BOT_NAME,
  API,
  API_ENDPOINTS,
  PADDLE_MONTH,
  PADDLE_YEAR,
  PADDLE_LIFETIME,
  PADDLE_TRIAL,
};
