import * as React from 'react';
import { Box, Button } from '../../Common';
import { cx } from '../../../utils';
import { FaSpinner } from 'react-icons/fa';

type TablePaginationProps = React.ComponentPropsWithoutRef<'nav'> & {
  itemsCount: number;
  pageIndex: number;
  pageCount: number;
  goToPreviousPage: () => void;
  canGoToPreviousPage: boolean;
  goToNextPage: () => void;
  canGoToNextPage: boolean;
  isLoading?: boolean;
};

const TablePagination = React.forwardRef<'nav', TablePaginationProps>(
  ({
    itemsCount,
    pageIndex,
    pageCount,
    goToPreviousPage,
    canGoToPreviousPage,
    goToNextPage,
    canGoToNextPage,
    isLoading,
  }) => {
    return (
      <Box
        as="nav"
        className={cx([
          'flex',
          'items-center',
          'justify-between',
          'px-4',
          'py-3',
          'bg-white',
        ])}
      >
        Showing {pageIndex + 1} of {pageCount} results ({itemsCount}{' '}
        transactions)
        <Box
          className={cx([
            'flex',
            'justify-between',
            'items-center',
            'space-x-3',
            'text-gray-500',
          ])}
        >
          {isLoading ? (
            <FaSpinner className={cx(['text-2xl', 'animate-spin'])} />
          ) : null}
          <Button
            onClick={() => goToPreviousPage()}
            intent="secondary"
            disabled={!canGoToPreviousPage}
          >
            Previous
          </Button>
          <Button
            onClick={() => goToNextPage()}
            intent="secondary"
            disabled={!canGoToNextPage}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
);

export { TablePagination };
