import { cva } from "class-variance-authority";

const base = ["rounded-md", "p-4"];

/** Intent */
const warning = [""];

const success = [""];

const error = [""];

const info = [""];

const alertStyles = cva(base, {
  variants: {
    intent: {
      warning,
      success,
      error,
      info,
    },
  },
  defaultVariants: {
    intent: "success",
  },
});

export { alertStyles };
