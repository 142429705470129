import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as C from './Payees.constants';
import * as T from './Payees.types';
import { getPayees, postPayee } from './Payees.req';

// GET
const useGetPayees = (budgetId: string) => {
  return useQuery({
    queryKey: [C.PAYEES_QUERY_KEY, budgetId],
    queryFn: () => getPayees(budgetId),
  });
};

// POST
const useCreatePayee = (budgetId: string) => {
  const queryClient = useQueryClient();
  const MUTATION_KEY = [C.PAYEES_MUTATION_KEY, budgetId];
  const QUERY_KEY = [C.PAYEES_QUERY_KEY, budgetId];

  return useMutation({
    mutationKey: MUTATION_KEY,
    mutationFn: (newPayee: T.PayeeWrite) => postPayee(newPayee),
    onMutate: async (newPayee) => {
      await queryClient.cancelQueries({ queryKey: QUERY_KEY });

      const previousPayees = queryClient.getQueryData(QUERY_KEY);

      queryClient.setQueryData(QUERY_KEY, (oldPayees: T.PayeeRead[]) => {
        return oldPayees?.map((previousPayee) => {
          // We can't use `id` because that's created in the server, and we don't
          // have access to it at this point.
          if (previousPayee.name === newPayee.name) {
            return newPayee;
          }

          return previousPayee;
        });
      });

      return {
        previousPayees,
      };
    },
    onError: (err, newPayee, context) => {
      queryClient.setQueryData(QUERY_KEY, context?.previousPayees);
    },

    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEY,
      });
    },
  });
};

// Alias
const usePayeePost = useCreatePayee;

export { useGetPayees, useCreatePayee, usePayeePost };
