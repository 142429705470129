import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
// import type {} from '@redux-devtools/extensions';
import { UserAPIResponse } from './User.types';

type UserState = {
  user: UserAPIResponse | null;
  setUser: (user: any) => void;
};

const useUserStore = create<UserState>()(
  devtools(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),
    }),
    {
      name: 'userStore',
    }
  )
);

export { useUserStore };
