import React, { forwardRef } from 'react';
import { FaCheck, FaChevronDown } from 'react-icons/fa';
import * as $ from '@radix-ui/react-select';
import * as styles from './Select.styles';
import * as T from './Select.types';
import { Box } from '../Box';
import { cx } from '../../../utils';
import { match } from 'ts-pattern';
import { CgSpinner } from 'react-icons/cg';

const Select = forwardRef<T.SelectElement, T.SelectProps>(
  (
    {
      label,
      options,
      isLoading = false,
      placeholder,
      hideLabel = false,
      fullWidth,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <Box>
        <label
          className={cx(
            [
              'block',
              'text-sm',
              'font-medium',
              'leading-6',
              'text-gray-900',
              'dark:text-white',
            ],
            { 'sr-only': hideLabel }
          )}
        >
          {label}
        </label>
        <Box
          className={cx(['text-gray-600'], {
            'mt-2': !hideLabel,
            'w-full': fullWidth,
          })}
        >
          <$.Root {...props}>
            <$.Trigger
              className={cx(
                [
                  styles.trigger,
                  'w-[180px]',
                  'bg-white',
                  'ring-1',
                  'ring-inset',
                  'ring-ew-border',
                ],
                {
                  'w-full': fullWidth,
                },
                [props.triggerClasses]
              )}
              ref={forwardedRef}
            >
              <$.Value placeholder={placeholder} />
              <$.Icon asChild>
                {match(isLoading)
                  .with(true, () => (
                    <CgSpinner
                      className={cx([
                        'h-4',
                        'w-4',
                        'shrink-0',
                        'animate-spin',
                        props.iconClasses,
                      ])}
                    />
                  ))
                  .otherwise(() => (
                    <FaChevronDown
                      className={cx([
                        'h-4',
                        'w-4',
                        'shrink-0',
                        props.iconClasses,
                      ])}
                    />
                  ))}
              </$.Icon>
            </$.Trigger>
            <$.Portal>
              <$.Content
                className={cx([
                  styles.select,
                  'bg-white',
                  props.dropdownClasses,
                ])}
                position="popper"
              >
                <$.Viewport
                  className={cx([styles.viewport, props.viewportClasses])}
                >
                  {options.map(({ label, value }) => (
                    <$.Item
                      key={String(value)}
                      value={String(value)}
                      className={cx([styles.item])}
                    >
                      <$.ItemText>{label}</$.ItemText>

                      <span
                        className={cx([
                          'absolute',
                          'right-2',
                          'flex',
                          'h-3.5',
                          'w-3.5',
                          'items-center',
                          'justify-center',
                        ])}
                      >
                        <$.ItemIndicator>
                          <FaCheck className={cx(['h-4', 'w-4'])} />
                        </$.ItemIndicator>
                      </span>
                    </$.Item>
                  ))}
                </$.Viewport>
              </$.Content>
            </$.Portal>
          </$.Root>
        </Box>
      </Box>
    );
  }
);

export { Select };
