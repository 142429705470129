import { ColumnDef } from '@tanstack/react-table';
import { Box, Button } from '../../Common';
import { useState } from 'react';
import { Input } from '../../Common';
import * as Category from '../../Categories';

const categoryName: Partial<ColumnDef<Category.T.Category>> = {
  cell: ({
    getValue,
    row: {
      index,
      original,
      getIsSelected,
      getCanExpand,
      getToggleExpandedHandler,
      getIsExpanded,
    },
    // column: { id: columnId },
    // table,
  }) => {
    const initialValue = getValue<string>();

    /* eslint-disable react-hooks/rules-of-hooks */
    const [value, setValue] = useState(initialValue);

    /* eslint-enable */
    // return <Input label="Name" hideLabel />;
    // return value;
    // {getCanExpand() && (
    //   <Button onClick={getToggleExpandedHandler()}>Can expand</Button>
    // )}
    return <Box>{value}</Box>;
  },
};

export { categoryName };
