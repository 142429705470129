import * as React from 'react';
import invariant from 'tiny-invariant';
import { match, P } from 'ts-pattern';
import * as _ from 'lodash-es';
import { useState, useMemo, useEffect } from 'react';
import { useFocusWithin } from 'react-aria';
import { ColumnDef } from '@tanstack/react-table';
import { TableTransaction } from '../../Transactions.types';
import { Box, Badge, ComboboxOption } from '../../../Common';
import * as Combobox from '../../../Common/Combobox';
import { cx } from '../../../../utils';
// import { getOppositeAccountEntry } from '../../Transactions.utils';
import { Key } from 'react-aria-components';
import * as C from '../../Transactions.constants';

/**
 * A category in Budgetwise is a double entry account.
 *
 * This component will behave differently than the other columns because we are
 * affecting the Entries directly and not the Transactions only.
 *
 * A category in the frontend is an account in the backend. Specifically an
 * `Account._as='CATEGORY'`.
 */
const categoryColumn: Partial<ColumnDef<TableTransaction>> = {
  cell: ({
    getValue,
    row: { index, original, getIsSelected },
    column: { id },
    table,
  }) => {
    invariant(
      original.entries,
      `The entries array for this transaction (${original.id}) is empty. Something
    when wrong when creating the transaction.`
    );

    /* eslint-disable react-hooks/rules-of-hooks */
    let initialValue = getValue<string>();
    let [value, setValue] = React.useState(initialValue);

    const isTransfer = original.internal_type === 'TRANSFER';

    const [isFocusWithin, setFocusWithin] = useState(false);

    const { focusWithinProps } = useFocusWithin({
      onFocusWithin: (event) => {
        setFocusWithin(true);
      },
      onBlurWithin: (event) => {
        setFocusWithin(false);
      },
    });

    // const currentMont = getCurrentMonthName();
    // const initialValue = getValue<Accounts.T.AccountRead>();
    const categoryObj = original.category;

    // We add regular ASSET and LIABILITY accounts to the category list
    // to allow transfers when selecting one of them.
    let accounts = table.options.meta?.accounts;
    let accountsForTransfer: ComboboxOption[] =
      accounts?.map((account) => {
        return {
          label: account.name,
          value: account.id,
        };
      }) || [];

    let groupOfAccounts = [
      {
        label: 'Accounts (Transfer)',
        value: accountsForTransfer,
      },
    ];

    const categoryGroups = table.options.meta?.categoryGroups ?? [];
    // const selectedCategory = findCategory(initialValue.name, categoryGroups);

    // We could have memoized this at the Account.Page level, but I prefer to have the structure as the API returned it
    // through Account.Page > Transactions.Page > Transactions.Table in case we need to read it.
    // We format from CategoryGroupsRead[] to ComboboxOption[]
    const categoryGroupsFormatted: ComboboxOption[] = useMemo(() => {
      let formattedCategoryGroups = categoryGroups.map(
        ({ name, id, accounts }) => {
          if (Array.isArray(accounts) && !_.isEmpty(accounts)) {
            // format nested options
            const nestedOptions = accounts.map((account) => ({
              label: account.name,
              value: account.id,
            }));
            return {
              label: name,
              value: nestedOptions,
            };
          }
          return {
            label: name,
            value: id,
          };
        }
      );

      return [...formattedCategoryGroups, ...groupOfAccounts];
    }, [categoryGroups, table.options.meta?.budgetId]);

    const onSelect = (option: Key | null) => {
      let selected: ComboboxOption = { label: '', value: '' };

      // When we select an item manually, the Combobox grabs the id, but when
      // we don't select anything and blur out, the Combobox grabs the last
      // selected element's label. So we need to look for both the id and the
      // label.
      categoryGroupsFormatted.forEach((categoryGroup) => {
        if (Array.isArray(categoryGroup.value)) {
          categoryGroup.value.forEach((category) => {
            if (
              category.value === String(option) ||
              category.label === String(option)
            ) {
              selected = category;
            }
          });
        }

        // If the option isn't an array, then it's a category. Renaming just to
        // keep things semantic.
        let category = categoryGroup;

        if (
          category.value === String(option) ||
          category.label === String(option)
        ) {
          selected = category;
        }
      });

      if (_.isEmpty(selected?.label)) {
        return;
      }

      // Only update when the selected value is different from the initial one
      if (selected?.label !== value) {
        setValue(selected.label);

        // - [x] TODO: Add logic for transfers
        let isTransfer = accountsForTransfer.find(
          ({ value }) => selected.value === value
        );

        if (!!isTransfer) {
          return table.options.meta?.onTransactionChange?.(index, id, {
            id: String(original.id),
            category_id: String(option),
            internal_type: 'TRANSFER',
          });
        }

        // Logic for regular transactions
        // The backend updates the entry and transaction for us on the transaction endpoint
        table.options.meta?.onTransactionChange?.(index, id, {
          id: String(original.id),
          category_id: String(option),
        });
      }
    };

    // If value is mutated externally, sync it with the state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    // if (original.source === C.SOURCES.INITIAL_BALANCE) {
    //   return (
    //     <div className={cx(['max-w-full'])}>
    //       <Badge intent="warning">Uncategorized</Badge>
    //     </div>
    //   );
    // }

    return (
      <Box
        // Allow skipping this element if we want to go back, i.e., Shift+Tab
        tabIndex={isFocusWithin ? -1 : 0}
        className={cx(['w-40'])}
        {...focusWithinProps}
      >
        {match([isFocusWithin, categoryObj, isTransfer])
          .with([P._, P._, true], () => {
            const msg =
              original.account_id === table.options.meta?.accountId
                ? 'Transfer to ' + original.category?.name
                : 'Transfer from ' + original.account?.name;
            return (
              <Badge className={cx(['truncate'])} intent="secondary">
                {msg}
              </Badge>
            );
          })
          .with([false, { _as: 'LEFT_TO_BUDGET' }, P._], () => {
            return (
              <Box
                className={cx(['truncate', 'overflow-hidden', 'text-ellipsis'])}
              >
                {/*<Badge intent="primary">Income for {currentMont}</Badge>*/}
                <Badge intent="success">Income</Badge>
              </Box>
            );
          })
          .with([false, { name: 'Unassigned' }, P._], () => {
            return (
              <Box
                className={cx(['truncate', 'overflow-hidden', 'text-ellipsis'])}
              >
                <Badge intent="warning">Uncategorized</Badge>
              </Box>
            );
          })
          .with([false, P._, false], () => categoryObj?.name)
          .with([true, P._, false], ([focusedWithin]) => (
            <Combobox.$
              hideLabel
              defaultInputValue={value}
              defaultItems={categoryGroupsFormatted}
              onSelectionChange={onSelect}
              isRequired
              validationBehavior="aria"
              isLoading={false}
              autoFocus
              onFocus={(event) => {
                event.currentTarget.select();
              }}
            >
              {(data: Combobox.T.ComboboxOption) => {
                return match(data)
                  .with({ value: P.array(P.not(P.nullish)) }, ({ value }) => {
                    return (
                      <Combobox.Section
                        title={data.label}
                        id={String(value)}
                        items={Array.isArray(value) ? value : []}
                      >
                        {(item: Combobox.T.ComboboxOption) => (
                          <Combobox.Item id={String(item.value)}>
                            {item.label}
                          </Combobox.Item>
                        )}
                      </Combobox.Section>
                    );
                  })
                  .with({ value: P.string }, () => (
                    <Combobox.Item id={String(data.value)}>
                      {data.label}
                    </Combobox.Item>
                  ))
                  .otherwise(() => <div />);
              }}
            </Combobox.$>
          ))
          .otherwise(() => (
            <div />
          ))}
      </Box>
    );
  },
};

export { categoryColumn };
