import { PropsWithChildren, ReactChild, ReactElement } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { FaArrowRightFromBracket } from 'react-icons/fa6';
import { cx } from '../../../utils';
import { Box } from '../Box';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { IconButton } from '../IconButton';
import { Divider } from '../Divider';
import * as Sidebar from '../Sidebar';
import * as SignOut from '../../SignOut';

type LayoutProps = {
  title?: string;
  subtitle?: string;
  primaryActionLabel?: string;
  primaryAction?: () => void;
  secondaryActionLabel?: string;
  secondaryAction?: () => void;
  sidebarOpen?: boolean;
  editableAction?: () => void;
  editableLabel?: string;
  actionsCenter?: ReactChild;
  actionsEnd?: ReactChild;
};

type LayoutComponent = (
  props: PropsWithChildren<LayoutProps>
) => ReactElement | null;

const Layout: LayoutComponent = ({
  title,
  subtitle,
  primaryActionLabel,
  primaryAction,
  secondaryActionLabel,
  secondaryAction,
  sidebarOpen = false,
  editableAction,
  editableLabel,
  children,
  actionsCenter,
  actionsEnd,
}) => {
  const sidebarStore = Sidebar.Store.useSidebarStore((state) => state);
  const navigate = useNavigate();
  const signOut = SignOut.H.useSignOut();

  const profileMenu = [
    // {
    //   label: 'Profile',
    //   icon: FaUser,
    // },
    {
      label: 'Logout',
      icon: FaArrowRightFromBracket,
      onSelect: async () => {
        const toastId = toast.loading('Logging out...');
        signOut.mutate(undefined, {
          onSuccess: () => {
            navigate('/sign-in');
            toast.success('Logged out. See you soon!', { id: toastId });
          },
        });
      },
    },
  ];

  return (
    <Box
      className={cx([
        'flex',
        'flex-col',
        'flex-grow',
        'bg-gray-100',
        'dark:bg-ew-bg-dark-600',
      ])}
    >
      <Box
        className={cx([
          'relative',
          'z-60',
          'bg-white',
          'dark:bg-ew-bg-dark',
          'dark:text-ew-text-dark',
          'shadow',
        ])}
      >
        <Box
          className={cx([
            'px-4',
            // 'container',
            // 'max-w-[90%]',
            'mx-auto',
          ])}
        >
          <Box
            className={cx([
              'py-2',
              'md:flex',
              'md:items-center',
              'md:justify-between',
            ])}
          >
            <Box
              className={cx(
                [
                  // 'min-w-0',
                  'animate-in',
                  'flex-1',
                  'transition-transform',
                  'delay-300',
                  'duration-500',
                ],
                {
                  // 'motion-safe:translate-x-5': sidebarOpen,
                  'translate-x-5': sidebarStore.sidebarOpen,
                  'ml-5': sidebarStore.sidebarOpen,
                }
              )}
            >
              <Box className={cx(['grid', 'grid-cols-3'])}>
                <Box className={cx(['flex', 'space-x-2', 'items-center'])}>
                  <h1
                    className={cx([
                      'text-xl',
                      'font-bold',
                      'leading-7',
                      'text-gray-700',
                      'sm:truncate',
                      'sm:leading-9',
                      'dark:text-ew-text-dark',
                    ])}
                  >
                    {title}
                  </h1>
                  {subtitle ? (
                    <>
                      {' '}
                      <span
                        className={cx([
                          'mt-2',
                          'flex',
                          'items-center',
                          'text-gray-500',
                          'sm:mr-6',
                          'sm:mt-0',
                          'dark:text-ew-text-dark',
                        ])}
                      >
                        {subtitle}
                      </span>
                    </>
                  ) : null}
                </Box>

                {actionsCenter ? (
                  <>{actionsCenter}</>
                ) : (
                  <Divider orientation="vertical" />
                )}
                {actionsEnd ? (
                  <Box
                    className={cx([
                      'flex',
                      'items-center',
                      'justify-end',
                      'ml-auto',
                      'space-x-3',
                    ])}
                  >
                    {actionsEnd}

                    <Box className={cx(['ml-auto'])}>
                      <Dropdown
                        align="end"
                        sideOffset={2}
                        alignOffset={0}
                        items={profileMenu}
                      >
                        <IconButton label={'User menu'} icon={FaUser} />
                      </Dropdown>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              className={cx([
                'mt-6',
                'flex',
                'items-center',
                'space-x-3',
                'md:ml-4',
                'md:mt-0',
              ])}
            >
              {editableAction && editableLabel ? (
                <Button intent="ghost" size="sm" onClick={editableAction}>
                  {editableLabel ?? 'Edit'}
                </Button>
              ) : null}
              {secondaryAction && secondaryActionLabel && (
                <Button intent="secondary" onClick={secondaryAction}>
                  {secondaryActionLabel}
                </Button>
              )}
              {primaryAction && primaryActionLabel && (
                <Button intent="primary" onClick={primaryAction}>
                  {primaryActionLabel}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        className={cx([
          // 'pt-8',
          'pb-14',
          // 'container',
          'w-full',
          'mx-auto',
          'flex',
          'flex-col',
          'flex-grow',
        ])}
      >
        <Box
          className={cx([
            'w-full',
            'mx-auto',
            // 'px-4',
            // 'sm:px-6',
            // 'lg:px-8'
          ])}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export { Layout };
