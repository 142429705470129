import { AxiosError, AxiosResponse } from 'axios';
import { api } from '../../api';
import * as Budgets from '../Budget';
import * as C from './Transactions.constants';
import * as T from './Transactions.types';

const getTransactionsByPeriod = async (
  budgetId: string,
  page = 0,
  periodId: string,
  params = {}
) => {
  const [start, end] = Budgets.utils.getPeriodDates(periodId);

  const response: AxiosResponse<T.TransactionsPaginated, AxiosError> =
    await api.get(C.API_URL, {
      params: {
        date__gte: start,
        date__lte: end,
        budget_id: budgetId,
        limit: C.PAGINATION_LIMIT,
        offset: C.PAGINATION_OFFSET * page,
        page,
        ...params,
      },
    });

  return response.data;
};

const getTransactions = async (
  accountId: string,
  budgetId: string,
  page = 0,
  params = {}
): Promise<T.TransactionsPaginated> => {
  const response: AxiosResponse<T.TransactionsPaginated, AxiosError> =
    await api.get(C.API_URL, {
      params: {
        account_id: accountId,
        budget_id: budgetId,
        limit: C.PAGINATION_LIMIT,
        offset: C.PAGINATION_OFFSET * page,
        page: page,
        ...params,
      },
    });

  return response.data;
};

const patchTransaction = async (
  transaction: T.TransactionUpdate
): Promise<T.TransactionsPaginated> => {
  const { id, ...restOfTransaction } = transaction;
  const response: AxiosResponse<T.TransactionsPaginated, AxiosError> =
    await api.patch(`/${C.API_URL}/${id}/`, restOfTransaction);

  return response.data;
};

const upsertTransaction = async (
  transaction: T.TransactionUpdate
): Promise<T.TransactionsPaginated> => {
  const response: AxiosResponse<T.TransactionsPaginated, AxiosError> =
    await api.post(`/${C.API_URL}/upsert/`, transaction);

  return response.data;
};

export {
  getTransactions,
  getTransactionsByPeriod,
  patchTransaction,
  upsertTransaction,
};
