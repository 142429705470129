import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import invariant from 'tiny-invariant';
import { useHotkeys } from 'react-hotkeys-hook';
import { FaPen } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Money } from '../Money';
import { Badge } from '../Badge';
import { Tooltip } from '../Tooltip';
import { IconButton } from '../IconButton';
import { cx, isNumberNegative } from '../../../utils';
import * as Account from '../../Account';
import * as Settings from '../../Settings';
import * as C from '../../Account/Account.constants';
import * as Transactions from '../../Transactions';

type SidebarAccountLinkComponent = (
  props: PropsWithChildren<{
    account: Account.T.AccountRead;
    current?: boolean;
    compact?: boolean;
    isLoading?: boolean;
    settings?: Settings.Types.Settings;
    onEdit?: () => void;
  }>
) => ReactElement | null;

const SidebarAccountLink: SidebarAccountLinkComponent = ({
  account,
  current = false,
  compact = false,
  isLoading = false,
  settings,
  onEdit,
}) => {
  const menuItemId = `account-menu-item-${account.id}`;
  const [isTruncated, setIsTruncated] = useState(false);
  const navigate = useNavigate();

  const { budgetId, accountId } = useParams();
  invariant(budgetId, 'No budget ID found in URL.');

  current = accountId === account.id;

  const destination = `a/${account.id}`;

  const ref = useHotkeys<HTMLLIElement>(
    ['space', 'enter'],
    (keyboardEvent, hotkeysEvent) => {
      navigate(destination);
    }
  );

  let accountType = account?.type;

  let internalTypes =
    // We skip BUDGET type for CCs
    accountType === C.CREDIT_CARD
      ? [
          Transactions.C.INTERNAL_TYPES.TRANSACTION,
          Transactions.C.INTERNAL_TYPES.INCOME,
          Transactions.C.INTERNAL_TYPES.SPLIT_TRANSACTION,
          Transactions.C.INTERNAL_TYPES.TRANSFER,
          Transactions.C.INTERNAL_TYPES.RECURRING,
        ]
      : undefined;

  const accountTotals = Account.H.useGetAccountTotals(
    account.id,
    budgetId,
    // TODO: we will use the month's period in the future
    undefined,
    settings?.budgeting_style,
    {
      internal_types: internalTypes?.join(','),
    }
  );

  useEffect(() => {
    const menuItem = document.getElementById(menuItemId);
    if (!!menuItem && menuItem.offsetWidth < menuItem.scrollWidth) {
      setIsTruncated(true);
    }
  }, [account]);

  return (
    <li
      tabIndex={0}
      ref={ref}
      className={cx([
        'rounded-md',
        'group',
        'focus:outline-none',
        'focus-visible:ring-2',
        'focus-visible:ring-ew-primary',
      ])}
    >
      <Link
        tabIndex={0}
        to={destination}
        className={cx(
          [
            'text-gray-300',
            'hover:text-white',
            'hover:bg-ew-bg-dark-700',
            'group/link',
            'flex',
            'items-center',
            'gap-x-1',
            'rounded-md',
            'pl-3',
            'p-2',
            'leading-6',
            'focus:outline-none',
            'focus-visible:ring-2',
            'focus-visible:ring-ew-primary',
          ],
          {
            'bg-ew-bg-dark-800 text-white': current,
            'p-1 pl-2': compact,
          }
        )}
      >
        {isTruncated ? (
          <Tooltip content={account.name}>
            <span id={menuItemId} className={cx(['truncate'])}>
              {account.name}
            </span>
          </Tooltip>
        ) : (
          <span id={menuItemId} className={cx(['truncate'])}>
            {account.name}
          </span>
        )}
        <Badge
          intent={
            isNumberNegative(accountTotals.data?.balance ?? 0)
              ? 'danger'
              : 'secondary'
          }
          isLoading={isLoading || accountTotals.isLoading}
          className={cx(['ml-auto'])}
          size="sm"
          asPill
        >
          <Money value={accountTotals.data?.balance ?? 0} />
        </Badge>
        <IconButton
          intent="ghost"
          className={cx([
            'group/icon',
            'opacity-0',
            'group-hover/link:opacity-100',
            'group-hover/link:!relative',
          ])}
          iconClasses={cx([
            'text-xs',
            'text-gray-400',
            'dark:group-hover/icon:text-white',
          ])}
          icon={FaPen}
          label="Open submenu"
          onClick={onEdit}
        />
      </Link>
    </li>
  );
};

export { SidebarAccountLink };
