import { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { FaCircle, FaCheckCircle } from 'react-icons/fa';
import * as T from '../../Transactions.types';
import { Toggle } from '../../../Common';
import { cx } from '../../../../utils';

const approvedColumn: Partial<ColumnDef<T.TransactionRead>> = {
  cell: ({
    getValue,
    row: { index, original, getIsSelected },
    column: { id },
    table,
  }) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    const initialValue = getValue<T.TableTransaction['approved']>();
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const onPress = (approved: boolean) => {
      table.options.meta?.onTransactionChange(index, id, {
        id: original.id,
        approved: approved,
      });
    };

    const iconClasses = cx([
      'h-5',
      'w-5',
      'text-ew-primary',
      {
        'text-gray-400': !value,
      },
    ]);

    return (
      <Toggle
        className={cx([
          'focus:ring-2',
          'focus:ring-inset',
          'focus:ring-ew-primary',
        ])}
        aria-label="Toggle approved"
        data-component="toggle"
        defaultPressed={value}
        onPressedChange={(pressed: boolean) => {
          onPress(pressed);
          setValue(pressed);
        }}
      >
        {value ? (
          <FaCheckCircle className={iconClasses} />
        ) : (
          <FaCircle className={iconClasses} />
        )}
      </Toggle>
    );
    /* eslint-enable */
  },
};

export { approvedColumn };
