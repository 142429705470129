import { Params, redirect } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import * as req from './FileImports.req';
import * as Transactions from '../Transactions';
import * as C from './FileImports.constants';

let action = async (
  queryClient: QueryClient,
  params: Params,
  request: Request
) => {
  let data = await request.json();

  switch (request.method.toLowerCase()) {
    case 'post':
      await req.createImport(data);

      // Re-fetch transactions
      await queryClient.invalidateQueries({
        queryKey: [Transactions.C.TRANSACTIONS_QUERY_KEY],
      });

      // Re-fetch imports
      await queryClient.invalidateQueries({
        queryKey: [C.FILE_IMPORTS_QUERY_KEY],
      });

      return redirect(`/b/${params.budgetId}/a/${data.account_id}`);
    case 'put':
      break;
    case 'delete':
      await req.deleteImport(data.id);

      // Re-fetch imports
      await queryClient.invalidateQueries({
        queryKey: [C.FILE_IMPORTS_QUERY_KEY],
      });

      break;
  }

  return null;
};

export { action };
