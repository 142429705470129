const API_URL = 'accounts';
const CATEGORIES_QUERY_KEY: string = 'CATEGORIES';

const CATEGORY_MUTATION_KEY = 'CATEGORY_MUTATION';

const CATEGORY_PATCH_KEY: string = 'CATEGORY_PATCH';
const CATEGORY_SECTIONS_QUERY_KEY: string = 'CATEGORYSECTIONS';

export {
  API_URL,
  CATEGORIES_QUERY_KEY,
  CATEGORY_SECTIONS_QUERY_KEY,
  CATEGORY_MUTATION_KEY,
  CATEGORY_PATCH_KEY,
};
