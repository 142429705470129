import * as React from 'react';
import { DialogProps } from '@radix-ui/react-dialog';
import { Command as CommandPrimitive } from 'cmdk';
import { FaSistrix } from 'react-icons/fa';

import { cx } from '../../../utils';
import { Dialog, DialogContent } from '../Dialog';

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cx(
      'bg-white flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground',
      className
    )}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps extends DialogProps {}

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <div
    className="flex items-center border-b dark:border-gray-700 px-3"
    cmdk-input-wrapper=""
  >
    <FaSistrix className="h-4 w-4 shrink-0 opacity-50 dark:text-ew-text-dark" />
    <CommandPrimitive.Input
      ref={ref}
      className={cx(
        [
          'flex',
          'h-9',
          'w-full',
          'rounded-md',
          'bg-transparent',
          'py-2',
          'text-sm',
          'outline-none',
          'placeholder:text-muted-foreground',
          'disabled:cursor-not-allowed',
          'disabled:opacity-50',
          'dark:text-ew-text-dark',
        ],
        className
      )}
      {...props}
    />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cx('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className={cx(
      ['py-6 text-center text-sm', 'dark:text-ew-text-dark'],
      props.className
    )}
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cx(
      [
        'overflow-hidden',
        'text-gray-600',
        'dark:text-ew-text-dark',
        '[&_[cmdk-group-heading]]:px-2',
        '[&_[cmdk-group-heading]]:py-1',
        '[&_[cmdk-group-heading]]:text-xs',
        '[&_[cmdk-group-heading]]:text-gray-800',
        '[&_[cmdk-group-heading]]:bg-gray-100',
        '[&_[cmdk-group-heading]]:border-y',
        '[&_[cmdk-group-heading]]:uppercase',
        '[&_[cmdk-group-heading]]:font-bold',
        '[&_[cmdk-group-heading]]:tracking-wider',
      ],
      className
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cx('-mx-1 h-px bg-gray-200', className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cx(
      [
        'relative',
        'flex',
        'text-gray-700',
        'cursor-default',
        'select-none',
        'items-center',
        'rounded-sm',
        'px-2',
        'py-1',
        'text-sm',
        'outline-none',
        'dark:text-ew-text-dark',
        'aria-selected:bg-ew-primary',
        'dark:aria-selected:bg-ew-primary-dark',
        'aria-selected:text-white',
        'dark:aria-selected:text-black',
        'data-[disabled]:pointer-events-none',
        'data-[disabled]:opacity-50',
      ],
      className
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cx(
        'ml-auto text-xs tracking-widest text-muted-foreground',
        className
      )}
      {...props}
    />
  );
};
CommandShortcut.displayName = 'CommandShortcut';

export {
  Command,
  // CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
};
