import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../api';
import { API_URL, BUDGET_QUERY_KEY } from './Budget.constants';
import { copyFromLastMonth, clearCurrentMonth } from './Budget.req';
import { BudgetPaginatedRead } from './Budget.types.ts';
import * as C from './Budget.constants';
import { postBudget } from './Budget.req.ts';

const getBudgets = async () => {
  const response: AxiosResponse<BudgetPaginatedRead> = await api.get(API_URL);

  return response.data;
};

const useGetBudgets = () => {
  return useQuery({
    queryKey: [BUDGET_QUERY_KEY],
    queryFn: getBudgets,
  });
};

const getBudget = async (budgetId: string) => {
  const response = await api.get(`/budgets/${budgetId}`);

  return response.data;
};

const useGetBudget = (budgetId: string) => {
  return useQuery({
    queryKey: [BUDGET_QUERY_KEY, budgetId],
    queryFn: () => getBudget(budgetId),
  });
};

const usePostBudget = (budgetId: string) => {
  const queryClient = useQueryClient();
  const queryKey = [C.BUDGET_QUERY_KEY, budgetId];
  return useMutation({
    mutationKey: [C.BUDGET_POST_MUTATION_KEY],
    mutationFn: postBudget,
    onSettled: async () => await queryClient.invalidateQueries({ queryKey }),
  });
};

const useCopyFromLastMonth = (
  budgetId: string,
  lastPeriodId: string,
  currentPeriodId: string
) => {
  return useMutation({
    mutationFn: () =>
      copyFromLastMonth(budgetId, lastPeriodId, currentPeriodId),
  });
};

const useClearCurrentMonth = (budgetId: string, periodId: string) => {
  return useMutation({
    mutationFn: () => clearCurrentMonth(budgetId, periodId),
  });
};

export {
  useGetBudget,
  useGetBudgets,
  useCopyFromLastMonth,
  useClearCurrentMonth,
  postBudget,
  usePostBudget,
};
