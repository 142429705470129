import React from 'react';
import { Box } from '../Box';
import { DividerComponent } from './Divider.types';
import { dividerStyles } from './Divider.styles';
import { cx } from '../../../utils';

const Divider: DividerComponent = ({ orientation, size, ...rest }) => {
  const classes = cx(dividerStyles({ orientation, size }), rest.className, {});
  return <Box {...rest} className={classes} aria-hidden="true" />;
};

export { Divider };
