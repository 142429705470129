import * as React from 'react';
import { Primitive } from '@radix-ui/react-primitive';
import { cx } from '../../../utils';
import * as T from './Label.types';

const Label = React.forwardRef<T.LabelComponent, T.LabelProps>(
  ({ children, hideLabel = false, ...props }, forwardedRef) => {
    return (
      <Primitive.label
        className={cx(
          [
            'block',
            'text-sm',
            'font-medium',
            'leading-6',
            'text-gray-600',
            'dark:text-white',
            'label-text',
          ],
          { 'sr-only': hideLabel }
        )}
        {...props}
      >
        {children}
      </Primitive.label>
    );
  }
);

export { Label };
