import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import invariant from 'tiny-invariant';
import * as T from './Settings.types';
import * as C from './Settings.constants';
import * as H from './Settings.hooks';
import { cx } from '../../utils';
import { Box, Button, Divider, Select } from '../Common';
import { match, P } from 'ts-pattern';

const SettingsForm = React.forwardRef<
  T.SettingsFormElement,
  T.SettingsFormProps
>(
  (
    { children, isLoading = false, onSubmit, onCancel, ...props },
    forwardedRef
  ) => {
    const { budgetId } = useParams();
    invariant(budgetId, 'budgetId is required');
    const settings = H.useGetSettings(budgetId);
    const updateSettings = H.usePutSettings(budgetId);

    const defaultValues = match(settings)
      .with(
        { isLoading: false, isError: false, data: P.not(P.nullish) },
        ({ data }) => {
          const { inserted_at, updated_at, user_id, id, budget_id, ...rest } =
            data[0];

          return {
            ...rest,
            week_start: String(rest.week_start),
            show_symbol: rest.show_symbol ? 'SHOW' : 'HIDE',
            show_due_date: rest.show_due_date ? 'SHOW' : 'HIDE',
          };
        }
      )
      .otherwise(() => ({
        budgeting_style: C.DEFAULT_SETTINGS.BUDGETING_STYLE,
        month_view: C.DEFAULT_SETTINGS.MONTH_VIEW,
        thousands: C.DEFAULT_SETTINGS.THOUSANDS,
        decimal: C.DEFAULT_SETTINGS.DECIMAL,
        symbol: C.DEFAULT_SETTINGS.SYMBOL,
        show_symbol: C.DEFAULT_SETTINGS.SHOW_SYMBOL,
        date_format: C.DEFAULT_SETTINGS.DATE_FORMAT,
        show_due_date: C.DEFAULT_SETTINGS.SHOW_DUE_DATE,
        week_start: C.DEFAULT_SETTINGS.WEEK_START,
        number_of_months: C.DEFAULT_SETTINGS.NUMBER_OF_MONTHS,
      }));

    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<T.SettingsFormInputs>({
      defaultValues,
    });

    const submitHandler: SubmitHandler<T.SettingsFormInputs> = (data) => {
      // const settings = {
      //   ...data,
      //   show_symbol: Boolean(data.show_symbol === 'SHOW'),
      //   show_due_date: Boolean(data.show_due_date === 'SHOW'),
      // };

      const updatedSettings = {
        id: String(settings?.data?.[0].id),
        ...data,
        show_symbol: Boolean(data.show_symbol === 'SHOW'),
        show_due_date: Boolean(data.show_due_date === 'SHOW'),
      };

      // TODO: fix
      // @ts-ignore
      updateSettings.mutate(updatedSettings, {
        onSuccess: () => {
          onCancel?.();
        },
        //
        // onError: (error) => {
        // },
      });
      // onSubmit?.(data);
    };

    return (
      <Box as="form" {...props} onSubmit={handleSubmit(submitHandler)}>
        <Box>
          <Box>
            <p className={cx(['text-lg', 'font-semibold', 'text-ew-primary'])}>
              Budget
            </p>
          </Box>

          <Divider />

          <Box className={cx(['grid', 'grid-cols-2', 'gap-4'])}>
            <Box>
              <Controller
                control={control}
                name="budgeting_style"
                render={({ field }) => (
                  <Select
                    isLoading={settings.isLoading}
                    label="Budgeting Style"
                    options={C.GENERAL_SETTINGS.BUDGETING_STYLE}
                    fullWidth
                    defaultValue={field.value}
                    {...field}
                    onValueChange={field.onChange}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                control={control}
                name="show_due_date"
                render={({ field }) => {
                  return (
                    <Select
                      isLoading={settings.isLoading}
                      label="Show due date"
                      options={C.GENERAL_SETTINGS.SHOW_DUE_DATE}
                      fullWidth
                      defaultValue={field.value}
                      {...field}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            </Box>
          </Box>

          <Divider />

          <Box>
            <p className={cx(['text-lg', 'font-semibold', 'text-ew-primary'])}>
              Currency
            </p>
          </Box>

          <Divider />

          <Box
            className={cx([
              'grid',
              'grid-cols-1',
              'md:grid-cols-2',
              'lg:grid-cols-4',
              'gap-4',
            ])}
          >
            <Box>
              <Controller
                control={control}
                name="symbol"
                render={({ field }) => {
                  return (
                    <Select
                      isLoading={settings.isLoading}
                      label="Default currency"
                      options={C.GENERAL_SETTINGS.SYMBOL}
                      fullWidth
                      defaultValue={field.value}
                      {...field}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            </Box>

            <Box>
              <Controller
                control={control}
                name="show_symbol"
                render={({ field }) => {
                  return (
                    <Select
                      isLoading={settings.isLoading}
                      label="Show/Hide Symbol"
                      options={C.GENERAL_SETTINGS.SHOW_SYMBOL}
                      fullWidth
                      defaultValue={field.value}
                      {...field}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            </Box>

            <Box>
              <Controller
                control={control}
                name="thousands"
                render={({ field }) => {
                  return (
                    <Select
                      isLoading={settings.isLoading}
                      label="Thousands"
                      options={C.GENERAL_SETTINGS.THOUSANDS}
                      fullWidth
                      defaultValue={field.value}
                      {...field}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            </Box>

            <Box>
              <Controller
                control={control}
                name="decimal"
                render={({ field }) => {
                  return (
                    <Select
                      isLoading={settings.isLoading}
                      label="Decimal"
                      options={C.GENERAL_SETTINGS.DECIMAL}
                      fullWidth
                      defaultValue={field.value}
                      {...field}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            </Box>
          </Box>

          <Divider />

          <Box>
            <p className={cx(['text-lg', 'font-semibold', 'text-ew-primary'])}>
              Date
            </p>
          </Box>

          <Divider />

          <Box className={cx(['grid', 'grid-cols-2', 'gap-4'])}>
            <Box>
              <Controller
                control={control}
                name="date_format"
                render={({ field }) => {
                  return (
                    <Select
                      isLoading={settings.isLoading}
                      label="Date format"
                      options={C.GENERAL_SETTINGS.DATE_FORMAT}
                      fullWidth
                      defaultValue={field.value}
                      {...field}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            </Box>

            <Box>
              <Controller
                control={control}
                name="week_start"
                render={({ field }) => {
                  return (
                    // TODO: fix
                    // @ts-ignore
                    <Select
                      isLoading={settings.isLoading}
                      label="Week starts on"
                      options={C.GENERAL_SETTINGS.WEEK_START}
                      fullWidth
                      defaultValue={String(field.value)}
                      {...field}
                      onValueChange={field.onChange}
                    />
                  );
                }}
              />
            </Box>
          </Box>

          <Divider size="xl" />

          <Box className={cx(['flex', 'justify-end', 'space-x-3'])}>
            <Button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
                onCancel?.();
              }}
            >
              Cancel
            </Button>

            <Button intent="primary" isLoading={updateSettings.isPending}>
              Apply Changes
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);

export { SettingsForm };
